.subscription-current-plan-info-notification {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-weight: 400;
  background-color: $info_lightest;
  margin-bottom: 10px;
}

.login-main-container.main-content {
  .form-container-fluid.subscription-page.container-fluid {
    height: 100%;
    padding-top: 0px;

    .subscription-wrapper {
      .container-fluid.main-container {
        padding-left: 0px;
        padding-right: 0px;

        .update-profile-title {
          margin-bottom: 30px;
          font-weight: 500;
        }

        .landing-left {
          margin-top: 0%;
          width: 100%;
          max-width: 100%;

          .subscription {
            width: 100%;
            max-width: 100%;
          }
        }

        .form-group.name-wrapper {
          .control-label {
            color: $label_color !important;
          }

          input.form-control {
            border: 1px solid $border_color;
          }
        }

        .plan-and-pricing-wrapper {
          text-align: center;

          h4 {
            font-weight: 400;
            font-size: 18px;
          }
        }

        .plan-validity-wrapper {
          text-align: center;
          display: flex;
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 5px;
          padding-left: 16px;
          padding-right: 16px;

          .plan-validity {
            border: 1px solid $border_color;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;

            .select-validity-wrapper {
              text-align: center;
              justify-content: end;
              display: flex;
              align-items: center;
              padding-right: 0px;
              font-weight: 400;
              text-transform: uppercase;
            }

            .plan-validity-btn-wrapper {
              display: flex;
              justify-content: start;

              button {
                margin-right: 5px;
                margin-left: 5px;
                background-color: $white;
                border: 1px solid $border_color;
                border-radius: 30px;
                color: $gray;
              }

              button:hover,
              button.selected {
                border: 1px solid $primary_input_field_border_color;
                color: $primary_input_field_border_color;
                font-weight: 500;
              }
            }
          }
        }

        .cards-wrapper {
          display: flex;
          justify-content: center;

          .subscription-plan-features-section {
            border: 1px solid $border_color;
            border-bottom: none;
          }

          .card-wrapper {
            padding-left: 0px;
            padding-right: 0px;

            .card {
              border: 1px solid $border_color;
              border-bottom: none;
              padding: 0px;

              .card-body {
                .card-title.plan {
                  text-decoration: underline;
                  text-underline-offset: 10px;
                  border-bottom: 1px solid lighten($gray, 40%);
                  margin-bottom: 0px;
                  padding-bottom: 6px;
                  text-transform: uppercase;
                  text-decoration-color: $primary;
                  text-decoration-thickness: 2px
                }

                .select-users-count-section {
                  margin-bottom: 10px;
                  border: 1px solid $border_color;
                  border-left: none;
                  border-right: none;
                  padding: 5px;
                }

                .plan-price {
                  font-weight: 400;
                }

                .get-started-btn {
                  button.btn {
                    color: $primary;
                    background-color: $white;
                    border-color: $primary_input_field_border_color;
                    padding: 2px 20px !important;
                  }

                  button.btn:hover {
                    color: $white;
                    background-color: $primary;
                  }
                }
              }

              // .list-group.list-group-flush {
              //   .list-group-item {
              //     border: none !important;
              //     display: flex;
              //     align-items: center;
              //   }
              // }
            }

            .card.subscription_plan_1 {
              background-color: lighten($gray, 48%);
            }

            .card.subscription_plan_2 {
              background-color: lighten($gray, 46%);
            }

            .card.subscription_plan_3 {
              background-color: lighten($gray, 44%);
            }

            .card.subscription_plan_4 {
              background-color: lighten($gray, 42%);
            }

            .card:hover {
              cursor: pointer;
            }

            .card::after {
              position: absolute;
              z-index: -1;
              opacity: 0;
              -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
              transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
          }

          // .card-wrapper:hover {
          //   .card {
          //     border: 1px solid $primary;
          //   }
          // }
        }

      }
    }

    .footer {
      background-color: $white;

      .product-owner-details {
        color: $label_color;

        a {
          color: $primary;
        }
      }

      .policies {
        a {
          color: $gray !important;
        }
      }
    }
  }
}

.menu-item-subscription-wrapper {
  margin-top: 5px;

  img {
    height: 24px !important;
    width: auto !important;
  }

  a {
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid $border_color;
    padding-top: 3px !important;
    padding-right: 10px !important;
    padding-bottom: 3px !important;
    padding-left: 5px !important;
  }
}

.payment-verification-modal {
  .modal-content {
    height: 40%;

    .modal-body {
      min-height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
}

.subscription-order-review-modal {
  .modal-dialog {
    width: 800px;

    .modal-content {
      .modal-header {
        padding: 10px 6px;

        .modal-title.subscription-order-modal-title {
          display: flex;
          font-size: 18px;
          font-weight: 400;

          .common-modal-title-content {
            font-size: 20px;
            display: flex;
            align-items: center;
            padding-left: 6px;
            padding-right: 6px;
          }
        }

        .common-modal-title-action {
          padding: 6px;
          text-align: right;
          display: flex;
          justify-content: end;
        }
      }

      .modal-body {
        height: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
        max-height: 75vh;
        overflow: auto;

        .table {
          margin-bottom: 0px;
        }

        .table>tbody>tr>td {
          padding: 5px 5px;
        }
      }

      .subscription-order-modal-footer {
        padding: 10px 15px;

        .btn-primary-outline {
          font-size: 14px;
        }
      }
    }
  }
}

.enforcement-plan {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 15;
  background: rgba(217, 217, 271, 0.5);
  cursor: not-allowed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .enforcement-plan-content-section {
    width: 700px;
    height: 300px;
    background: rgba(255, 255, 255, 0.9);
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: default;
    align-content: center;
    font-size: 16px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);

    .enforcement-body-wrapper {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      .enforcement-content-wrapper {
        text-align: left;
        padding-left: 45px;

        h2 {
          font-weight: 500;
        }

        p {
          font-size: 14px;
        }
      }

      .enforcement-button-wrapper {
        .btn {
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }
  }
}

.subscription-features-wrapper {
  margin-bottom: 10%;

  .subscription-features {
    display: flex;
    flex-wrap: wrap;

    .feature-plan_1,
    .feature-plan_2,
    .feature-plan_3,
    .feature-plan_4 {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    padding-left: 0px;
    padding-right: 0px;

    .feature-name {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid $border_color;
    }

    .feature-plan_1 {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid lighten($border_color, 5%);
      border-left: none;
      // border-top: none;
      text-align: center;
      background-color: lighten($gray, 48%);
    }

    .feature-plan_2 {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid lighten($border_color, 5%);
      border-left: none;
      // border-top: none;
      text-align: center;
      background-color: lighten($gray, 46%);
    }

    .feature-plan_3 {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid lighten($border_color, 5%);
      border-left: none;
      // border-top: none;
      text-align: center;
      background-color: lighten($gray, 44%);
    }

    .feature-plan_4 {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid lighten($border_color, 5%);
      border-left: none;
      // border-top: none;
      text-align: center;
      background-color: lighten($gray, 42%);
    }
  }
}

.subscription-show-page {
  overflow-y: unset !important;

  .subscription-show-wrapper {
    .container-fluid.main-container {
      .subscription-show {
        .subscription-orders-wrapper {
          .subscription-orders-title {
            .title {
              font-size: 18px;
              font-weight: 400;
            }
          }

          .subscription-orders-body {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

.upgrade-plan-wrapper {
  color: $primary;
  cursor: pointer;
}

.subscriptions-orders-history-wrapper {
  .subscriptions-orders-history-title {
    .title {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .subscriptions-orders-history-body {
    padding-left: 0px;
    padding-right: 0px;
  }
}

// .renew-plan-link {
//   font-weight: 500;
// }

.current-subscription-wrapper {
  .current-subscription-title {
    .title {
      font-size: 18px !important;
      font-weight: 400 !important;
    }
  }

  .current-subscription-body {
    display: flex;
    flex-wrap: wrap;

    .form-group-output-border {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.subscription-wrapper {
  .current-subscription-wrapper {
    margin-top: 10px;
  }
}

.add-subscription-users {
  .fa-plus {
    width: 14px;
  }
}

.all-plans-wrapper {
  text-align: left;
  padding-bottom: 5px;

  h4 {
    font-weight: 400 !important;
    font-size: 18px !important;
    padding-bottom: 5px;
    border-bottom: 1px solid $border_color;
  }
}

.upgrade-plan-modal-body,
.increase-user-count-modal-body {
  .current-plan-details {
    padding-top: 15px;

    .form-group-output-border {
      .form-group-output {
        margin-bottom: 5px
      }
    }
  }

  .plan-information-text-wrapper {
    .plan-information-text {
      font-size: 12px;
      border: 1px solid $border_color;
      border-radius: 5px;
      padding: 7px 5px;
      margin-bottom: 10px;
    }
  }

  .plan-title-wrapper {
    .title {
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid $border_color;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .plan-total-details-wrapper {

    .sub-total-wrapper,
    .taxes-wrapper,
    .total-wrapper {
      .value {
        text-align: right;
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .total-wrapper .value {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.all-pricing-wrapper {
  margin-bottom: 20px;

  .plan-1,
  .plan-2,
  .plan-3,
  .plan-4 {
    .card-header {
      border: 1px solid $border_color;
      color: $white;
      background-color: #2D3E76;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: none;
    }

    .lighter-bg-grey {
      background-color: #F5F6F7;
    }

    .px-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .py-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .distributo-plan-card {
      border: 1px solid $border_color;
      min-height: 1350px;

      .light-text-dark {
        color: #51525A;
      }

      .pricing-slider {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 20px;
        height: 1250px;

        .pricing-sub-headenig {
          min-height: 50px;
        }

        h4 {
          font-size: 20px !important;
        }

        h5 {
          font-weight: 500;
        }

        p {
          font-size: 14px !important;
        }

        .pt-3 {
          padding-top: 1rem !important
        }

        .pt-5 {
          padding-top: 3rem !important
        }

        .fs-14 {
          font-size: 14px;
        }

        .m-0 {
          margin: 0 !important
        }

        .fw-bold {
          font-weight: 500;
        }

        .mt-2 {
          margin-top: .5rem !important
        }

        .mb-0 {
          margin-bottom: 0px;
        }

        .text-start {
          text-align: left !important;
        }

        .rounded-0 {
          border-radius: 0 !important
        }

        .px-4 {
          padding-right: 1.5rem !important;
          padding-left: 1.5rem !important
        }

        .orange-check {
          margin: 0;
          padding: 0px;
        }

        .orange-check li:before {
          content: "\f00c";
          left: 0px;
          color: #FE7C19;
          position: absolute;
          font-family: fontawesome;
          top: 0px;
        }

        .orange-check li {
          list-style: none;
          position: relative;
          padding-left: 30px;
          margin: 0 0 9px 0;
        }

        ul.orange-check ul li {
          list-style: circle !important;
          padding-left: 6px !important;
        }

        ul.orange-check ul li:before {
          display: none;
        }

        ul.orange-check ul {
          margin-left: 11px;
        }

        .enterprice-contact {
          min-height: 115px;
        }
      }

      button.btn,
      .btn.plan-4 {
        font-size: 14px;
        padding-left: 25px;
        padding-right: 25px;
        font-weight: 500;
        background-color: #2D3E76;
        border-color: #2D3E76;
      }

      button.btn.disabled-btn {
        border-color: #2D3E76;
        color: #2D3E76;
        background: $white;
        cursor: not-allowed;
      }

    }
  }
}

.all-pricing-wrapper .plan-1 .distributo-plan-card .pricing-slider .pt-5 {
  padding-top: 75px !important
}

.all-pricing-wrapper .plan-4 .distributo-plan-card .pricing-slider .pt-5 {
  padding-top: 72px !important
}

.plans-detail-comparions {
  .border-bottom {
    border-bottom: 1px solid #E8E8E8 !important;
  }

  .lighter-bg-grey {
    background-color: #F5F6F7;
  }

  .fw-bold {
    font-weight: 500;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .rounded-0 {
    border-radius: 0 !important
  }

  .fs-14 {
    font-size: 14px;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .d-flex {
    display: flex !important
  }

  .align-items-center {
    align-items: center !important
  }

  .rounded-3 {
    border-radius: 4px !important;
  }

  .icon {
    min-height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  .me-2 {
    margin-right: .5rem !important
  }

  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
  }

  .same-col {
    text-align: center;
    min-width: 100px;
  }

  .mt-2 {
    margin-top: .5rem !important
  }

  .pricing-slider-sec-2 {
    padding-bottom: 160px !important;
  }

  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .d-none {
    display: none !important
  }

  .d-md-block {
    display: block !important
  }

  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .light-border-grey {
    border-color: #E8E8E8;
  }

  .mb-5 {
    margin-bottom: 3rem !important
  }

  .table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
  }

  .table-borderless>:not(:first-child) {
    border-top-width: 0
  }

  .text-dark {
    color: #222222 !important;
  }

  .p-4 {
    padding: 1.5rem !important
  }

  .m-0 {
    margin: 0 !important
  }

  tbody {
    background-color: $white;
  }

  h4 {
    font-size: 20px !important;
  }

  h5 {
    font-weight: 500;
  }

  p {
    font-size: 14px !important;
  }

  .pt-3 {
    padding-top: 1rem !important
  }

  .pt-5 {
    padding-top: 3rem !important
  }

  .fs-14 {
    font-size: 14px;
  }

  .m-0 {
    margin: 0 !important
  }

  .fw-bold {
    font-weight: 500;
  }

  .mt-2 {
    margin-top: .5rem !important
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .text-start {
    text-align: left !important;
  }

  .rounded-0 {
    border-radius: 0 !important
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  td h4.fw-bold.text-uppercase.mb-0 {
    margin-top: 0px;
  }

  // lighter-bg-grey
}

td .label-outline-default,
td .label-outline-default:hover {
  background: none;
  border: 1px solid $primary;
  font-size: 12px;
  color: $primary;
  font-weight: 500;
  padding: 3px 5px;
  margin-left: 10px;

  img.upgrade-plan {
    height: 15px;
  }
}