.supplier-recents-left-sidenav {
  overflow-y: auto;
  padding-top: 15;
}

.supplier-recents-right-section {
  border-left: 1px solid $secondary_lighter;
  padding-top: 10;
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.supplier-opening-balance-type-credit {
  width: 50%;
}