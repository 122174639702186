.error input,
.error .form-control,
.error .Select-control {
  border-color: $danger_input_field_border_color !important;
}

span.credit-errors-message {
  font-size: 11px;
  color: $errors_message !important;
  position: absolute;
  left: 5px;
  right: 5px;
}

.right-filter {
  width: 268px;
  min-width: 268px;
  max-width: 268px;
  background: none repeat scroll 0 0 $white;
  border-left: 1px solid $border-color;
  position: absolute;
  right: 0;
  z-index: 998;
  top: 115px;
  height: 100%;
}

.right-filter .reports-advance-filters-body {
  height: 100% !important;
  max-height: calc(100% - 177px) !important;
  min-height: calc(100% - 177px) !important;
  overflow: auto;
}

#credit-table th {
  // white-space: nowrap;
}

#credit-table th,
#credit-table td {
  border: 1px solid $border-color !important;
  padding: 5px 5px;
  position: relative;
}

#credit-table td.word-break.text-center .form-control {
  text-align: center;
}

.common-actions-base-screen-body .form-control,
.common-actions-base-screen-body .Select-control {
  border: 1px solid $white;
  padding: 0;
}

#credit-table td.word-break.fix-td {
  background: $white;
  z-index: 9;
}

.table-fix-top #credit-table tr:first-child th.word-break.fix-col {
  position: relative !important;
  z-index: 1000;
}

#credit-table td.word-break.fix-td:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: $gray_lighter;
  right: -1px;
}

#credit-table td.word-break.fix-td:before,
.table-fix-top #credit-table tr:first-child th.word-break.fix-col:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: $gray_lighter;
  left: -1px;
}

#credit-table th {
  position: initial !important;
}

#credit-table .btn {
  width: 100%;
  padding: 3px 8px;
  margin: 3px 1px;
}

#credit-table .check-hover {
  margin: auto !important;
}

#credit-table li.dropdown .dropdown-menu {
  left: 0;
  right: 0;
  margin: 10px 0 0 0;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 0px 21px $box_shadow;
  padding: 12px 0px;
}

.credit-table-fix .fix-table .table-fix-body {
  height: calc(100vh - 126px);
}

.common-actions-base-screen-body input.word-break {
  padding: 4px 10px;
  border: 1px solid transparent;
  min-height: 42px;
  font-size: 14px;
  width: 100%;
  border-radius: 2px;
}

.common-actions-base-screen-body input.word-break:hover,
.common-actions-base-screen-body input.word-break:focus {
  border: 1px solid $primary;
}

#credit-table td.bg-white {
  background: transparent !important;
}

#credit-table .is-open .Select-control {
  border: 1px solid $primary !important;
}

#credit-table li.dropdown {
  margin: 0;
  padding: 0;
  list-style: none;
}

// #credit-table tr:hover td {
//   background: lighten($gray, 42%);
// }
#credit-table li.dropdown a span {
  font-size: 14px !important;
  // white-space: nowrap;
}

.credit-table-fix .table-responsive {
  padding: 0px !important;
}

#credit-table {
  table-layout: auto;
}