.settings-form-elements {
  .setting-tab-item-check {
    padding-left: 0px;

    .check-hover {
      width: auto;

      &:hover {
        background: transparent;
      }
    }
  }

  .setting-tab-item {
    color: $secondary_darkest !important;
  }

  .setting-tab-item-sub {
    color: $secondary_darkest !important;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

div[id$="settings-scroll-container"] > .form-group:last-child {
  margin-bottom: 50px;
}

// WHAT   select form-group's which don't have .settings-sub-category class
div[id$="settings-scroll-container"] > .form-group:not(.settings-sub-category) {
  // WHAT   this is to elevate dropdown, so that it's above the sticky header (z-index: 1)
  .Select {
    z-index: 2;
  }

  // WHAT   this to elevate the selected dropdown, so that the selected dropdown menu is
  //        above the other dropdowns (as other dropdown have z-index: 2)
  .Select.is-focused.is-open {
    z-index: 3;
  }
}

.settings-form-elements.settings-scroll-container {
  height: calc(100vh - 170px) !important;
}
.render-child-settable-form-elements {
  padding-left: 20px;
  padding-bottom: 10px;
}
