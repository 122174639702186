.branch-zone-taxes .header {
  padding-left: 6px;
  padding-right: 6px;
}

.branch-warehouses .header {
  padding-left: 6px;
  padding-right: 6px;
}

.branch-zone-taxes .no-record-found {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.branch-warehouses .no-record-found {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.branch-warehouses {

  .warehouse-header-row,
  .warehouse-body-row {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.branch-zone-taxes {

  .branch-zone-taxes-header-row,
  .branch-zone-taxes-body-row {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}