.inventory-batch-tracking-link.show {
  cursor: pointer;
  margin-top: 6px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.inventory-batch-tracking-link.disabled {
  cursor: auto;
  color: $gray;
}

.inventory-batch-tracking-modal-dimensions {
  max-width: none !important;
  width: 65vw;
  margin: auto;
}

.inventory-batch-modal-header-parent-row .header-breadcrumb-and-title {
  padding-bottom: 5px;
}

.inventory-batch-tracking-modal-header-edit-pencil-icon {
  color: $primary;
}

.inventory-batch-tracking-modal-total-quantity-header.edit-modal {
  padding-left: 0px;

  // .inventory-batch-tracking-total-quantity {
  //   padding-left: 0px;
  // }
}

.inventory-batch-tracking-total-quantity {
  font-size: 15px;
  font-weight: 500;
  color: #676767;
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
}

.inventory-batch-tracking-total-quantity.edit-modal {
  padding-right: 15px;
}


.inventory-batch-tracking-total-quantity-unit-value.edit-modal {
  padding-left: 0px;
  padding-right: 6px;
}

.inventory-batch-tracking-total-quantity-unit-value {
  font-size: 15px;
  padding-left: 6px;
  color: #4d4d4d;
}

.inventory-batch-tracking-modal-total-quantity-header.show-modal {
  padding-left: 0px;
}

.inventory-batch-tracking-modal-body-content-height {
  height: 60vh;

  .inventory-batch-tracking-batch {
    padding-left: 6px;
    padding-right: 6px;

    .Select-control {
      border: 1px solid #e3e5e7;
    }
  }

  .inventory-item-batch-input-quantity {
    margin: 0px;
    text-align: right;
  }

  .with-content .inventory-batch-tracking-destroy {
    padding-left: 2px;
    padding-right: 2px;

    .btn-danger-outline {
      padding: 0px;
    }
  }

  .inventory-batch-tracking-mfd-date,
  .inventory-batch-tracking-expiry-date,
  .inventory-batch-tracking-available-quantity,
  .inventory-batch-tracking-input-quantity,
  .inventory-batch-tracking-input-quantity-unit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
  }

  .inventory-batch-tracking-input-quantity-unit div {
    padding: 0px;
  }
}

.inventory-batch-tracking-modal-body-content-height.with-content.identifier-list {
  padding-top: 0px;

  .voucher-item-inventory-batches-items {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .voucher-item-inventory-batches-items:hover {
    background-color: lighten($gray, 45%);
  }
}

.inventory-batch-tracking-modal-body-content-height.with-content.identifier-list.edit-modal {
  padding-top: 6px;

  .inventory-batch-tracking-modal-body-main-content {
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid #d8dbdd;

    .inventory-batch-tracking-mfd-date,
    .inventory-batch-tracking-expiry-date,
    .inventory-batch-tracking-available-quantity,
    .inventory-batch-tracking-input-quantity-unit {
      color: #4d4d4d;
    }

    .Select.wrappable-select.is-open {

      .Select-control {
        border: 1px solid $primary_input_field_border_color;
      }

      .Select-menu-outer {
        border: 1px solid $primary_input_field_border_color;
        border-top-color: $white;
      }
    }

    .error {

      .Select.wrappable-select.is-open {

        .Select-control {
          border: 1px solid $danger_input_field_border_color;
          border-bottom-color: $white;
        }

        .Select-menu-outer {
          border: 1px solid $danger_input_field_border_color;
          border-top-color: $white;
        }
      }
    }
  }
}

.inventory-batch-tracking-modal-without-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.inventory-batch-tracking-modal-body-content-inner-height {
  height: 57vh;
}

.inventory-batch-tracking-modal-body-content-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  .row.flex-wrap div {
    color: #676767;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.inventory-batch-modal-header-close-icon-row-element.edit-modal {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
}

.inventory-batch-tracking-edit-modal-action-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .fa.fa-times {
    font-size: medium !important;
  }

  .btn.btn-sm {
    padding: 3px 10px;
  }

  .inventory-batch-tracking-modal-header-edit-button-col {
    margin-right: 5px;

    button {
      margin-top: 0px;
    }

    .fa-pencil {
      font-size: medium;
    }
  }

  .inventory-batch-tracking-modal-header-close-button-col {
    margin-right: 10px;

    button {
      margin-top: 0px;
    }

    .fa-times {
      font-size: large;
      -webkit-text-stroke: 2px $white;
    }
  }
}

.inventory-batch-modal-header-close-icon-row-element.edit-modal {
  .fa-times {
    font-size: large;
    -webkit-text-stroke: 2px $white;
  }
}

.add-new-inventory-batch-tracking {
  margin-top: 10px;

  .row {
    text-align: end;
    padding-right: 6px;
  }

  button.btn {
    font-weight: 500;
  }
}

.voucher-item-inventory-batches-items {
  border-bottom: 1px solid #d8dbdd;

  .row.flex-wrap div {
    color: #4d4d4d;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.nventory-batch-modal-header-close-icon-row-element.edit-modal {
  .action-btn {
    padding: 3px 10px;
  }
}

.inventory-batch-modal-header-close-icon-row-element.edit-modal {
  .btn-default.action-btn {
    padding: 3px 10px;
  }
}

@supports (-moz-appearance:none) {
  .inventory-batch-tracking-edit-modal-action-btn {
    .btn-default.action-btn {
      padding: 4px 10px !important;
    }
  }

  .inventory-batch-modal-header-close-icon-row-element.edit-modal {
    .btn-default.action-btn {
      padding: 2px 10px;
    }
  }
}