.btn {
  border: 1px solid transparent;
  font-weight: 400;
  // opacity: 0.8;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: $gray_light;
  color: $gray_light;
  // min-height: 42px;
  line-height: 24px;
  border-radius: 2px;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.open>.btn.dropdown-toggle {
  background-color: transparent;
  color: $gray;
  border-color: $gray;
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active {
  background-color: transparent;
  border-color: $gray_light;
}

.btn.btn-fill {
  color: $white;
  /*background-color: $gray_light;*/
  background-color: $gray_darker;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn.btn-fill:hover,
.btn.btn-fill:focus,
.btn.btn-fill:active,
.btn.btn-fill.active,
.open>.btn.btn-fill.dropdown-toggle {
  /*background-color: $gray;*/
  background-color: $gray_darkest;
  color: $white;
}

.btn.btn-fill .caret {
  border-top-color: $white;
}

.btn .caret {
  border-top-color: $gray_light;
}

.btn:hover,
.btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important;
}

.btn:active,
.btn.active,
.open>.btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}

.btn.btn-icon {
  padding: 8px;
}

.btn-primary {
  border-color: $primary_button_color;
  color: $white;
  background-color: $primary_button_color;
  opacity: 1;
}

.btn-primary:hover,
.open>.btn-primary.dropdown-toggle {
  background-color: $primary_button_color_on_hover;
  color: $white;
  border-color: $primary_button_color_on_hover;
  opacity: 1;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
  background-color: $primary_button_color_on_hover;
  color: $white;
  border-color: $primary_button_color_on_hover;
  opacity: 1;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
.btn-primary:disabled:active,
.btn-primary:disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: $primary_light_button_color;
  border-color: $primary_light_button_color;
  color: $white;
}

.btn-primary.btn-fill {
  color: $white;
  background-color: $primary_light_button_color;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-primary.btn-fill:hover,
.btn-primary.btn-fill:focus,
.btn-primary.btn-fill:active,
.btn-primary.btn-fill.active,
.open>.btn-primary.btn-fill.dropdown-toggle {
  background-color: $primary_light_button_color;
  color: $white;
}

.btn-primary.btn-fill .caret {
  border-top-color: $white;
}

.btn-primary .caret {
  border-top-color: $primary;
}

.btn-success {
  background-color: $success_label_bg_color;
  border-color: $success_label_bg_color;
  color: $white;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
  background-color: $success_label_bg_color;
  color: $success_label_bg_color;
  border-color: $success_label_bg_color;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: $success_label_bg_color;
  border-color: $success_label_bg_color;
  color: $white;
}

.btn-success.btn-fill {
  color: $white;
  background-color: $success_label_bg_color;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-success.btn-fill:hover,
.btn-success.btn-fill:focus,
.btn-success.btn-fill:active,
.btn-success.btn-fill.active,
.open>.btn-success.btn-fill.dropdown-toggle {
  background-color: $success_label_bg_color;
  color: $white;
}

.btn-success.btn-fill .caret {
  border-top-color: $white;
}

.btn-success .caret {
  border-top-color: $success_label_bg_color;
}

.btn-info {
  border-color: $info_label_bg_color;
  color: $white;
  background: $info_label_bg_color;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open>.btn-info.dropdown-toggle {
  background-color: $info_label_bg_color !important;
  color: $white;
  border-color: $info_label_bg_color;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: $info_label_bg_color !important;
  border-color: $info_label_bg_color;
}

.btn-info.btn-fill {
  color: $white;
  background-color: $info_label_bg_color;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open>.btn-info.btn-fill.dropdown-toggle {
  background-color: $info_label_bg_color;
  color: $white;
}

.btn-info.btn-fill .caret {
  border-top-color: $white;
}

.btn-info .caret {
  border-top-color: $info_label_bg_color;
}

.btn-warning {
  border-color: $warning_label_bg_color;
  color: $white;
  background-color: $warning_label_bg_color;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open>.btn-warning.dropdown-toggle {
  background-color: transparent;
  color: $warning_label_bg_color;
  border-color: $warning_label_bg_color;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: $warning_label_bg_color;
  border-color: $warning_label_bg_color;
  color: $white;
}

.btn-warning.btn-fill {
  color: $white;
  background-color: $warning_label_bg_color;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-warning.btn-fill:hover,
.btn-warning.btn-fill:focus,
.btn-warning.btn-fill:active,
.btn-warning.btn-fill.active,
.open>.btn-warning.btn-fill.dropdown-toggle {
  background-color: $warning_label_bg_color;
  color: $white;
}

.btn-warning.btn-fill .caret {
  border-top-color: $white;
}

.btn-warning .caret {
  border-top-color: $warning_label_bg_color;
}

.btn-danger {
  background-color: $danger_label_bg_color;
  border-color: $danger_label_bg_color;
  color: $white;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open>.btn-danger.dropdown-toggle {
  background-color: $danger_label_bg_color;
  color: $white;
  border-color: $danger_label_bg_color;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: $danger_label_bg_color;
  border-color: $danger_label_bg_color;
  color: $white;
}

.btn-danger.btn-fill {
  color: $white;
  background-color: $danger_label_bg_color;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-danger.btn-fill:hover,
.btn-danger.btn-fill:focus,
.btn-danger.btn-fill:active,
.btn-danger.btn-fill.active,
.open>.btn-danger.btn-fill.dropdown-toggle {
  background-color: $danger_label_bg_color;
  color: $white;
}

.btn-danger.btn-fill .caret {
  border-top-color: $white;
}

.btn-danger .caret {
  border-top-color: $danger_label_bg_color;
}

.btn-neutral {
  border-color: $white;
  color: $white;
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:active,
.btn-neutral.active,
.open>.btn-neutral.dropdown-toggle {
  background-color: transparent;
  color: $white;
  border-color: $white;
}

.btn-neutral.disabled,
.btn-neutral.disabled:hover,
.btn-neutral.disabled:focus,
.btn-neutral.disabled.focus,
.btn-neutral.disabled:active,
.btn-neutral.disabled.active,
.btn-neutral:disabled,
.btn-neutral:disabled:hover,
.btn-neutral:disabled:focus,
.btn-neutral:disabled.focus,
.btn-neutral:disabled:active,
.btn-neutral:disabled.active,
.btn-neutral[disabled],
.btn-neutral[disabled]:hover,
.btn-neutral[disabled]:focus,
.btn-neutral[disabled].focus,
.btn-neutral[disabled]:active,
.btn-neutral[disabled].active,
fieldset[disabled] .btn-neutral,
fieldset[disabled] .btn-neutral:hover,
fieldset[disabled] .btn-neutral:focus,
fieldset[disabled] .btn-neutral.focus,
fieldset[disabled] .btn-neutral:active,
fieldset[disabled] .btn-neutral.active {
  background-color: transparent;
  border-color: $white;
}

.btn-neutral.btn-fill {
  color: $white;
  background-color: $white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.btn-neutral.btn-fill:hover,
.btn-neutral.btn-fill:focus,
.btn-neutral.btn-fill:active,
.btn-neutral.btn-fill.active,
.open>.btn-neutral.btn-fill.dropdown-toggle {
  background-color: $white;
  color: $white;
}

.btn-neutral.btn-fill .caret {
  border-top-color: $white;
}

.btn-neutral .caret {
  border-top-color: $white;
}

.btn-neutral:active,
.btn-neutral.active,
.open>.btn-neutral.dropdown-toggle {
  background-color: $white;
  color: $gray_light;
}

.btn-neutral.btn-fill,
.btn-neutral.btn-fill:hover,
.btn-neutral.btn-fill:focus {
  color: $gray_light;
}

.btn-neutral.btn-simple:active,
.btn-neutral.btn-simple.active {
  background-color: transparent;
}

.btn:disabled,
.btn[disabled],
.btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px;
}

.btn-round.btn-icon {
  padding: 9px;
}

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px;
}

.btn-simple.btn-icon {
  padding: 8px;
}

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400;
}

.btn-lg.btn-round {
  padding: 15px 30px;
}

.btn-lg.btn-simple {
  padding: 16px 30px;
}

.btn-sm {
  font-size: 12px;
  border-radius: 2px;
  padding: 4px 10px;
  /*padding: 5px 20px;*/
}

.btn-sm.btn-round {
  padding: 6px 10px;
}

.btn-sm.btn-simple {
  padding: 7px 10px;
}

.btn-xs {
  font-size: 12px;
  border-radius: 3px;
  padding: 1px 5px;
}

.btn-xs.btn-round {
  padding: 2px 5px;
}

.btn-xs.btn-simple {
  padding: 3px 5px;
}

.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

// custom style

.action-btn {
  font-size: 15px;
  // padding: 4px 0 0 0;
  border: none;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  text-align: center;
  margin: 0 2px;
  min-height: auto;
  display: flex;
  align-content: center;
  justify-content: center;
}

#listing-item-action-dropdown {
  padding: 0px;
  display: flex;
  align-items: center;
}

.modal-footer .btn-sm {
  line-height: 1.5;
  min-height: auto;
}

.nested-form-item-destroy-btn .btn {
  min-height: 42px;
}