@mixin textStyle {
  font-size: 14px;
  height: 42px;
  line-height: 42px;
  background-color: lighten($gray_lighter, 10%);
  color: $gray_light;
  border-top: 1px solid $border_color;
  border-bottom: 1px solid $border_color;
  padding-left: 10px;
  padding-right: 10px;
}

.offer-is-disabled {
  width: 20%;
  display: flex;
  justify-content: end;
}

.form-horizontal.offers {

  .offer-benefits-form-header {
    .sr-no-label {
      flex-direction: inherit !important;
      justify-content: end;
    }
  }

  // basic-information-section
  .basic-information-section {
    .customers-filter {
      a.customers-filter-link:focus {
        text-decoration: underline;
      }

      a.customers-filter-link:focus-visible {
        outline: none;
      }
    }
  }

  // condition-section
  .condition-section {
    margin-top: 20px;

    .offers-condition .offers-condition-body {
      padding-left: 15px !important;

      .condition-on {
        width: 20%;
        min-width: 20%;
        max-width: 20%;
      }

      .offer-type {
        width: 35%;
        min-width: 35%;
        max-width: 35%;
      }

      .condition-on-center-text {
        @include textStyle;
      }

      .condition-operator-condition-value-section {
        .condition-operator-gteq-lt-text {
          @include textStyle;
          border: 1px solid $border_color;
          overflow: hidden;
          border-right: none;
        }

        .condition-operator-gt-lteq-text {
          @include textStyle;
          border: 1px solid $border_color;
          overflow: hidden;
          border-right: none;
        }
      }

      .condition-value {
        .condition-value-1 {
          border-left: none;
          border-right: 1px solid $border_color;
          text-align: left;
          z-index: 0;
          background-color: $white;
        }

        .condition-value-1:focus {
          border: 1px solid $primary_input_field_border_color;
        }
      }

      .condition-value.error {
        .condition-value-1 {
          border: 1px solid $danger_input_field_border_color;
        }
      }

      .condition-value-2-wrapper {
        .condition-value-2 {
          border-left: none;
          border-right: 1px solid $border_color;
          text-align: left;
          z-index: 0;
          background-color: $white;
        }

        .condition-value-2:focus {
          border: 1px solid $primary_input_field_border_color;
        }
      }

      .condition-value-2-wrapper.error {
        .condition-value-2 {
          border: 1px solid $danger_input_field_border_color;
        }
      }

      .applicable-on-products-count,
      .condition-products-count {
        @include textStyle;
        border: 1px solid $border_color;
        text-decoration: underline;

        a {
          color: $primary_light;
        }

        a:focus,
        a:active,
        a:focus-visible {
          outline: none;
          color: $primary;
        }
      }

      .applicable-on-products-count.error,
      .condition-products-count.error {
        @include textStyle;
        border: 1px solid $danger_input_field_border_color;
        text-decoration: underline $danger_light;

        a {
          color: $danger_light;
        }

        a:focus,
        a:active,
        a:focus-visible {
          outline: none;
          color: $danger_light;
        }
      }
    }
  }

  // offer-benefits-section
  .offer-benefits-section {
    margin-top: 20px;

    .offer-benefit-name-wrapper {}

    .offer-benefit-details {
      display: flex;

      .offer-benefit-impact-wrapper {
        padding-right: 6px;

        .offer-benefit-impact-value {
          input.form-control {
            background-color: $white;
          }

          input.form-control:focus {
            border: 1px solid $primary_input_field_border_color;
          }
        }

        .offer-benefit-impact-value.error {
          input.form-control {
            border-right: 1px solid $danger_input_field_border_color;
          }
        }
      }

      .offer-benefit-impact-wrapper.quantity {
        width: 30%;

        .offer-benefit-impact-value {
          width: 40%;
        }

        .offer-benefit-impact-type {
          width: 60%;
        }
      }

      .offer-benefit-impact-wrapper.percentage,
      .offer-benefit-impact-wrapper.flat,
      .offer-benefit-impact-wrapper.percentage_markdown,
      .offer-benefit-impact-wrapper.flat_markdown {
        width: 60%;

        .offer-benefit-impact-value {
          width: 30%;
        }

        .offer-benefit-impact-type {
          width: 70%;
        }
      }

      .offer-benefit-impact-on-wrapper {
        padding-left: 6px;
        padding-right: 0px;
        width: 40%;

        .offer-benefit-impact-on-text {
          @include textStyle;
          padding-left: 5px;
          padding-right: 5px;
          border: 1px solid $border_color;
          overflow: hidden;
          border-right: none;
          width: 22%;
          text-align: center;
        }

        .offer-benefit-impact-on {
          width: 78%;
        }
      }

      .offer-benefit-extra-product-wrapper {
        padding-left: 6px;
        // padding-right: 6px;
        width: 70%;

        .offer-benefit-extra-product-text {
          @include textStyle;
          padding-left: 5px;
          padding-right: 5px;
          border: 1px solid $border_color;
          overflow: hidden;
          border-right: none;
          text-align: center;
          width: 56px;
        }

        .offer-benefit-extra-product-id {
          width: 50%;
        }

        .offer-benefit-extra-product-price-wrapper {
          width: 20%;

          .offer-benefit-extra-product-price {
            background-color: $white;
            border: 1px solid $border_color;
          }

          .offer-benefit-extra-product-price:focus {
            border: 1px solid $primary_input_field_border_color;
          }
        }

        .offer-benefit-extra-product-price-wrapper.error {
          .offer-benefit-extra-product-price {
            border-right: 1px solid $danger_input_field_border_color;
          }
        }

        .offer-benefit-at-price-of-text {
          @include textStyle;
          padding-left: 5px;
          padding-right: 5px;
          border: 1px solid $border_color;
          border-left: none;
          overflow: hidden;
          border-right: none;
          text-align: center;
          width: 70px
        }

        .offer-benefit-extra-product-inc-tax-text {
          @include textStyle;
          padding-left: 5px;
          padding-right: 5px;
          overflow: hidden;
        }
      }
    }

    .offer-benefit-impact-max-limit-wrapper {
      .offer-benefit-impact-max-limit-text {
        @include textStyle;
        border: 1px solid $border_color;
        overflow: hidden;
        border-right: none;
      }

      .offer-benefit-impact-max-limit {
        width: 57%;

        .form-control {
          background-color: $white;
          border: 1px solid $border_color;
          z-index: 0;
        }

        .form-control:focus {
          border: 1px solid $primary_input_field_border_color;
        }
      }
    }

    .nested-form-item-destroy-btn {
      padding: 0px !important;
    }

    #offer-benefit-chain-benefits {
      width: 60px;
      height: 25px;
    }

    .offer-benefit-chain-benefits-wrapper {
      .Select-menu-outer .Select-menu {
        .Select-option {
          padding: 8px 10px;
        }
      }
    }
  }

  // limits-section
  .limits-section {
    margin-top: 20px;
    margin-bottom: 15%;

    .offers-limits .offers-limits-body {
      padding-left: 15px !important;

      .form-control[type="number"] {
        text-align: left !important;
      }
    }
  }
}

// Show page
.offer-show-page.title-with-btn {
  display: flex;

  .offer-show-page-basic-information-title {
    display: flex;
    align-items: center;
  }

  .offer-show-page-is-disabled {
    display: flex;
    justify-content: end;
  }
}

.offer-show-page-condition-details {
  padding-bottom: 20px;
}

.offer-show-page-offer-benefits-details {
  padding-bottom: 20px;
}

.offer-show-page-limits-details {
  margin-bottom: 10%;
}

.offer-show-page-condition-details.form-horizontal.offers {
  .condition-on-offer-type-product-filter-section {
    padding-left: 0px;
    padding-right: 0px;

    .condition-products-count,
    .applicable-on-products-count {
      text-decoration: none !important;

      a {
        cursor: not-allowed !important;
        color: #333 !important;
      }
    }
  }

  .condition-operator-condition-value-section {
    padding-left: 0px;
    padding-right: 0px;
  }
}