.product-historical-pricing-link {
  cursor: pointer;
  text-decoration: underline;
}

.historical-pricing-listing-modal.modal {
  .modal-dialog {
    width: 70vw;

    .modal-content {

      .modal-header {

        // Modal header stylesheet
        h4 {
          margin: 0px;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .advance-search-modal-body {
        // Modal body stylesheet
        padding-top: 0px;

        #generic-table {
          thead {
            position: sticky;
            top: 0;
            background: $white;
          }
        }
      }

      .advance-search-modal-footer {
        // Modal footer stylesheet
      }

    }
  }
}