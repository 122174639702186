@mixin fa-ellipsis-v($margin-right : 0px) {
  font-size: medium;
  color: lighten($gray, 10%);
  margin-right: $margin-right;
  width: 12px;
}

@mixin align-items-center {
  display: flex;
  align-items:center;
}

@mixin cursor-grab {
  display: flex;
  width: 40%;
  .fa-ellipsis-v.left {
    @include fa-ellipsis-v(4px);
  }
  .fa-ellipsis-v.right {
    @include fa-ellipsis-v();
  }
}

@mixin item-number {
  width: 60%;
  padding: 0px;
  text-align: right;
}

.nested-draggable-item.form-group {
  .nested-item {
    @include align-items-center;
  }
  .sort-handle {
    @include align-items-center;
    .cursor-grab {
      @include cursor-grab;
    }
    .item-number {
      @include item-number;
    }
  }
}

.nested-draggable-item td.sort-handle {
  vertical-align: middle;
  .flex-wrap {
    padding-top: 10px;
    .cursor-grab {
      @include cursor-grab;
    }
    span.item-number {
      @include item-number;
      margin: 0;
    }
  }
}