  /*      Checkbox and radio         */

  .checkbox,
  .radio {
    margin-bottom: 12px;
  }

  .checkbox label,
  .radio label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    margin-bottom: 0;
  }

  .checkbox label::before,
  .checkbox label::after {
    font-family: 'FontAwesome';
    content: "\f096";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    cursor: pointer;
    line-height: 19px;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 1px;
    color: $gray_lightest;
    transition: color 0.2s linear;
    padding: 1px;
  }

  .checkbox label::after {
    content: "";
    text-align: center;
    opacity: 1;
    left: 1px;
    color: $gray_lightest;
  }

  .checkbox input[type="checkbox"],
  .radio input[type="radio"] {
    opacity: 0;
    margin-left: 0;
  }

  .checkbox input[type="checkbox"]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f046";
  }

  .checkbox input[type="checkbox"]:checked+label::after {
    color: $info_light;
  }

  .checkbox input[type="checkbox"]:checked+label::before {
    opacity: 0;
  }

  .checkbox input[type="checkbox"]:disabled+label,
  .radio input[type="radio"]:disabled+label,
  .checkbox input[type="checkbox"]:disabled:checked+label::after {
    color: $gray_lightest;
  }

  .checkbox input[type="checkbox"]:disabled+label::before,
  .checkbox input[type="checkbox"]:disabled+label::after {
    cursor: not-allowed;
  }

  .checkbox input[type="checkbox"]:disabled+label,
  .radio input[type="radio"]:disabled+label {
    cursor: not-allowed;
  }

  .checkbox.checkbox-circle label::before {
    border-radius: 50%;
  }

  .checkbox.checkbox-inline {
    padding-left: 0;
  }

  .checkbox-primary input[type="checkbox"]:checked+label::before {
    background-color: $primary;
    border-color: $primary;
  }

  .checkbox-primary input[type="checkbox"]:checked+label::after {
    color: $white;
  }

  .checkbox-danger input[type="checkbox"]:checked+label::before {
    background-color: $danger_light;
    border-color: $danger_light;
  }

  .checkbox-danger input[type="checkbox"]:checked+label::after {
    color: $white;
  }

  .checkbox-info input[type="checkbox"]:checked+label::before {
    background-color: $info_light;
    border-color: $info_light;
  }

  .checkbox-info input[type="checkbox"]:checked+label::after {
    color: $white;
  }

  .checkbox-warning input[type="checkbox"]:checked+label::before {
    background-color: $warning_dark;
    border-color: $warning_dark;
  }

  .checkbox-warning input[type="checkbox"]:checked+label::after {
    color: $white;
  }

  .checkbox-success input[type="checkbox"]:checked+label::before {
    background-color: $success;
    border-color: $success;
  }

  .checkbox-success input[type="checkbox"]:checked+label::after {
    color: $white;
  }

  .radio label::before,
  .radio label::after {
    font-family: 'FontAwesome';
    content: "\f10c";
    font-size: 20px;
    height: 20px;
    width: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    line-height: 19px;
    left: 0;
    top: 0;
    color: $gray_lightest;
    padding: 1px;
    transition: color 0.2s linear;
  }

  .radio input[type="radio"]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f192";
    color: $gray_lightest;
  }

  .radio input[type="radio"]:checked+label::after {
    color: $info_light;
  }

  .radio input[type="radio"]:disabled+label {
    color: $gray_lightest;
  }

  .radio input[type="radio"]:disabled+label::before,
  .radio input[type="radio"]:disabled+label::after {
    color: $gray_lightest;
  }

  .radio.radio-inline {
    margin-top: 0;
  }