.side-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;

  .side-modal-bkg {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    opacity: 0;
  }

  .side-modal-container {
    position: absolute;
    right: 0;
    top: 0px;
    height: 100%;
    width: 430px;
    background: $white;
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transform: translateX(100%);
    transition: .5s;
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .side-modal-container {
      transform: translateX(0%)
    }

    .side-modal-bkg {
      opacity: 1
    }
  }
}


.side-modal-header {
  padding: 15px;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title .title {
    margin: 0;
    font-weight: 400;
    line-height: 23px;
    color: $gray_darker;
  }
}

.side-modal-body {
  padding: 15px;
  max-height: 100%;
  overflow-y: scroll;
}

.side-modal-footer {
  padding: 15px;
  border-top: 1px solid $border-color;
}