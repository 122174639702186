.number-format-preview {
  height: 213px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lighten($gray_lighter, 10%);
}

.sequence-justify-length {
  display: flex;
  align-items: center;
  margin-bottom: 15;
}

.number-format-options {
  display: flex;
  flex-direction: column;
}

.voucher-books-number-formats {
  margin-bottom: 20%;
}

.number-format-spinner {
  margin-left: 8px;
}

.draggable-item.form-group {
  display: flex;
  align-items: center;

  .sort-handle .cursor-grab {
    display: flex;

    .fa-ellipsis-v.left {
      font-size: large;
      color: lighten($gray, 10%);
      margin-right: 4;
    }

    .fa-ellipsis-v.right {
      font-size: large;
      color: lighten($gray, 10%);
    }
  }
}

.voucher-book-show-page-vouchers {
  .title-with-btn {
    display: flex;
    border-bottom: 1px solid $border_color;
  }
}

.voucher-book-show-page {
  .table-card-body {
    padding-left: 0px;
    padding-right: 0px;

    table#generic-table {
      margin-bottom: 0px !important;
    }
  }
}