// /* Desktops, big landscape tablets and laptops(Large, Extra large) */
//   @media screen and (min-width: 1024px){
//     /*Style*/
//   }

// /* Tablet(medium) */
//   @media screen and (min-width : 768px) and (max-width : 1023px){
//     #main-wrapper {
//       width: 1024px;
//     }
//   }

// /* Smartphones (portrait and landscape)(small) */

@media screen and (min-width : 0px) and (max-width : 767px) {

  body {
    width: 1400px !important;
  }

  body.remove-mobile-responsive {
    width: auto !important;

    .login-main-container.main-content {
      .navbar-collapse.collapse {
        display: flex !important;
        padding-left: 0px;
        padding-right: 0px;
      }

      .form-container-fluid.container-fluid {
        .d-flex.align-items-center {
          .landing-left {
            margin-top: 0%;
            margin-bottom: 0px;
          }

          .product-owner-details {
            width: 100%;
            text-align: center;
          }

          .policies {
            width: 100%;
            text-align: center;
          }
        }
      }

      // organisations-screen
      .form-container-fluid.organisations-screen.container-fluid {
        .align-items-center {
          .landing-left {
            width: 90vw !important;
            max-width: 90vw !important;
            margin-top: 0%;
            margin-bottom: 0px;

            .list-of-account-tenants-title {
              margin-bottom: 30px;
            }
          }
        }
      }

      .form-container-fluid.new-organisation-page.container-fluid {
        .align-items-center {
          .landing-left {
            width: 90vw !important;
            max-width: 90vw !important;
            margin-top: 0%;
            margin-bottom: 0px;

            .list-of-account-tenants-title {
              margin-bottom: 30px;
            }
          }
        }
      }

      .form-container-fluid.update-account-profile-page.container-fluid {
        .align-items-center {
          .landing-left {
            width: 90vw !important;
            max-width: 90vw !important;
            margin-top: 0%;
            margin-bottom: 0px;

            .update-account-profile {
              padding-top: 0px;
              height: 60vh;
              overflow: auto;
            }
          }

          .product-owner-details {
            color: $label_color;

            a {
              color: $primary;
            }
          }

          .policies {
            a {
              color: $gray !important;
            }
          }
        }
      }

    }
  }

  body.login-page.remove-mobile-responsive {
    width: auto !important;

    .login-main-container.main-content {
      .login-main-left {
        width: 100% !important;

        .login.align-items-center {
          padding-left: 20px !important;
          padding-right: 20px !important;

          .product-details {
            left: 20px;
            top: 20px;
          }
        }
      }

      .login-main-right {
        display: none;
      }
    }
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
    border: none !important;

    .logout-nav {
      padding-top: 8px;
      padding-bottom: 8px;

      .dropdown-menu {
        max-height: 85vh !important;
        overflow: auto;

        li.user-menu-item.account-info,
        li.current-tenant-user-menu-item,
        li.user-menu-item-change-tenant,
        li.user-menu-item-update-profile,
        li.user-menu-item-change-password,
        li.user-menu-item-sign-out {
          a {
            padding: 9px 15px !important;
          }
        }

        li.listing-action-menu-item-header.current-account,
        li.listing-action-menu-item-header.switch-account,
        li.listing-action-menu-item-header.my-account {
          a {
            padding: 0px !important;
          }
        }
      }
    }
  }

  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .logout-nav {
    float: right !important;
  }

  .dropdown-menu {
    position: absolute !important;
    background-color: $white !important;
    -webkit-box-shadow: 0px 0px 21px $box-shadow !important;
    box-shadow: 0px 0px 21px $box-shadow !important;
  }

  .navbar-collapse .navbar-nav .navbar-form {
    padding: 0px !important;
    margin: 0px !important;
    border: none !important;
  }

  // .fixed-pagination {
  //   position: absolute;
  //   -webkit-transform: translateX(50%) !important;
  //   transform: translateX(50%) !important;
  //   // bottom: 0 !important;
  //   // margin: 20px 0 10px 0px !important;
  //   margin: 0px !important;
  //   display: block !important;
  // }

  .navbar-fixed-top .navbar .container-fluid .navbar-header {
    float: left !important;
  }

  .logout-nav {
    padding-right: 5px !important;
  }

  .index-listing-container-fluid .container-fluid {
    padding-top: 0px !important;
  }

  .index-listing-container-fluid.openListingInModal.container-fluid {
    .row .col-md-12 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .modal-dialog {
    width: 60%;
  }

  .active-top-report-options-dropdown.reports-container-fluid.toggled.container-fluid {
    padding-right: 0px !important;
  }

  body.sidebar-mini {
    #sidebar {
      position: relative;
      display: block;
      font-weight: 200;
      z-index: 3;
      width: 84px;
    }
  }

  .sidebar-mini .sidebar-wrapper .nav li a>i {
    margin-left: 13px;
    margin-right: 0px;
  }

  .sidebar-mini #sidebar .sidebar-mini {
    margin: -2px 0px 0 10px;
  }

  .sidebar-mini .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-mini {
    margin-left: 13px;
    margin-right: 0px;
  }

  #sidebar .sidebar-mini {
    width: 25px !important;
    height: 25px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.47);
    font-size: 11px;
    line-height: 26px;
    margin: -3px 9px 0 2px;
    text-shadow: 1px 1px 1px $primary;
  }

  body.off-canvas-sidebar.sidebar-mini .setting-page-tab ul.nav.nav-tabs {
    left: 113px;
    width: 89.5%;
  }

  .off-canvas-sidebar.sidebar-mini #popover-filter-form {
    left: calc((100% - (100% - #{$mini_sidebar_width})) + 30px) !important;
  }

  .sidebar-mini .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal {
    overflow: hidden;
  }

  .sidebar-mini .sidebar .sidebar-wrapper>.nav ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info>a>span,
  .sidebar-mini .sidebar .sidebar-wrapper>.nav li>a p {
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }

  .sidebar-mini .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal {
    overflow: hidden;
  }

  .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-mini,
  .sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-mini {
    text-transform: uppercase;
    float: left;
    width: 30px;
    text-align: center;
    margin-right: 15px;
    letter-spacing: 1px;
  }

  .visible-on-sidebar-mini {
    display: none !important;
    width: 12px;
  }

  .sidebar-mini .visible-on-sidebar-regular {
    display: none !important;
  }

  .sidebar-mini .visible-on-sidebar-mini {
    display: inline-block !important;
  }

  .sidebar-mini .sidebar,
  .sidebar-mini .sidebar .sidebar-wrapper {
    width: $mini_sidebar_width;
  }

  .sidebar-mini .main-panel {
    width: calc(100% - #{$mini_sidebar_width});
  }

  .sidebar-mini .sidebar {
    display: block;
    font-weight: 200;
    z-index: 3;
  }

  .sidebar-mini .sidebar .logo a.logo-mini {
    opacity: 1;
  }

  .sidebar-mini .sidebar .logo a.logo-normal {
    opacity: 0;
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  .sidebar-mini .sidebar .sidebar-wrapper>.nav ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info>a>span,
  .sidebar-mini .sidebar .sidebar-wrapper>.nav li>a p {
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }

  .sidebar-mini .logo {
    padding: 0px;
  }

  .sidebar-mini .sidebar-logo {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sidebar-mini .sidebar-logo-main .sidebar-logo {
    text-align: center;
    margin-left: 0;
  }

  .sidebar-mini .sidebar-logo-main .sidenav-toggle-sidebar-button-parent-div {
    display: none;
    padding: 0px;
  }

  .sidebar-mini .sidenav-brand-title {
    display: none;
    padding: 0;
  }

  .sidebar-mini .navbar-header {
    padding: 0 15px;
  }

  .sidebar-mini .navbar-minimize #minimizeSidebar {
    padding-left: 0px;
  }

  .sidebar-mini .navbar-fixed-top {
    left: 86px !important;
  }

  body.off-canvas-sidebar.sidebar-mini .table-full-width thead {
    left: 113px;
    width: 89.1%;
  }

  body.off-canvas-sidebar.sidebar-mini .bulk-action-body.table-full-width tfoot {
    left: 113px;
    width: 89.1%;
  }

  .sidebar-mini .top-bar {
    left: 84px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .content.table-full-width.table-responsive {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}