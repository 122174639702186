.print-format-main {
  height: 100%;
  // padding-top: 55px;
}

.tabs-with-custom-fields {
  z-index: 3;
}

.print-formats-tabs {
  padding-right: 0;
}

.print-format-tab-pane {
  margin-top: -15px;
}

.print-format-container .print-format-main-data {
  border-left: 1px solid $border-color !important;
  height: 100% !important;
  padding-left: 15px !important;
}

.print-format-form-elements .alert.alert-danger {
  margin-top: 30px !important;
  margin-bottom: 15px !important;
}

#print-format-top-tab-container .print-formats-tabs {
  padding-left: 0px !important;
  padding-top: 0px !important;
  position: initial !important;
  padding-right: 0;
  width: 100%;
}

#print-format-top-tab-container .nav-item-cols {
  flex-shrink: 0;
  padding-left: 45px !important;
  z-index: 5;
  background-color: white;
}

.print-format-left-nav {
  overflow-y: auto;
  // height: 100%;
  height: calc(100vh - 132px);
  padding: 30px 0px 0px 0px;

  li.nav-item {
    display: flex;
    align-items: center;
    background: $white;
    padding-right: 5px !important;

    a {
      width: 90%;
      background-color: $white !important;
      color: $secondary_darkest !important;
      padding: 5px 0px 5px 15px;
    }

    a:hover {
      cursor: pointer;
      color: $secondary_darkest;
      background-color: transparent;
    }
  }

  li.nav-item.active,
  li.nav-item.active a {
    color: $secondary_darkest !important;
    font-weight: 700 !important;
    background-color: $primary_lightest !important;
  }
}


.print-format-actions {
  width: 14px;
  padding-left: 0;
  padding-right: 0;
  border: 0px;
  background-color: transparent;
}

.print-format-actions:hover,
.print-format-actions:focus {
  background-color: transparent !important;
}

.print-format-menu-items {
  padding: 6px 15px;

  label {
    width: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
    margin: 0px !important;
    font-weight: 400 !important;
  }
}

.print-format-menu-items.delete.disabled label {
  color: $disabled_delete_button_color !important;
  cursor: not-allowed;
}

.print-format-menu-items.duplicate.disabled label {
  cursor: not-allowed;
  color: $secondary_light !important;
}

.print-format-menu-items:hover,
.print-format-menu-items label:hover {
  cursor: pointer;
  background-color: $th-default-bg-color;
}

.print-format-menu-items.duplicate label {
  color: $secondary_darkest !important;
}

.print-format-menu-items.delete label {
  color: $delete_button_color !important;
}

.print-format-form-elements .render-child-settable-form-elements {
  padding-left: 20px;
  padding-bottom: 10px;
}

.print-format-form-elements .setting-tab-item-check {
  padding-left: 0px;

  .check-hover {
    left: 0;
  }
}

.print-format-form-elements table.nested-form-table td {
  background-color: $white;

  &.action-co {
    padding-right: -10px;
  }

  .form-control {
    padding-left: 3px;
    padding-right: 3px;
  }

  .listing-item-actions {
    text-align: center;
  }
}

.print-format-form-elements table.nested-form-table {
  margin-bottom: 5px;

  th.title {
    padding-left: 3px;
    font-weight: normal !important;
    color: #2d363f !important;
  }

  th.action {
    padding-right: 6px;
  }

  td.title,
  td.model-attributes,
  td.width-percentage,
  td.actions {
    vertical-align: middle;
  }
}

.settings-form-elements.print-format-form-elements {
  .form-group.settings-sub-category {
    .settings-form-elements.print-format-form-elements {
      .dropdown-wrapper {
        z-index: 3;
      }
    }

    .sticky-setting-header-title {
      z-index: 4 !important;
    }

    .sticky-setting-header-title.Footer {
      z-index: 2 !important;
    }
  }
}

.print-format-form-fields {
  .print-format-name-label {
    color: $secondary_darkest !important;
  }

  .print-format-is-default-label {
    display: block;
    text-align: left;
    color: $secondary_darkest !important;
  }

  .print-format-is-default {
    line-height: 42px;
    height: 42;
    cursor: pointer;
  }
}

.print-format-form .fixed-action-footer {
  padding: 0px;

  .form-footer-btn {
    padding: 5px 0px 0px 0px;
  }
}

.print-formats-tabs a {
  padding-top: 5px !important;
}

.new-print-format-li-link a {
  color: $primary;
  cursor: pointer;
}

.print-format-default-field {
  height: 77px;
  display: flex;

  .flex.items-center {
    padding-top: 27px;
  }
}

.print-format-default-field.disabled .slider.slider-success.round {
  background-color: #a0c5a9 !important;
}

// Toggle
.setting-tab-item-check.small-toggle {
  .switch.mb0 {
    display: block;
    height: 20px !important;
    width: 42px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: $white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}

#print-formats-selection {
  .dropdown-toggle {
    height: 33px;
  }

  .dropdown-toggle .caret {
    border-top-color: $white !important;
  }

  .dropdown-toggle:hover,
  .dropdown-toggle:focus {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }

  .print-format-menu-item span {
    cursor: pointer;
  }

  .fa-spinner {
    font-size: 12px;
  }

  li:last-child {
    margin-top: 0px !important;
    border-top: 0px !important;
  }
}

.new-print-format-li-link {
  padding-left: 15px;
}

li.listing-action-menu-item-header a,
li.listing-action-menu-item-header:hover a {
  padding: 5px;
  margin-bottom: 0px !important;
  font-weight: 600;
  color: $gray;
  background-color: $white;
  cursor: default;
}

li.listing-action-menu-item-divider {
  margin: 0px !important;
}

li.listing-action-menu-item-divider.mt5 {
  margin-top: 5px;
}

li.listing-action-menu-item a {
  padding: 0px !important;
}

li.listing-action-menu-item {
  padding: 5px 5px 5px 10px !important;
}

li.listing-action-menu-item:hover,
li.listing-action-menu-item:hover a {
  background-color: $th-default-bg-color;
}

.listing-action-button-toolbar ul.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.listing-action-button-toolbar .dropdown.btn-group .dropdown-menu {
  display: none;
}

.listing-action-button-toolbar .dropdown.open.btn-group .dropdown-menu {
  display: block;
}

.add-new-items-table-button {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.helperContainerClass {
  width: auto;
  border: 1px solid #f0f1e8;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;

  &:active {
    cursor: grabbing;
  }

  &>td {
    padding-top: 7px;
  }

  &>td.row-handler-td .firstElement {
    display: flex;
    flex-direction: row;

    .handle {
      display: flex;
      width: 16;
      margin-left: 5px;
      padding: 0;
      cursor: grab;

      i.fa-ellipsis-v {
        font-size: large;
        color: rgb(136, 136, 136);
      }
    }
  }
}

.pdf-viewer-parent-div {
  z-index: 1;
}

.print-format-items-table {
  .nested-form-table tbody tr td {
    vertical-align: middle;
  }

  .nested-form-table tbody tr td.row-handler-td {
    padding: 0;

    .handle {
      display: flex;
      width: 16;
      margin-left: 5px;
      padding: 0px;
      cursor: grab;

      i.fa-ellipsis-v {
        font-size: large;
        color: rgb(136, 136, 136);
      }
    }
  }

  .nested-form-table tbody tr td.action-co {
    text-align: left;
    width: 0;
    min-width: 0;
    position: sticky;
    right: 0;
  }
}

.print-format-items-table .errors-message {
  font-weight: 400;
}

.print-format-pdf-viewer {
  height: calc(100vh - 132px);
}

.print-format-field-types {
  line-height: 42px;
  color: $secondary_darkest;
}

.print-format-btn-toolbar .dropdown-menu.dropdown-menu-right {
  z-index: 1;
}