$slider-offset: 4px;
$slider-dim: 15px;
$switch-width: 45px;
$switch-dim: $slider-dim + (2 * $slider-offset);
$switch-toggle-x: $switch-width - (2 * $slider-offset) - $slider-dim;
$switch-transition: .3s;

.switch {
  position: relative;
  width: $switch-width;
  height: $switch-dim;

  &:focus {
    outline: 1px solid $danger_dark;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray_lighter;
  -webkit-transition: $switch-transition;
  transition: $switch-transition;
}

.slider:before {
  position: absolute;
  content: "";
  height: $slider-dim;
  width: $slider-dim;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: $switch-transition;
  transition: $switch-transition;
}

input:checked+.slider {
  background-color: $primary;
}

input:checked+.slider.slider-success {
  background-color: $success;
}

input:checked+.slider.slider-danger {
  background-color: $danger;
}

input:focus+.slider {
  box-shadow: 0 0 0 2px $primary;
}

input:checked+.slider:before {
  -webkit-transform: translateX($switch-toggle-x);
  -ms-transform: translateX($switch-toggle-x);
  transform: translateX($switch-toggle-x);
}

.slider.round {
  border-radius: $switch-dim;
}

.slider.round:before {
  border-radius: 50%;
}