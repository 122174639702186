.alert {
  border: 0;
  border-radius: 2px;
  color: $white;
  padding: 10px 15px;
  font-size: 14px;
}

.container .alert {
  border-radius: 4px;
}

.navbar .alert {
  border-radius: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 85px;
  width: 100%;
  z-index: 3;
}

.navbar:not(.navbar-transparent) .alert {
  top: 70px;
}

.alert span[data-notify="icon"] {
  font-size: 30px;
  display: block;
  left: 15px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.alert button.close {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -13px;
  z-index: 1033;
  background-color: $white;
  display: block;
  border-radius: 50%;
  opacity: .4;
  line-height: 20px;
  font-size: 12px;
  width: 25px;
  height: 25px;
  outline: 0 !important;
  text-align: center;
  padding: 3px;
  font-weight: 300;
}

.alert button.close:hover {
  opacity: .55;
}

.alert .close~span {
  display: block;
  max-width: 89%;
}

.alert[data-notify="container"] {
  padding: 10px 10px 10px 20px;
  border-radius: 4px;
}

.alert.alert-with-icon {
  padding-left: 65px;
}

.alert a,
.alert a:hover,
.alert a:focus {
  text-decoration: underline;
  color: $white;
}

.flash-message-dismiss-btn {
  float: right;
  text-decoration: none !important;
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.28);
  width: 22px;
  height: 22px;
  text-align: center;
  // padding-top: 4px;
  border-radius: 32px;
}

.alert-info {
  color: $alert_info_text_color;
  background-color: $alert_info_bg_color;

  a {
    color: $primary_link_color !important;
  }

  .flash-message-dismiss-btn {
    border: 1px solid $alert_info_text_color;
    color: $alert_info_text_color !important;
  }
}

.alert-success {
  color: $alert_success_text_color;
  background-color: $alert_success_bg_color;

  a {
    color: $primary_link_color !important;
  }

  .flash-message-dismiss-btn {
    border: 1px solid $alert_success_text_color;
    color: $alert_success_text_color !important;
  }
}

.alert-warning {
  color: $alert_warning_text_color;
  background-color: $alert_warning_bg_color;

  a {
    color: $primary_link_color !important;
  }

  .flash-message-dismiss-btn {
    border: 1px solid $alert_warning_text_color;
    color: $alert_warning_text_color !important;
  }
}

.alert-danger {
  color: $alert_danger_text_color;
  background-color: $alert_danger_bg_color;

  a {
    color: $primary_link_color !important;
  }

  .flash-message-dismiss-btn {
    border: 1px solid $alert_danger_text_color;
    color: $alert_danger_text_color !important;
  }
}