.user-recents-left-sidenav {
  overflow-y: auto;
  padding-top: 15;
}

.user-recents-right-section {
  border-left: 1px solid $secondary_lighter;
  height: 100%;
  padding-top: 10;
}

.user-location-history {
  position: relative;
  // height: 100%;
  height: calc(100vh - 272px);
  display: flex;
  flex-direction: column;

  .bulk-action-right img {
    flex: 1 1 0% !important;
    object-fit: none !important;
  }
}

.suspended-item .fa-spinner,
.revoke-suspended-item .fa-spinner {
  margin-right: 6px;
}

.suspended-item.disabled,
.suspended-item.disabled:hover,
.suspended-item.disabled:focus {
  cursor: not-allowed !important;
  background-color: $gray_lighter !important;
}

.revoke-suspended-item.disabled,
.revoke-suspended-item.disabled:hover,
.revoke-suspended-item.disabled:focus {
  cursor: not-allowed !important;
  background-color: $gray_lighter !important;
}

.listing-action-menu-item-header.switch-account,
.listing-action-menu-item-header.current-account,
.listing-action-menu-item-header.my-organisations {
  margin: 9px;
}

.listing-action-menu-item-header.switch-account a,
.listing-action-menu-item-header.switch-account:hover a,
.listing-action-menu-item-header.current-account a,
.listing-action-menu-item-header.current-account:hover a,
.listing-action-menu-item-header.my-organisations a,
.listing-action-menu-item-header.my-organisations:hover a {
  font-size: 16px;
  padding: 0px;
  font-weight: 400;
  color: $black;
}

.listing-action-menu-item-header.my-organisations:hover a {
  cursor: pointer;
}

.listing-action-menu-item-header.my-account {
  margin: 9px;
}

.listing-action-menu-item-header.my-account a,
.listing-action-menu-item-header.my-account:hover a {
  font-size: 16px;
  padding: 0px;
  font-weight: 400;
  color: $black;
}

.current-tenant-user-menu-item {
  img {
    height: 24px !important;
  }

  a {
    cursor: default;
  }
}

.current-tenant-billing-menu-item {
  img {
    height: 24px !important;
  }

  a.billing-link {
    padding-left: 0px !important;
    color: #4d4d4d;
  }
}

.current-tenant-subscription-menu-item {
  img {
    height: 24px !important;
  }

  a.subscription-link {
    padding-left: 0px !important;
    color: #4d4d4d;
  }
}

.user-menu-item.account-info {
  font-size: 16px;
  cursor: default;

  a {
    cursor: default;
  }

  img {
    height: 28px;
    width: 28px;
  }
}

.user-menu-item-change-tenant a {
  padding: 5px 15px;

  img {
    width: 28px !important;
    height: 28px !important;
  }
}

.user-menu-item-change-tenant.disabled {
  opacity: 0.5;

  div.flex-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.change-password-screen {
  .change-password-set-password-title {
    font-weight: 500;
  }

  .password-wrapper {
    padding-left: 6px !important;
  }
}

.form-container-fluid.change-password-screen.container-fluid {
  .form-horizontal.change-password {
    color: $label_color !important;
  }

  .otp-wrapper {
    margin-bottom: 6px;
  }

  .resend-otp-wrapper {
    text-align: right;
    margin-bottom: 15px;

    .resend-otp-time-remaining {
      color: $label_color !important;
    }

    .resend-otp-number {
      color: $label_color !important;
      font-weight: 500;
      cursor: pointer;
    }

    .resend-otp-number.disabled,
    .resend-otp-number.disabled:hover,
    .resend-otp-number.disabled:active {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .password-wrapper .form-group .control-label {
    text-align: left !important;
  }
}

.user-listing-actions.listing-item-actions .dropdown-menu.dropdown-menu-right {
  width: 250px;
}

small.user-listing-delete-acccess-menu-item,
small.user-listing-suspend-access-menu-item,
small.user-listing-revoke-suspension-menu-item {
  font-weight: 500;
  color: $label_color;
}

.user-menu {
  .user-menu-item-update-profile {
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .user-menu-item-change-password {
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .user-menu-item-sign-out,
  .user-menu-item-sign-out:hover,
  .user-menu-item-sign-out:active {
    margin: 0px !important;

    #logout {
      margin: 0px !important;
    }

    a {
      color: #C23329 !important;
    }

    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

.bulk-actions-dashboard-and-mobile-users-wrapper {

  .dashboard-users-label,
  .mobile-users-label {
    font-weight: 400;
  }
}