.wrapper.wrapper-full-page {
  height: auto;
  min-height: 100vh;
}

/*.main-panel, .wrapper-full-page {
  -webkit-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  left: 0;
  width: 100%;
}
*/

/*.main-panel > .main-content, .wrapper-full-page > .main-content {
padding: 15px 0px;
}*/

.main-panel .card,
.wrapper-full-page .card {
  margin-bottom: 15px;
}

.animation-transition-general,
.login-page .card {
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.login-page>.content,
.lock-page>.content {
  padding-top: 22vh;
}

.login-page .card {
  -webkit-box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.login-page .card.card-hidden {
  opacity: 0;
  -webkit-transform: translate3d(0, -60px, 0);
  -ms-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}

.login-page .card .header {
  padding-bottom: 30px;
}

.login-page .card .btn-wd {
  min-width: 180px;
}

.full-page:after,
.full-page:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.full-page:after {
  background: $gray_darker;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $gray_darker), color-stop(100%, rgba(17, 17, 17, 0.4)));
  background: -webkit-linear-gradient(top, $gray_darker 0%, rgba(17, 17, 17, 0.4) 100%);
  background: -o-linear-gradient(top, $gray_darker 0%, rgba(17, 17, 17, 0.4) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from($gray_darker), to(rgba(17, 17, 17, 0.4)));
  background: linear-gradient(to bottom, $gray_darker 0%, rgba(17, 17, 17, 0.4) 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

.full-page>.content,
.full-page>.footer {
  position: relative;
  z-index: 4;
}

.full-page>.content {
  min-height: calc(100vh - 70px);
}

.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.full-page[data-image]:after,
.full-page.has-image:after {
  opacity: .9;
}

.full-page[data-color="blue"]:after {
  background: $primary;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary), color-stop(100%, rgba(0, 111, 230, 0.6)));
  background: -webkit-linear-gradient(top, $primary 0%, rgba(0, 111, 230, 0.6) 100%);
  background: -o-linear-gradient(top, $primary 0%, rgba(0, 111, 230, 0.6) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from($primary), to(rgba(0, 111, 230, 0.6)));
  background: linear-gradient(to bottom, $primary 0%, rgba(0, 111, 230, 0.6) 100%);
  background-size: 150% 150%;
}

.full-page .footer nav>ul a:not(.btn),
.full-page .footer,
.full-page .footer .copyright a {
  color: $white;
  font-size: 14px;
}

.full-page .footer .copyright {
  color: rgba(255, 255, 255, 0.5);
}

#loginForm .login-card {
  padding-top: 0px;
}

.reset-account-password-link-wrapper {
  text-align: right;
}

.get-it-on-google-play-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  width: 100%;
  text-align: center;

  a {
    cursor: pointer;
  }
}

.product-owner-details {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
  color: $white;
  width: 100%;
  text-align: center;

  a {
    font-weight: 700;
    color: $white;
  }
}

.policies {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  color: $gray_lighter;
  width: 100%;
  text-align: center;

  a {
    // font-weight: 700;
    color: $gray_lighter;
  }
}

h3.user-login-client-name {
  font-weight: 500;
  color: $white;
  margin-top: 10px !important;
  font-size: 22px;
}

.account-signin-or-signup {
  color: $white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.account-sign-up,
.account-sign-up:hover,
.account-sign-up:active {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;

  p.login-page-request-demo-label {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  a {
    color: $login_page_button_bg_color !important;
    cursor: pointer;
    font-weight: 500;
    background-color: $white !important;
  }
}