.integration-sync-not-found {
  font-weight: 500;
}

.tally-integration-complete-filter {
  padding-bottom: 10%;

  label[for='formControlsSelectBranches'],
  label[for='report-predefined-period'],
  label.control-label {
    height: 34px;
    display: flex;
    align-items: center;
  }

  .last-sync-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;

    .form-fields {
      display: flex;

      .action-wrapper {
        .integration-sync-states.btn-group {
          width: 100%;

          li.listing-action-menu-item.disabled {
            cursor: not-allowed;

            div[role="presentation"] {
              cursor: not-allowed;
            }

            span.menu-item-name {
              color: $gray_light;
            }

            span.menu-item-name:focus-visible {
              outline: none;
            }
          }
        }
      }

      div[class^="col-md"] {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  .new-sync-wrapper {
    .notification {
      padding: 6px;
      background-color: $gray_lighter;
    }

    .form-fields {
      display: flex;

      div[class^="col-md"] {
        padding-left: 6px;
        padding-right: 6px;
      }
    }

    .prepare-file-button-wrapper {
      display: flex;
      align-self: flex-end;
      // justify-content: flex-end;
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
}