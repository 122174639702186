$font-size: 15px;
$bg-color: $white;

$base-color: $black;
$base-size: 0.9em;
$base-link-color: darken(#4183c4, 10%);
$base-family: "Roboto", sans-serif;
$code-family: Consolas, Inconsolata, Courier, monospace;

$line-height: 1.6;

$base-margin: 0;
$base-padding: 0;
$border-color: darken($bg-color, 10%);

$bg-color-table: darken($bg-color, 1%);

.help-container {
  font-family: $base-family;
  font-size: $font-size;
  background: $bg-color;
  line-height: $line-height;
  -webkit-font-smoothing: antialiased;

  a {
    color: $base-link-color;

    &:hover {
      color: lighten($base-link-color, 10);
    }
  }

  h2 {
    border-bottom: 1px solid darken($bg-color, 10);
    line-height: $line-height;
  }

  h6 {
    color: darken($gray, 5%);
  }

  hr {
    border: 1px solid darken($bg-color, 10);
  }

  pre {
    & > code {
      font-size: 0.9em;
      font-family: $code-family;
    }
  }

  p,
  li,
  td,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    & > code {
      //border: 1px solid #efdfd0;
      background-color: rgba(0, 0, 0, 0.07);
      font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
      font-size: 85%;
      padding: 0.2em 0.5em;
      border: 0;
    }
  }

  blockquote {
    border-left: 4px solid $border_color;
    padding: 0 15px;
    font-style: italic;
  }

  table {
    background-color: darken($bg-color, 2%);

    tr {
      th,
      td {
        border: 1px solid darken($bg-color, 10%);
      }
      &:nth-child(2n) {
        background-color: darken($bg-color, 5%);
      }
    }
  }
}
