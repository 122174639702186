.card {
  /*border-radius: 4px;*/
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);*/
  background-color: $white;
  /*margin-bottom: 30px;*/
}

.card .image {
  width: 100%;
  overflow: hidden;
  height: 260px;
  border-radius: 4px 4px 0 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card .image img {
  width: 100%;
}

.card .filter {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
}

.card .filter .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card:hover .filter {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .btn-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}

.card:hover .btn-hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card .content {
  padding: 15px 15px 10px 15px;
}

.card .header {
  padding: 0px 15px 0;
}

.card .category,
.card label {
  font-size: 14px;
  font-weight: 400;
  /*color: $gray_light;*/
  color: $gray_dark;
  margin-bottom: 0px;
}

.card .category i,
.card label i {
  font-size: 14px;
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  /*text-transform: uppercase;*/
}

.card .title {
  margin: 0;
  color: $gray_dark;
  font-weight: 300;
}

.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.card .description {
  font-size: 14px;
  color: $gray_darker;
}

.card .footer {
  padding: 0;
  background-color: transparent;
  line-height: 30px;
  padding-bottom: 15px;
}

.card .footer .legend {
  padding: 5px 0;
}

.card .footer hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card .stats {
  color: $gray_light;
}

.card .footer div {
  display: inline-block;
}

.card .author {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.card .author i {
  font-size: 14px;
}

.card h6 {
  font-size: 12px;
  margin: 0;
}

.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: $gray_lightest;
  content: "";
  position: absolute;
}

.card .ct-chart {
  margin: 30px 0 30px;
  height: 245px;
}

.card .table tbody td:first-child,
.card .table thead th:first-child {
  padding-left: 5px;
}

.card .table tbody td:last-child,
.card .table thead th:last-child {
  padding-right: 5px;
}

.card .alert {
  border-radius: 4px;
  position: relative;
}

.card .alert.alert-with-icon {
  padding-left: 65px;
}

.card-user .image {
  height: 110px;
}

.card-user .image-plain {
  height: 0;
  margin-top: 110px;
}

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px;
}

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid $white;
  position: relative;
  margin-bottom: 15px;
}

.card-user .avatar.border-gray {
  border-color: $gray_lightest;
}

.card-user .title {
  line-height: 24px;
}

.card-user .content {
  min-height: 240px;
}

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px;
}

.card-user hr,
.card-price hr {
  margin: 5px 15px;
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.card-plain .image {
  border-radius: 4px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.card-stats .icon-big i {
  font-weight: bold;
  line-height: 59px;
}

.card-stats .numbers {
  font-size: 2em;
  text-align: right;
}

.card-stats .numbers p {
  margin: 0;
}