@import "./variables.module";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://cdn.materialdesignicons.com/3.5.95/css/materialdesignicons.min.css");

.wrapper .ps {
  touch-action: auto;
  overflow: hidden !important;
}

.sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal,
.sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal {
  margin: 0;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
  white-space: nowrap;
  display: block;
}

.sidebar-mini .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal {
  overflow: hidden;
}

.sidebar .sidebar-wrapper li>ul.nav {
  margin-top: 0px;
}

.logout-nav a:first-child:after {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $white;
  position: absolute;
  top: -8px;
  right: 9px;
}

.logout-nav .dropdown-menu {
  min-width: 200px !important;
  margin: 16px 0 0 0 !important;
  max-height: 85vh !important;
  overflow: auto;
}

.show-page-header-action-nav {
  align-items: center;

  .dropdown-menu {
    right: 0;
    left: unset;
    float: none;
    position: absolute;
  }
}

.invoice-show-page-invoice-items,
.navbar-fixed-top {
  .dropdown-menu>li:last-child>a {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.invoice-show-page-invoice-items,
.navbar-fixed-top {
  .dropdown-menu>li:first-child>a {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.invoice-show-page-invoice-items,
.navbar-fixed-top {
  .dropdown-menu {
    border: none;
    border-radius: 2px !important;
    box-shadow: 0px 0px 21px $box_shadow;
    margin: 7px 0 0 0;
    // min-width: 350px !important;
    padding: 12px 0;

    i {
      font-size: 17px;
      height: auto;
      line-height: 19px;
      width: 20px;
      margin: 0 9px 0 0px;
    }

    a {
      padding: 9px 15px;

      &#logout {
        margin: 12px 0 0px 0;
      }
    }

    li:last-child {
      margin: 12px 0 0px 0;
      border-top: 1px solid $secondary_lighter;
    }
  }
}

.index-dropdown-section,
.form-dropdown-section {
  a {
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    padding: 3px 30px !important;
    color: $secondary_darkest;

    &:hover {
      color: $black;
      background: $secondary_lightest;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 12px;
    }
  }

  span {
    padding: 0 15px;
    font-size: 13px;
  }

  border-bottom: 1px solid $border_color;
  margin: 0px 0 9px 0;

  &:last-child {
    border: none;
    margin: 0px;

    a {
      &:last-child {
        margin: 0px;
      }
    }
  }
}

.form-header-breadcrumb {
  width: fit-content;

  .title-with-dropdown {
    display: flex;

    .header-breadcrumb.title {
      margin-right: 10px;
      padding-top: 1px;
    }
  }
}

.invoice-show-page-invoice-items .dropdown-menu {
  li:last-child {
    margin: 0px;
    padding: 6px 15px;
    border-top: none;
  }
}

.bootstrap-table .open .dropdown-menu,
.dropdown.open .dropdown-menu,
.dropup.open .dropdown-menu,
.navbar-nav>li.open>.dropdown-menu {
  margin-top: 0px;
  min-width: 160px;
  margin-top: 12px;
}

.fa-trash-o {
  cursor: pointer;
}

.error,
.error .form-group {

  input,
  textarea,
  select {
    border-color: $danger_input_field_border_color;
  }

  .Select .Select-control {
    border-color: $danger_input_field_border_color;
  }

  .Select-menu-outer {
    border-color: $danger_input_field_border_color;
    border-top-color: transparent;
  }
}

td.error .Select-menu-outer {
  border-color: $danger_input_field_border_color;
  border-top-color: transparent;
}

.warning,
.warning .form-group {

  input,
  textarea,
  select {
    border-color: $warning_input_field_border_color;
  }

  .Select .Select-control {
    border-color: $warning_input_field_border_color;
  }

  .Select-menu-outer {
    border-color: $warning_input_field_border_color;
    border-top-color: transparent;
  }
}

.errors-message,
.warnings-message {
  font-size: 12px;
  margin-top: 3px;
  display: block;
}

.errors-message {
  color: $errors_message;
}

.warnings-message {
  color: $warnings_message;
}

#minimizeSidebar {
  background-color: transparent;
  border: none !important;
  color: $gray_lighter;
  // width: 39px;
  font-size: 17px !important;
}

.pe-7s-config {
  color: $primary;
}

.card {
  .header {
    .card-header {
      padding: 0px;

      .card-header-title h4 {
        margin: 0 0 15px 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 15px;

        small {
          font-size: 12px;
          font-weight: 400;
          margin: 6px 0 0 0;
          display: inline-block;
        }
      }
    }
  }

  .sign-in-page-content {
    padding: 15px !important;
  }
}

#basic-nav-dropdown-right {
  background-color: $white;
  padding: 0px;
}

// Search input field and button styles.
.card-header-search .row-fluid {
  .form-group {
    text-align: center;

    #noraml-serach-input-field {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      height: 32px !important;
      padding-right: 20px !important;
    }

    #noraml-serach-button {
      border-radius: 20px !important;
      padding: 5 20px !important;
      margin-left: -16px;
      z-index: 4 !important;
    }
  }
}

#main-wrapper {
  .wrapper {
    #sidebar {
      .logo {
        border-bottom: none;
        // text-align: center;
      }
    }
  }
}

// Custom radio button style.
.radio-button {
  .radioInline {
    display: inline-block;
    margin-right: 20px;
  }

  .radioInline+.radioInline {
    margin-left: 10px;
  }

  .radioButton {
    color: $gray_light;
    font-size: 12px;
    position: relative;
  }

  .radioButton span {
    position: relative;
    padding-left: 20px;
  }

  .radioButton span:after {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid;
    position: absolute;
    left: 0;
    top: 1px;
    border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .radioButton input[type="radio"] {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  .radioButton input[type="radio"]:checked+span {
    color: $gray_light !important;
  }

  .radioButton input[type="radio"]:checked+span:before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    background: $gray_light;
    left: 5px;
    top: 6px;
    border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
  }
}

// Datepicker style to display full width input field.
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

// from group style
.form-horizontal .form-group {
  margin-left: 0px !important;
  margin-right: 0px !important;
  // margin-bottom: 5px !important;
}

.assign-role-title {
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
}

.users_group_panel_body .row {
  margin-bottom: 15px;
}

.pagination .active {
  a {
    z-index: 0;
  }
}

.rotate-180 {
  transform: rotate(180deg);
  -webkit-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.show-detail-info {
  .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;

    .col-md-3 {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.image-parent {
  position: relative;
  text-align: center;

  .delete-image {
    color: $white;
    border: none;
    cursor: pointer;
  }
}

.image-inner-div {
  border: 1px solid $gray_input_field_border_color;
  border-radius: 15px;
  margin: 0 0 16px 0;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px $box_shadow;
}

.image-inner-div img {
  width: 100%;
  height: 185px;
  object-fit: scale-down;
  background: rgba(204, 204, 204, 0.28);
}

.image-inner-div .btn-danger {
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 8;
  border-radius: 50px;
  width: 30px;
  min-height: 30px;
  padding: 0px;
  opacity: 0.7;
  border: none;
}

.image-inner-div:hover .btn-danger {
  opacity: unset;
}

.image-inner-div.error {
  border: 1px solid $danger_input_field_border_color;
}

.uplaod-btn input {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  min-height: auto;
  height: auto;
  cursor: pointer;
}

.uplaod-btn {
  position: relative;
  border: 2px dashed $gray_lighter;
  box-shadow: 0px 0px 10px $box_shadow;
  border-radius: 15px;
  height: 185px;
  overflow: hidden;
  text-align: center;
}

.uplaod-btn .fa {
  margin: 54px 0 7px -30px;
  font-size: 45px;
  color: $gray_lighter;
}

.uplaod-btn p {
  font-weight: 600;
  text-transform: uppercase;
  color: $gray;
}

.row.product-images-row {
  padding: 0 9px;
}

.customer-address-box {
  padding: 18px;
  overflow: auto;
  border-radius: 3px;
  background: hsla(165, 17%, 86%, 0.14);
  font-size: 13px;
  line-height: 22px;
  padding-left: 73px;
  position: relative;
  color: $secondary_darker;
  border: 1px solid $gray_lighter;
  min-height: 110px;
}

.customer-address-box .fa {
  position: absolute;
  left: 24px;
  font-size: 25px;
  top: 23px;
  color: #282c3f;
}

// Custome react-datepicker css.

// .btn-group button {
//   margin-right: 5px;
//   border-radius: 3px !important;
// }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  opacity: 0.7;
}

/*
::-webkit-scrollbar-button {
  background: $gray_lighter;
  border-radius: 50px;
}
*/

#sidebar ::-webkit-scrollbar-track-piece {
  background: $sidebar_nav_background;
}

#sidebar ::-webkit-scrollbar {
  width: 14px;
}

#sidebar ::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.35);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  opacity: 0.3;
}

#sidebar ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

#sidebar ::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background: $white;
}

::-webkit-scrollbar-thumb {
  background: darken($gray_lighter, 10%);
  ;
}

.modal-dialog ::-webkit-scrollbar-thumb {
  background: darken($gray_lighter, 20%);
}

.form-container-fluid.container-fluid {
  margin-right: 3px !important;
}

.modal-dialog .new-advance-search-modal-body.for-show-page.modal-body {
  margin-right: 0px;

  .form-container-fluid.container-fluid {
    margin-right: 0px !important;
  }
}

#main-panel {

  .listing-card-2-container .index-listing-container-fluid.container-fluid,
  .feed-container-fluid.container-fluid {
    margin-right: 3px;
  }
}

.modal-dialog .modal-body {
  margin-right: 3px;
}

.modal-dialog .new-advance-search-modal-body.for-show-page.modal-body {
  margin-right: 0px;
}

hr {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.fa-pencil-square,
.fa-download,
.fa-trash-o,
.fa-money {
  display: inline-block;
  width: 1em;
  text-align: center;
}

// Remove number text-field spin button.
input[type="number"] {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

// Accordion
.panel-heading {
  .panel-title {
    cursor: pointer;

    a {
      font-size: 15px;
    }
  }

  a {
    &:before {
      font-family: "Glyphicons Halflings";
      content: "\e113";
      float: right;
      transition: all 0.5s;
    }

    &.collapsed:before {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

// Home card css.

.dms-panel {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: $white;

  .content {
    padding: 0px;
  }

  .panel-heading {
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.chart-panel-filters {
  background: $gray_lighter;
  width: 100%;
  padding: 6px 0;
  margin-bottom: 15px;
  margin-top: 15px;
  display: inline-block;
}

.chart-panel-filters-common {
  margin: 2px 0px 21px 0px;
}

.chart-panel-filters .col-md-2,
.chart-panel-filters .col-md-1 {
  padding: 0 3px;
  font-size: 12px;
}

.chart-panel-filters input.chart-header-date-picker-input {
  border: none;
  height: 29px;
  border-radius: 2px;
  text-align: center;
  padding: 5px;
}

.chart-panel-filters-end-date {
  height: 29px;
  background: $white;
  color: $gray_dark;
  border-radius: 2px;
  text-align: center;
  padding: 5px;
  line-height: 20px;
  cursor: not-allowed;
}

.chart-panel-filters .form-control {
  border: none;
  height: 29px !important;
  border-radius: 2px;
  padding: 5px;
}

.bp-btns a {
  background: $secondary_lighter;
  color: $white;
  height: 28px;
  border-radius: 2px;
  width: 28px;
  display: inline-block;
  text-align: center;
  padding: 3px 0;
}

.left-hyphen:before {
  content: "-";
  position: absolute;
  background: #d2d2d3;
  width: 14px;
  height: 14px;
  text-align: center;
  font-size: 18px;
  line-height: 12px;
  margin: 7px 0 0 -10px;
  border-radius: 50px;
}

.chart-panel-header b {
  color: $gray_dark;
}

.chart-panel-header .col-md-6 {
  padding: 4px 15px;
}

.chart-panel-header {
  min-height: 50px;
}

span.recharts-legend-item-text {
  font-size: 12px;
  color: $gray_dark;
}

.performance-panel .col-md-12.period-till-date,
.performance-panel .col-md-6 {
  padding: 0px;
}

.performance-panel .col-md-4.text-center.title {
  text-align: left;
}

.home-card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;

  i {
    font-weight: 700;
    line-height: 59px;
  }
}

.home-card-stats .numbers {
  font-size: 1.6em;
  text-align: right;
  word-wrap: break-word;

  p {
    margin: 0px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.home-card .stats {
  color: $gray_light;
}

.home-graph-card {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 15px;

    .title {
      margin: 0px;
    }

    .category {
      font-size: 14px;
      font-weight: 400;
      color: $gray_light;
      margin-bottom: 0;
    }
  }
}

.chart-header-date-picker-input {
  width: 100%;
}

.card-state-tooltip>.tooltip-inner {
  background-color: $white;
  color: $black;
  border: 1px solid $primary_darkest;
}

#day-book-tally-integration-tabs .tab-content {
  padding-bottom: 170px;
}

.show-page-header-button .btn-sm {
  line-height: 1.5;
  margin: 0px 1px !important;
  color: $white !important;
  cursor: pointer;

  .fa {
    color: $white;
  }
}

.show-page-header-button a {
  color: inherit;
}

.show-page-header-button.destroy,
.show-page-header-button.destroy:hover {
  cursor: pointer;

  .destroy-action-btn {
    // background-color: $white;
    color: $danger_light;
    font-size: 14px;
    font-weight: 500;
  }
}

.show-page-header-action-nav ul.dropdown-menu {
  padding: 0px;

  .show-page-header-button {
    margin: 0px;
    border: none;
  }
}

// .show-page-header-action-nav .btn-group-sm .btn-sm {
//   padding: 5px 14px;
// }

.show-page-header-button .btn-danger:hover,
.show-page-header-button .btn-danger:focus {
  background-color: $danger_light !important;
  border-color: $danger_light !important;
}

.advance-search-modal-header,
.new-advance-search-modal-header,
.quick-add-modal-header {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;

  button.close:focus,
  button.close:active {
    outline: none;
  }

  .modal-title {
    font-weight: 400;
  }
}

.quick-add-modal-header {
  .quick-add-modal-title {
    .quick-add-modal-title-content {
      padding-left: 6px;
      padding-right: 6px;
    }

    .quick-add-modal-action-btn {
      padding-left: 6px;
      padding-right: 6px;
      display: flex;
      justify-content: end;
    }
  }
}

.advance-search-modal-title {
  .advance-search-modal-title-content {
    padding-left: 6px;
    padding-right: 6px;
  }

  .advance-search-modal-title-action-btn {
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
  }
}

.common-modal {
  .common-modal-title-content {
    padding: 0px;
  }

  .common-modal-title-action {
    padding: 0px;
    display: flex;
    justify-content: end;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .common-modal {
    .common-modal-title-content {
      padding: 0px;
    }

    .common-modal-title-action {
      padding: 0px;
      justify-content: end;
      display: -webkit-flex;
      -webkit-justify-content: flex-end;
    }
  }
}

// .advance-search-modal-body {
//   height: auto !important;
//   max-height: calc(100vh - 123px) !important;
//   overflow-y: scroll;
// }

.advance-search-modal-body .form-group .Select {
  height: 40px;
}

// .advance-search-modal-body.modal-body {
//   height: 55vh !important;
// }

.sidebar-mini .sidebar-wrapper .nav li a>i {
  margin-left: 13px;
  margin-right: 0px;
}

.sidebar-mini #sidebar .sidebar-mini {
  margin: -2px 0px 0 10px;
}

.sidebar-mini .sidebar .sidebar-wrapper .nav ul>li>a .sidebar-mini {
  margin-left: 13px;
  margin-right: 0px;
}

table tr>th>.table-head {
  font-size: 14px;
  text-transform: uppercase;
}

.inner-table tr th {
  text-transform: uppercase;
  font-size: 14px;
  border-top: 1px solid $th-default-bg-color !important;
}

.modal-header .modal-title {
  font-weight: 400;
}

.modal-header .close {
  margin-top: 4px;
}

.order-listing-bulk-actions {
  padding: 0;

  ul.dropdown-menu {
    li.bulk-action-menu-item.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.product-variants-tab-add-variant-btn {
  padding: 15px 0px;
}

.password-validation-hint {
  color: $primary;
  font-size: 14px !important;
}

.customer-show-credit-leeway-tab .select-credit-leeway {
  float: right;
}

.debit-reason-grid-class-name,
.credit-reason-grid-class-name {
  height: 100%;
}

.customer-show-merge-duplicate-customer-info {
  margin: 0px;
}

.beat-routes-show-filter-customer-panel-title {
  cursor: pointer;
}

.beat-routes-show-filter-customer-panel {
  border: none;
  box-shadow: none;
}

.payments-show-create-invoice-payment-item {
  .select-invoice {
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .amount,
  .discount,
  .total {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fa-info-circle {
    font-size: 14px !important;
  }
}

.payment-show-edit-invoice-payment-item {

  .amount,
  .discount,
  .total {
    padding-left: 0px;
    padding-right: 0px;

    .form-group {
      margin-bottom: 0px;
    }
  }

  .fa-info-circle {
    font-size: 14px !important;
  }
}

.card table tbody tr.accordian-body {
  background-color: $white;

  th {
    font-size: 14px;
  }
}

.credit-notes-create-invoice {

  .select-invoice,
  .amount {
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .fa {
    font-size: 14px !important;
  }
}

.credit-note-edit-cn-invoice {
  .amount {
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-group {
    margin-bottom: 0px;
  }
}

.tally-integration-daybook {
  margin-top: 5%;
}

.page-show-details div {
  padding: 0 6px;
  color: $gray;
}

.page-show-details div.col-md-1 {
  padding-right: 0px;
  text-align: right;
}

.page-show-details div b {
  color: $gray_dark;
}

.alert {
  padding: 15px !important;
  font-size: 14px;
}

.flash-message-type {
  font-size: 14px;
  font-weight: 600;
}

.flash-message-key {
  text-transform: capitalize;
}

.breadcrumb-with-btn-actions {
  padding-top: 5px;
}

.breadcrumb-with-btn-delete-btn {
  cursor: pointer;
}

.new-btn {
  .plus-icon {
    font-size: 14px;
  }
}

.home-card-stats {
  h5.text-muted {
    margin: 0px 0px 5px 0px;
  }

  .fa-info-circle {
    font-size: 16;
  }
}

.merge-duplicate-customer-confirmation-modal-form {
  margin-bottom: 0px;
}

.period-till-date {

  .title,
  .amount {
    padding: 0px;
    font-size: 13;
  }

  .show-caret {
    padding: 0px;

    .text-success,
    .text-danger {
      font-size: 18;
      vertical-align: middle;
    }
  }
}

.popover-trigger-hover-focus {
  .content-list {
    padding-left: 10px;
  }
}

// .modal-dialog::before {
//   height: calc(100vh - (1.75rem * 2));
//   display: block;
//   content: "";
// }

.modal-dialog {
  height: calc(100vh - (1.75rem * 2));
  display: block;
  content: "";
}

.modal-dialog {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.75rem auto;
}

.modal-dialog,
.modal-content {
  color: initial;
  //   max-height: calc(100% - (.5rem * 2));
  // Commented this style to make the modal expand to the content placed inside it
  // max-height: 90vw;
}

.modal-body {
  max-height: calc(100% - 169px);
  overflow-y: scroll;
}

.modal-content {
  box-shadow: none;
  border-radius: 3px;
  border: none;
  width: 100%;
}

#sidebar .sidebar-mini {
  width: 25px !important;
  height: 25px;
  border-radius: 50px;
  background: $white;
  font-size: 11px;
  line-height: 26px;
  margin: -3px 9px 0 2px;
  // text-shadow: 1px 1px 1px $primary;
  color: $sidebar_nav_background;
}

.notification {
  margin: 0px !important;
}

.notification-success {
  background-color: $success_notification_bg_color !important;

  .notification-message {
    color: $success_notification_text_color !important;
  }

  .notification-dismiss {
    padding: 5px 3px 3px !important;
    border: 1px solid $success_notification_text_color !important;
    color: $success_notification_text_color !important;
    background-color: $success_notification_bg_color !important;
    height: 22px !important;
    width: 22px !important;
    opacity: 1 !important;
    font-weight: 600 !important;
  }

  .pe-7s-like2 {
    color: $success_notification_text_color !important;
  }
}

.notification-error {
  background-color: $error_notification_bg_color !important;

  .notification-message {
    color: $error_notification_text_color !important;
  }

  .notification-dismiss {
    padding: 5px 3px 3px !important;
    border: 1px solid $error_notification_text_color !important;
    color: $error_notification_text_color !important;
    height: 22px !important;
    width: 22px !important;
    opacity: 1 !important;
    font-weight: 600 !important;
  }

  .pe-7s-attention {
    color: $error_notification_text_color !important;
  }
}

.performance-item {
  h6 {
    color: $gray_darker;
    font-size: 18px;
    margin: 0 0 5px 0;
  }

  p {
    font-size: 12px;
    color: darken($gray, 7%);
    margin: 0px;
  }

  .performance-item-chart {
    width: 80px;
    height: 80px;
    border: 2px solid #d0cccc;
    margin: auto;
    border-radius: 50px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    background: lighten($gray_lighter, 10%);
    line-height: 77px;
    box-shadow: 0px 0px 7px $box_shadow;
    margin-bottom: 14px;
    color: $gray_light;
  }
}

.performance-danger .performance-item-chart {
  border: 2px solid lighten($danger, 20%);
  background: $danger_lightest;
}

.performance-danger .performance-item-chart .text-danger {
  color: lighten($danger, 20%) !important;
}

.performance-success .performance-item-chart {
  border: 2px solid $success_light;
  background: $success_lighter;
}

.performance-success .performance-item-chart .text-success {
  color: lighten($success, 5%) !important;
}

.actions-box {
  background: $gray_lighter;
  overflow: hidden;
  border-radius: 3px;
}

.actions-box h5.text-muted {
  padding: 5px 15px 12px 15px;
  border-bottom: 1px solid $white;
}

.actions-box p {
  font-size: 13px;
}

.customer-details-panel .panel-body {
  font-size: 13px;
}

.customer-details-panel .page-show-details {
  padding: 2px 0;
}

.customer-details-panel .page-show-details b {
  color: $gray_dark;
  font-weight: 600;
  font-size: 12px;
}

.customer-recents-panel th {
  font-size: 13px;
}

.customer-recents-panel .panel-body {
  padding-top: 0px;
}

.customer-recents-panel .nav-tabs {
  margin: 0 -15px;
  padding: 0 16px;
  margin-bottom: 15px;
}

.logout-nav {
  padding-right: 15px !important;
}

.logout-nav {
  .user-menu.dropdown-with-icons {
    height: 34px;
    width: 34px;
    border: 1px solid $gray;
    border-radius: 50%;
    text-align: center;
    background: transparent;

    #basic-nav-dropdown-right {
      height: 34px;
      line-height: 34px;
      background: transparent;
      margin-top: -1px;
      font-weight: 500;
    }

    #basic-nav-dropdown-right:hover {
      color: $primary_button_color_on_hover;
    }
  }
}

.accordian-body thead {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  display: contents;
}

tr.open {
  cursor: pointer;
}

.table-responsive .payment-listing-main .table-header-group {

  tr.open,
  tr.received,
  tr.accounted,
  tr.failed {
    cursor: pointer;
  }
}

.table-header-group {
  display: table-header-group;
}

.master-checkbox-th {
  width: 36px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.master-checkbox-th input {
  margin: 0px;
}

.check-hover {
  width: 36px;
  cursor: default;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 0px !important;
}

.check-hover input {
  margin: 0px;
}

.check-hover:hover,
.check-hover:focus {
  background: lighten($gray, 41%);
  cursor: default;
  border: none;
  outline: none;
}

.action-co {
  width: 100px;
}

.pn-td {
  width: 124px;
}

.invoices-number {
  width: 150px;
}

label.control-label,
label {
  margin-bottom: 5px !important;
  font-weight: 400 !important;
  color: $label_color !important;
  font-size: 12px !important;
}

div.control-label {
  font-weight: 400 !important;
  color: $label_color !important;
  font-size: 12px !important;
}

.advance-search-modal-body label.control-label,
.new-advance-search-modal-body label.control-label {
  color: $label_color;
}

.advance-search-modal-footer,
.new-advance-search-modal-footer {
  text-align: right;
  // padding: 20px 30px;
}

.modal-footer {
  text-align: right;
}

.nav-tabs {
  border-bottom: 2px solid $border_color !important;
  margin-bottom: 15px;
}

.nav-tabs>li>a,
.nav-tabs>li>div {
  border: none !important;
  padding: 18px 0;
  margin: 0px;
  position: relative;
  font-weight: 500;
  color: $gray_dark !important;
  font-size: 14px;
  text-transform: uppercase;
}

.nav-tabs>li {
  margin: 0 20px 0 0px;
  position: relative;
}

.nav-tabs>li.active a,
.nav-tabs>li.active div,
.nav-tabs>li a:hover,
.nav-tabs>li div:hover {
  color: $primary !important;
}

.nav-tabs>li:after {
  content: "";
  position: absolute;
  background: $border_color;
  left: 0;
  right: 0;
  height: 2px;
}

.nav-tabs>li.active:after,
.nav-tabs>li:hover:after {
  background: $primary;
}

.btn-info.action-btn,
.btn-primary.action-btn,
.btn-success.action-btn {
  background: $white !important;
  color: $primary !important;
  border: 1px solid $primary !important;
}

.btn-info.action-btn:hover,
.btn-primary.action-btn:hover,
.btn-success.action-btn:hover {
  background: $primary !important;
  color: $white !important;
}

.btn-default.action-btn {
  background: $white !important;
  color: $gray_light !important;
  border: 1px solid $gray_light !important;
}

.btn-default.action-btn:hover,
.btn-default.action-btn:focus {
  background: $gray_light !important;
  color: $white !important;
}

.modal-dialog,
.side-modal-container {
  .btn-default.action-btn {
    background: $white !important;
    color: $gray_light !important;
    border: 1px solid $primary !important;
  }

  .btn-default.action-btn:hover,
  .btn-default.action-btn:focus {
    background: $white !important;
    color: $white !important;
  }
}

.btn-danger.action-btn {
  background: $white !important;
  color: $delete_button_color !important;
  border: 1px solid $delete_button_color !important;
}

.btn-danger.action-btn:hover {
  background: $delete_button_color !important;
  color: $white !important;
}

.tooltip {
  font-family: "Roboto", sans-serif;
}

.btn-primary-outline {
  color: $primary;
  border: 1px solid $primary;
  background: $white;
}

.btn-primary-outline:hover,
.btn-primary-outline:active,
.btn-primary-outline:focus {
  color: $primary;
  border: 1px solid $primary;
}

.btn-warning-outline {
  color: #f2a100;
  border: 1px solid #f2a100;
  background: $white;
}

.btn-warning-outline:disabled {
  background: $white;
  color: $gray_light !important;
}

.btn-warning-outline:hover,
.btn-warning-outline:active,
.btn-warning-outline:focus {
  color: #f2a100;
  border: 1px solid #f2a100;
}

.btn-danger-outline {
  color: $danger_light;
  background: $danger_lightest;
  border: 1px solid $danger_lighter;
}

.btn-danger-outline:hover,
.btn-danger-outline:active,
.btn-danger-outline:focus {
  color: $danger_light;
  background: $danger_lightest;
  border: 1px solid $danger_light;
}

.btn-danger-outline:disabled,
.btn-danger-outline:hover:disabled,
.btn-danger-outline:focus:disabled {
  color: $gray_light;
  background-color: transparent;
  cursor: not-allowed;
}

.table.payments-table thead th {
  padding: 12px 3px !important;
}

.table.payments-table td {
  padding: 19px 3px !important;
}

.form-footer-btn {
  border-top: 1px solid $border-color;
  padding: 30px 0 15px 0;
}

.form-footer-btn .btn {
  min-width: 107px;
}

.form-footer-btn .btn-primary {
  margin: 0 0 0 8px;
}

.nested-form-item-destroy-btn .btn {
  font-size: 16px;
  padding: 0px 15px;
}

.nested-form-item-destroy-btn .btn.disabled {
  border-color: $danger;
  opacity: 0.5;
  color: $danger;
}

.order-items.nested-form-item-destroy-btn .btn,
.credit-notes.nested-form-item-destroy-btn .btn,
.debit-notes.nested-form-item-destroy-btn .btn {
  padding: 0px;
}


.invoice-items.nested-form-item-destroy-btn .btn {
  padding: 0px;
}

.item-custom-discount-field {
  .discount-value {
    width: 55%;
    background-color: transparent !important;
  }

  .discount-value:disabled {
    background-color: lighten($gray_lighter, 10%) !important;
  }

  .discount-type {
    width: 45%;
  }
}

.item-number {
  padding: 5px 0px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  line-height: 16px;
  margin: 8px 0 0 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 600;
}

div#product-variants-and-images-form-tabs .nav-tabs {
  margin-right: 15px;
  margin-left: 15px;
}

.permissions strong {
  font-size: 12px;
}

.permissions span {
  padding-top: 2px;
}

.home-card,
.home-graph-card {
  border-radius: 0px;
  -webkit-box-shadow: 0px 5px 20px 0px lighten($gray_lighter, 10%);
  box-shadow: 0px 5px 20px 0px lighten($gray_lighter, 10%);
  // box-shadow: none;
  background-color: $white;
  margin-bottom: 0;

  .content {
    padding: 10px;
  }

  /* Homepage New */
  .form-control.input-sm {
    font-size: 14px;
    font-weight: 400;
    padding: 0px 5px;
    border-radius: 2px;
  }

  .chart-header-date-picker-input {
    font-size: 14px;
    border: 1px solid $border_color;
    border-radius: 2px;
    padding: 4px 5px;
  }

  /* End Homepage New */
}

/* Homepage New */
.common-filters-section .form-control.input-sm {
  font-size: 14px;
  font-weight: 400;
  padding: 0px 5px;
  border-radius: 2px;
}

.common-filters-section .chart-header-date-picker-input {
  font-size: 14px;
  border: 1px solid $border_color;
  border-radius: 6px;
  padding: 4px 5px;
}

/* End Homepage New */

.day-ranges .panel.panel-default {
  border: none;
  margin-top: 15px;
}

.day-ranges .panel.panel-default .panel-body {
  overflow: hidden;
  padding: 0px;
  width: 100%;
}

.sortable {
  position: relative;
  cursor: pointer;
}

.customer-contact-box {
  padding: 15px;
  overflow: auto;
  border-radius: 3px;
  background: hsla(165, 17%, 86%, 0.14);
  font-size: 13px;
  line-height: 22px;
  position: relative;
  color: $secondary_darker;
  border: 1px solid $gray_lighter;
  min-height: 110px;

  .keys {
    font-weight: 500;
    padding-right: 0px;
  }
}

.card .section .section-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
}

.sortable.sortable-asc:hover .table-head:after {
  content: "\2191";
  font-family: fontawesome;
  font-size: 14px;
  margin: 0px 0px 0px 4px;
  position: absolute;
  -webkit-text-stroke: 0.5px;
}

.sortable.sortable-active.sortable-asc .table-head:after {
  content: "\2191";
  font-family: fontawesome;
  font-size: 14px;
  margin: 0px 0px 0px 4px;
  position: absolute;
  -webkit-text-stroke: 0.5px;
}

.sortable.sortable-active.sortable-desc .table-head:after {
  content: "\2193";
  font-family: fontawesome;
  font-size: 14px;
  margin: 0px 0px 0px 4px;
  position: absolute;
  -webkit-text-stroke: 0.5px;
}

#users_group_form {
  .users_group_role_group {
    padding: 0 15;
  }

  .users_group_role {
    padding: 0 15;
    height: 40px;

    .form-group {
      margin-bottom: 0;

      .form-check {
        display: flex;

        .form-check-label {
          font-size: 14px !important;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.login-main .row.no-gutter {
  padding: 0;
  margin: 0px -15px;
}

.login {
  background-image: url('../img/login_page_left_side_bg_image.svg');
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

.login .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pad-0 {
  padding: 0px;
}

.login-left,
.landing-left {
  max-width: 316px;
  margin: auto;
}

.login-left h6,
.landing-left h6 {
  color: $white;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 5px 0 5px 0;
}

.login-left p,
.landing-left p {
  color: $white;
  font-size: 12px;
  font-weight: 400;
}

.login-left .text-center,
.landing-left .text-center {
  margin-bottom: 20px;
}

.login-left label.control-label,
.landing-left label.control-label {
  color: $white !important;
  text-transform: uppercase;
  letter-spacing: 0.5;
}

.login-left .form-control,
.landing-left .form-control {
  border: none;
}

.login-left .errors-message,
.landing-left .errors-message {
  color: $login_field_error_message;
}

.btn-login,
.btn-login:hover,
.btn-login.disabled,
.btn-signup,
.btn-signup:hover,
.btn-signup.disabled {
  background: $login_page_button_bg_color !important;
  height: 42px;
  border: 1px solid $login_page_button_bg_color !important;
  border-radius: 2px;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: unset;
  // text-shadow: 0px 0px 3px #00b6a9;
}

.login-right {
  padding-top: 80px;
  padding-left: 80px;

  .login-page-request-demo-label {
    margin-bottom: 15px;
  }

  .login-page-request-demo-btn {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    color: white;
    background-color: #162458;
    border: none;
  }
}

.login-right h2 {
  font-size: 30px;
  color: $login_page_right_section_header_text_color;
  font-weight: 500;
  margin: 0 0 24px 0;
  line-height: 39px;
}

.login-right p {
  color: $login_page_right_section_paragraph_text_color;
  font-size: 16px;
}

.bg-image {
  min-height: 100vh;
  background: url("../../assets/img/distributo_webapp_signin_image_right_section_2.png");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.notification-message div {
  font-size: 14px;
}

.inner-space-right fieldset {
  margin: 0 -15px 0 0px;
}

.popover ul {
  margin: 0;
  padding: 0 0 0 14px;
}

.setting-page-tab {
  .form-footer-btn {
    padding: 15px 0;
  }

  .nav-tabs {
    a {
      padding-top: 0px;
    }
  }

  .setting-tab-item {
    font-weight: 500;
    margin-bottom: 2px;
    color: $black;
  }

  .setting-tab-item-check {
    position: relative;
    padding-left: 40px;

    .check-hover {
      position: absolute;
      left: 0;
      top: -9px;

      input {
        margin: 0px;
      }
    }
  }

  .setting-tab-item-sub {
    margin-bottom: 6px;
    color: $black;
  }
}

// @media (max-width: 1300px) {
//   .setting-page-tab .nav-tabs a {
//     padding-top: 18px !important;
//   }
//   #settings-tabs {
//     padding-top: 100px !important;
//   }
//   .setting-page-tab ul.nav.nav-tabs {
//     top: 44px !important;
//   }
// }
// @media (max-width: 800px) {
//   .setting-page-tab .nav-tabs a {
//     padding-top: 18px !important;
//   }
//   #settings-tabs {
//     padding-top: 160px !important;
//   }
// }

#settings-tabs {
  position: relative;
  padding-top: 50px;
  padding-bottom: 80px;
}

.setting-page-tab label {
  color: $black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.setting-page-tab ul.nav.nav-tabs {
  position: fixed;
  left: 260px;
  width: calc(100% - 290px);
  z-index: 8;
  top: 66px;
  background: $white;
  padding-top: 30px;
}

body.off-canvas-sidebar.sidebar-mini .setting-page-tab ul.nav.nav-tabs {
  left: 113px;
  width: 89.5%;
}

.users_group_panel_group {
  padding: 0px 15px;
}

.users_group_panel_heading h5 {
  margin: 0px;
  border-bottom: 1px solid $secondary_lighter;
  padding: 20px 0;
}

.users_group_panel_heading h5 a {
  color: $secondary;
}

.users_group_panel_heading h5 a.collapsed:before {
  content: "\f105";
  font-family: fontawesome;
  width: 22px;
  display: inline-block;
  margin: 0px 0 0 0;
}

.users_group_panel_heading h5 a:before {
  content: "\f107";
  font-family: fontawesome;
  width: 22px;
  display: inline-block;
  margin: 0px 0 0 0;
}

.users_group_sub_panel_heading h5 {
  margin: 0px;
  border-bottom: 1px solid $secondary_lighter;
  padding: 5px 0px 15px 0;
}

.users_group_sub_panel_heading h5 {
  color: $secondary;
}

.users_group_panel_body {
  border-bottom: 1px solid $secondary_lighter;
  padding-bottom: 15px;
}

.users_group_item_label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $gray;
}

.users_group_item small {
  color: $gray;
}

.users_group_sub_panel_group {
  padding: 0 0 0 20px;
}

.users_group_sub_panel_group .users_group_panel_body {
  padding: 10px 0 0 23px;
  border: none;
}

.users_group_sub_panel_group .users_group_panel_heading h5 {
  border: none;
  padding: 11px 0;
}

.users_group_sub_panel_group .users_group_panel_heading h5 a {
  color: $secondary;
  font-size: 14px;
}

.form-group-output-main {
  padding: 10px 25px;
}

.form-group-output-main [class*="col-"] {
  padding: 0 5px;
}

.form-group-output-item {
  color: $black;
  padding: 0 0 10px 0;
}

.form-group-output-border {
  position: relative;
}

.form-group-output-main .row {
  display: flex;
}

.form-group-output {
  margin-bottom: 20px;
}

.btn-brand-blue,
.btn-brand-blue:hover {
  background: $brand-blue;
  border-color: $brand-blue;
  color: $white;
}

.btn-pill {
  border-radius: 50px;
}

.btn-sm-pill {
  padding: 2px 14px;
  min-height: 32px;
}

.mt-0 {
  margin-top: 0px;
}

.title-with-btn .btn {
  margin: -8px 0 0 0;
}

.title-with-btn .btn-group {
  float: right;
}

.pt-0 {
  padding-top: 0px;
}

hr {
  border-top: 1px solid $hr_border_color;
}

.detail-table .table tbody td:first-child,
.detail-table .table thead th:first-child {
  padding-left: 0px;
}

.detail-table .table tbody td:last-child,
.detail-table .table thead th:last-child {
  padding-right: 0px;
  text-align: right;
}

#change_password .text-black i.pe-7s-key {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.change-password .toggle-password,
.e-invoice-credential .toggle-password,
.e-way-bill-credential .toggle-password,
.sign-up-account-wrapper .toggle-password {
  float: right;
  margin-right: 6px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  font-size: 20px;
  cursor: pointer;
}

#show-page-header-action-dropdown {
  height: 33px;
}

.show-page-header-action-nav {

  li.listing-action-menu-item-header a,
  li.listing-action-menu-item-header:hover a {
    padding: 5px !important;
    color: $gray;
  }

  ul.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }

  ul.dropdown-menu li:last-child {
    margin-top: 0px;
    border-top: none;
  }
}

.rc-time-picker-input {
  border: none;
  font-size: inherit;
  color: inherit;
  padding: 0px;
}

.user-menu .user-menu-item-settings i {
  color: $black;
}

.identifier ul {
  margin: 0px;
  padding: 0px;
}

.identifier {
  margin-top: 15px;
}

.identifier-head {
  color: $black;
  text-transform: uppercase;
  padding: 8px 15px;
}

.identifier-list {
  padding: 6px 0;
  height: 39vh;
  // border: 1px solid $secondary_lighter;
  overflow: auto;
}

.identifier-list div:last-child {
  border: none;
}

.identifier-list div input {
  margin: 2px 9px 0px 0;
}

.identifier-list div label {
  margin: 0px !important;
  padding: 6px 15px;
  width: 100%;
  cursor: pointer;
  display: flex;
}

.identifier-list div.active {
  // background: $secondary_lighter;
}

.row-h {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row-h>[class*="col-"] {
  display: flex;
  flex-direction: column;
}

.identifier-left {
  position: relative;
}

.identifier-left:after {
  content: "\F105";
  position: absolute;
  right: -23px;
  top: 43%;
  bottom: 0;
  font-family: fontawesome;
  font-size: 35px;
}

.identifier-list-no {
  text-align: center;
  width: 100%;
  padding-top: 101px;
}

#product-variants-and-images-form-tabs .nav-tabs li a {
  outline: 0;
  outline-offset: 0;
}

.tracking-info {
  vertical-align: top !important;
}

.bank-account-header-section .bank-account-header {
  margin: 10px 0px;
}

.bank-account-header-section .form-group {
  margin-bottom: 0px;
}

.sub-account-header-section .sub-account-header {
  margin: 10px 0px;
}

.sub-account-header-section .form-group {
  margin-bottom: 0px;
}

.beat-route-assignment-header-section .beat-route-assignment-header {
  margin: 10px 0px;
}

.beat-route-assignment-header-section .form-group {
  margin-bottom: 0px;
}

.customer-address-header-section .customer-address-title {
  margin: 10px 0px;
}

.customer-address-header-section .form-group {
  margin-bottom: 0px;
}

.customer-contact-header-section .customer-contact-title,
.supplier-contact-header-section .supplier-contact-title {
  margin: 10px 0px;
}

.customer-contact-header-section .form-group,
.supplier-contact-header-section .form-group {
  margin-bottom: 0px;
}

.branch-address-nested-form #branch-address-line2 {
  height: 42px;
}

.content.chart-header .key {
  padding: 0px;
  font-size: 13;
}

.content.chart-header .value {
  padding: 0px;
  font-size: 13;
  word-break: break-all;
}

.line-chart-title-heading {
  margin: 0px 0px 5px 0px;
}

.line-chart-title-heading i {
  font-size: 16px;
  color: $primary_darker;
}

.line-chart-content .body {
  padding: 0px;
  text-align: center;
}

.dashboad-previous-next-btn i {
  font-size: 20px;
}

.submit-form-loader i {
  font-size: 15px;
}

.home-card-stats {
  .home-card-stats-header-title {
    margin: 0px 0px 5px 0px;

    i {
      font-size: 16px;
      color: $primary_darker;
    }
  }

  .period-till-date {
    .info {
      .key {
        padding: 0px;
        text-align: center;
        font-size: 13px;
      }

      .value {
        padding: 0px;
        font-size: 13px;
      }
    }

    .performance {
      padding: 0px;
      text-align: center;

      .fa {
        font-size: 18px;
        vertical-align: middle;
      }

      .fa-caret-up,
      .increment-percentage {
        color: $increment_percentage_color;
      }

      .fa-caret-down,
      .decrement-percentage {
        color: $decrement_percentage_color !important;
      }
    }
  }
}

.form-horizontal .normal-search-form {
  z-index: 0;
}

.credit-note-invoice-section {
  .title {
    margin: 10px 0px;
  }

  .form-group {
    margin-bottom: 0px;
  }
}

.credit-note-download-pdf-btn,
.invoice-re-calculate-btn,
.invoice-download-pdf-btn,
.order-download-pdf-btn {
  margin-right: 2px;
}

.customer-address-body,
.address-body {
  .excel-label {
    white-space: pre-wrap;

    .action-buttons {
      display: none;
      position: absolute;
      top: 5px;
      right: 15px;
    }
  }

  .excel-label:hover {
    .action-buttons {
      display: block;
    }
  }

  .customer-address-body-row,
  .address-body-row {
    padding: 0px 6px 15px 6px;

    .default-pill-button-wrapper {
      height: 25px;
    }

    span.actions {
      display: none;
    }
  }
}

.customer-address-body-row:hover,
.address-body-row:hover {
  span.actions {
    display: flex;
  }
}

.customer-contact-body .excel-label,
.supplier-contact-body .excel-label {
  .action-buttons {
    display: none;
    position: absolute;
    top: 5px;
    right: 15px;
  }

  padding-bottom: 10px;
}

.customer-contact-body .excel-label:hover,
.supplier-contact-body .excel-label:hover {
  .action-buttons {
    display: block;
  }
}

.customer-contact-body .customer-contact-body-row,
.supplier-contact-body .supplier-contact-body-row {
  padding: 0px 6px 15px 6px;
  // .excel-label .key {
  //   padding: 0px 6px;
  // }
}

.contact-excel-label {
  padding-left: 0px;

  .top-section {
    display: flex;

    .customer-default-address.label-default {
      background-color: $gray;
    }

    .default-pill-button-wrapper {
      height: 25px;
    }

    .actions {
      display: none;
      width: 100%;
      flex-direction: row-reverse;
    }
  }
}

.contact-excel-label:hover {
  .top-section {
    .actions {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
    }
  }
}

.customer-contact-info {
  padding-bottom: 10px;
  height: 70px;
  overflow: auto;
}

.customer-address-nested-form #customer-address-line2 {
  height: 42px;
}

.dashboard-home-page h4 {
  margin-top: 0px;
}

.invoice-item-inventory-tracking-identifier,
.inventory-tracking-identifier-label,
.order-item-purchase-order-item-label {
  cursor: pointer;
}

.show-page-inventory-tracking-info {
  cursor: pointer;
  margin-top: 5px;
}

.permitted-states-btn {
  text-transform: capitalize;
}

.form-group-output-border .billing-address-value,
.form-group-output-border .shipping-address-value,
.form-group-output-item.branch-address-value {
  white-space: pre-wrap;
  word-break: break-all;
}

.payment-form-realisation-date {
  padding-right: 15px;
}

.word-break {
  word-break: break-all;
}

#purchase-invoice-form {
  .discount-amount {
    margin-bottom: 5px;
  }

  .voucher-discount-amount {
    margin-bottom: 0px;
  }

  .net-amount {
    padding-right: 6px;
  }

  .total-section-col-label {
    text-align: right;
    margin-bottom: 0px;
    padding-top: 16px;
  }
}

.purchase-invoice-inventory-tracking-modal-body {
  .inventory-tracking-identifier {
    margin-top: 6px;

    .inventory-tracking-identifier-value {
      max-height: 100px;
    }
  }
}

.purchase-return-inventory-tracking-modal-body {
  .inventory-tracking-identifier {
    margin-top: 6px;

    .inventory-tracking-identifier-value {
      max-height: 100px;
    }
  }
}

.day-ranges .title {
  cursor: pointer;
  font-weight: 400;
}

.stock-entry-listing-header-section .form-group {
  margin-bottom: 0px;
}

.supplier-address-nested-form #supplier-address-line2 {
  height: 42px;
}

.supplier-address-nested-form-root {
  margin-bottom: 5%;
}

.user-role-form-modal-body,
.customer-advance-search-modal-body-style,
.bank-account-form-modal,
.credit-note-advance-search-modal-body-style,
.purchase-invoice-advance-search-modal-body-style,
.update-payment-modal-body,
.stock-entry-form-modal-body,
.credit-note-invoice-modal-body,
.beat-route-assignment-form-modal-body,
.product-advance-search-modal-body-style,
.stock-advance-search-modal-body-style,
.merge-duplicate-customer-modal-body,
.invoice-payment-form-modal-body,
.sub-account-form-modal-body,
.e-way-bill-modal,
.supplier-contact-form-modal-body,
.historical-pricing-listing-modal-body {
  height: 71vh;
}

.e-way-bill .check-hover {
  height: 28px;
}

.payment-advance-search-modal-body-style,
.return-advance-search-modal-body-style {
  height: 75vh;
}

.user-roles-section .title {
  margin: 10px 0px;
}

.user-roles-section .form-group {
  margin-bottom: 0px;
}

.warehouse-address-nested-form #warehouse-address-line2 {
  height: 42px;
}

.chart-filter-section {
  .chart-filter-first-section {
    padding-right: 0px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;

    .brand-filter {
      padding-right: 0px;
      padding-left: 5px;

      .brand-filter-form-group {
        margin-bottom: 0px;
      }
    }

    .prev-or-next-btn-section {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .start-date {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-dash {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .end-date-label {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .chart-filter-second-section {
    padding-left: 0px;
    padding-right: 5px;

    .period-filter-form-group {
      margin-bottom: 0px;
    }
  }
}

.common-filters-section {
  .common-filter-first-section {
    padding: 0px;

    .brand-filter {
      padding-right: 0px;

      .brand-filter-form-group {
        margin-bottom: 0px;
      }
    }

    .prev-or-next-btn-section {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .start-date {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-dash {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .end-date {
      padding: 0px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .common-filter-second-section {
    padding: 0px;

    .period-filter-form-group {
      margin-bottom: 0px;
    }
  }
}

.customer-image-upload {
  margin-bottom: 30px;
}

.accounting-reports-table.table tr td {
  padding: 10px 5px;
}

#popover-filter-form .arrow {
  display: none;
}

.off-canvas-sidebar #popover-filter-form {
  left: calc((100% - (100% - #{$sidebar_width})) + 30px) !important;
}

.off-canvas-sidebar.sidebar-mini #popover-filter-form {
  left: calc((100% - (100% - #{$mini_sidebar_width})) + 30px) !important;
}

.filter-icon {
  color: $primary !important;
  font-size: 16px;
}

.sub-account-header-section .filter-icon .pe-7s-filter {
  font-size: 16px;
  padding-top: 18px;
}

.accounting-reports-table tr.account-net-income .key,
.accounting-reports-table tr.account-type .key {
  font-size: 16px;
}

.accounting-reports-table tr.account-total .key {
  padding-left: 25px;
}

.accounting-reports-table tr.account .fa-angle-down,
.accounting-reports-table tr.account .fa-angle-right {
  font-size: 18px;
  cursor: pointer;
}

.accounting-reports-table tr.account-sub-accounts .row .key {
  padding-left: 25px;
}

.accounting-reports-table tr.account-sub-accounts .row .value {
  padding: 0px;
}

.accounting-reports-table .total-liabilities-and-equities-key {
  font-size: 16px;
}

.pe-7s-filter {
  cursor: pointer;
}

.all-reports-listing-page ul {
  padding-left: 10px;
}

.all-reports-listing-page h5 {
  color: $gray;
}

.all-reports-listing-page ul li {
  padding: 8px 0px;
  list-style-type: none;
  border-bottom: 1px dashed lighten($gray, 45%);
}

.all-reports-listing-page ul li:before {
  font-family: "FontAwesome";
  content: "\f006";
  margin: 0 5px 0 0px;
  color: $gray_light;
}

.adBanner {
  background-color: transparent;
  height: 1px;
  width: 1px;
}

.feed-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .feed-section-title {
    font-size: 14;
    font-weight: bold;
    margin-bottom: 10;
    text-align: center;
  }
}

.feed-section-divider {
  text-align: center;

  div {
    position: relative;
    background-color: $white;
    padding: 0;
    top: 42%;
  }
}

.feed-section-divider:before {
  content: "";
  position: absolute;
  border-right: 1px solid $gray_lighter;
  height: 100%;
}

.price-list-feed {
  padding-bottom: 30px;
  display: flex;
  padding-left: 0px;

  .feed-section {
    padding-left: 0px;

    .feed-section-title {
      text-align: left;
    }

    .feed-section-body {
      padding-left: 15px;
    }

    .feed-section-submit-btn {
      padding-left: 15px;
    }
  }
}

.accounting-children td {
  padding: 5 !important;
}

.invoice-csv-export {
  text-align: right;
}

.dashboard-container-fluid {
  background-color: $dashboard_background_color;
  padding-top: 82px;

  .main-content,
  .form-content {
    background-color: $dashboard_background_color;
  }

  .till-date-title {
    color: #51525A;
    font-weight: 400;
  }

  .till-date-amount {
    margin: 0px 0px 5px 0px;
    font-weight: 400;
    color: $dashboard_header_text_color;
  }
}

.home-card.home-card-stats {
  padding-bottom: 15px;
  box-shadow: 0px 5px 20px 0px lighten($gray_lighter, 10%);

  // box-shadow: none;
  .header {
    margin-top: 15px;
  }

  .fa-caret-up,
  .increment-percentage {
    color: $increment_percentage_color;
  }

  .fa-caret-down,
  .decrement-percentage {
    color: $decrement_percentage_color !important;
  }
}

.listing-page-info #header-listing-setting,
.listing-page-info #header-listing-custom-action,
.invoice-show-page-invoice-items #header-listing-setting {
  padding: 0;
  font-size: 18;
  border: none;
}

.listing-page-info .actions {
  display: flex;
  justify-content: flex-end;
  padding: 0;

  span {
    margin-left: 5px;
  }

  .dropdown-menu li:last-child {
    margin: 0;
    border-top: none;
  }
}

.setting-menu-items {
  padding: 6px 15px;
}

.setting-menu-items input {
  margin: 0px 10px 0 0px;
}

.setting-menu-items label {
  width: 100%;
  justify-content: left;
  align-items: center;
  display: flex;
  margin: 0px !important;
}

.home-card.home-card-stats fieldset {
  label h6 {
    margin-bottom: 0px;
  }

  &:last-child {
    height: 85px;
  }
}

label h6.till-date-title {
  margin-bottom: 0px;
}

.hp tr {
  // table-layout: fixed !important;
  // width: 100% !important;
  // display: table;
}

.hp tbody.table-header-group {
  overflow-y: scroll;
  height: 51vh;
  display: block;
  // table-layout: fixed;
}

// .hp-f {
// background: #f9f9fc;
// border: 1px solid lighten($gray, 42%);
// }

.hp-f-header {
  padding: 14px 13px 13px 13px;
  border-bottom: 1px solid lighten($gray, 42%);
}

.hp-f-header h5 {
  margin: 0px;
  font-weight: bold;
}

.hp-f-footer {
  padding: 5px 15px;
  border-top: 1px solid lighten($gray, 42%);
}

.hp-f-body {
  padding: 15px;
  height: 44vh;
  overflow-y: scroll;
}

// Beat route form css
.fa.fa-filter.fa-filter-o {
  text-shadow: 0px 0px 1px $primary, 0px 0px 1px $primary !important;
  color: $white !important;
}

// Beat route customer selection section
.scrollable-list-header .left-section {
  padding: 0 !important;

  .header-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    text-transform: uppercase;
  }
}

.scrollable-list-header .preview-btn {
  padding: 1px 10px;
}

.scrollable-list-header .fa-filter {
  height: inherit;
  vertical-align: middle;
  font-size: 18;
  color: $primary;
  cursor: pointer;
}

.scrollable-list-header .right-section {
  text-align: right;

  .selected-item-detail {
    line-height: 36px;
    vertical-align: middle;
    height: inherit;
  }
}

.scrollable-list-hr {
  padding-left: 15 !important;
  padding-right: 15 !important;

  hr {
    margin-bottom: 0 !important;
  }
}

.scrollable-list-body {
  .filter-section {
    padding-right: 6 !important;
  }

  .border-right {
    border-right: 1px solid lighten($gray, 42%);
  }
}

.sidebar .sidebar-wrapper li>ul.nav li.divider {
  height: 1px;
  margin: 4px 0 4px 69px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.sidebar .nav p {
  width: 100%;
}

.sidebar .nav li .nav-main-link {
  align-items: center;
  display: flex;
}

.report-row-parent-expandable.collapsed:before {
  content: "\f105";
  font-family: fontawesome;
  width: 22px;
  display: inline-block;
  margin: 0px 0 0 0;
}

.report-row-parent-expandable:before {
  content: "\f107";
  font-family: fontawesome;
  width: 22px;
  display: inline-block;
  margin: 0px 0 0 0;
}

.report-row-subtotal-expandable:before {
  content: "";
  font-family: fontawesome;
  width: 22px;
  display: inline-block;
  margin: 0px 0 0 0;
}

.no-record-found {
  text-align: center;
  padding: 15px !important;
}

.col-md-half input[type="checkbox"] {
  margin: 13px 0 0 0;
}

.col-md-half .control-label input[type="checkbox"] {
  margin: 2px 0 0 0;
}

// Placement of menu items/options on top of select tag.
.select-up .Select-menu-outer {
  position: absolute !important;
  top: auto !important;
  border: 1px solid $primary_input_field_border_color;
  border-bottom: none;
  bottom: calc(100% - 1px) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.select-up .is-open .Select-control {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.error .select-up .Select-menu-outer {
  border-top: 1px solid $danger_input_field_border_color;
}

.form-control.z-index-zero {
  z-index: 0 !important;
}

.invoice-payment-header,
.invoice-credit-note-header {
  .title {
    padding-right: 6px;
    height: 36px;
    display: flex;
    align-items: center;

    .text-muted {
      margin-bottom: 0px !important;
    }
  }

  .remaining-amount,
  .remaining-discount {
    padding-left: 6px;
    padding-right: 6px;
    height: 36px;
    display: flex;
    align-items: center;

    .key {
      color: $secondary;
      font-weight: 500;
    }

    .value {
      color: $secondary;
    }

    .negative-value {
      color: $danger;
    }
  }
}

.border-right {
  border-right: 1px solid lighten($gray, 42%);
}

.border-left {
  border-left: 1px solid lighten($gray, 42%);
}

.customer-account-entry-hr hr {
  margin-bottom: 5px !important;
}

.inventory-tracking-selected-identifier,
.order-item-purchase-order-items-list {
  max-height: 200px;

  .Select {
    border: 1px solid $border_color;
    overflow-y: scroll;
    border-radius: 2px;
    max-height: 100px;
  }

  .Select-control {
    border: none;
  }
}

.order-item-purchase-order-items-list {
  .link {
    cursor: pointer;
  }

  .fa.fa-trash {
    color: $danger_lighter;
  }
}

.new-advance-search-modal-body.modal-body {
  height: 71vh;
}

.Select.is-focused.is-open {
  z-index: 1;
}

#div-hover:hover {
  cursor: pointer;
  background-color: lighten($gray_lighter, 8%);
}

.nested-form-on-show-page [class*="col-"],
.add-inventory-tracking-entries [class*="col-"] {
  padding: 0 6px;
}

.nested-form-on-show-page [class*="col-"]:first-child,
.add-inventory-tracking-entries [class*="col-"]:first-child {
  padding-left: 15px;
}

.nested-form-on-show-page [class*="col-"]:last-child,
.add-inventory-tracking-entries [class*="col-"]:last-child {
  padding-right: 15px;
}

.add-inventory-tracking-entries.p-aite .header {
  color: $secondary_darker;
  font-weight: 500;
}

.p-iti {
  padding: 0px;
  height: calc(100% - 300px);
}

.p-iti td {
  padding: 6px 15px !important;
}

.p-aite {
  margin-top: 15px;
}

.p-iti td.col-md-2 {
  padding-left: 13px !important;
  padding-right: 9px !important;
}

.p-iti table.table {
  margin: 0;
}

.itsi-output td {
  padding: 5px 8px !important;
  font-size: 13px !important;
}

.itsi-output {
  max-height: 123px;
  overflow: overlay;
  border: 1px solid lighten($gray, 45%);
}

.itsi-output .table {
  margin: 0px;
}

.inventory-tracking-identifier .no-items-found {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px
}

.table.batch-tracking-inventory-tracking-info-modal tr td {
  padding: 7px 5px;
  border-top: none;
  border-bottom: 1px solid lighten($gray, 45%);
}

.table.batch-tracking-inventory-tracking-info-modal tr:first-child td {
  border-top: none;
}

.table.batch-tracking-inventory-tracking-info-modal tr:last-child td {
  border-bottom: none;
}

.inventory-tracking-modal-body .inventory-tracking-identifier .table tr td.quantity {
  text-align: right;
  // padding-right: 0px !important;
}

.tasks-fieldset input[type="number"] {
  text-align: right;
}

.tax-rates-tasks-fieldset input[type="number"],
.tax-category-tax-rates-tasks-fieldset input[type="number"],
#accounting-entries-amount {
  text-align: left !important;
}

// Listing page bulk action css
#header-bulk-actions-dropdown-button {
  padding: 0;
  font-size: 18;
  border: none;
}

.bulk-action-menu-item {
  padding: 7px 10px;
}

.bulk-action-menu-item:hover {
  background-color: $gray_lightest;
}

.bulk-action-menu-item {
  position: relative;
  cursor: pointer;
}

.bulk-action-menu-item.active .fa {
  position: absolute;
  font-size: 14px !important;
  margin: 0px;
  left: 8px;
}

.bulk-action-menu-item.active {
  padding-left: 33px;
}

.reload-listing {
  font-size: medium;
  color: $gray_light;
  cursor: pointer;
}

.reload-listing:hover {
  background-color: transparent;
  color: $secondary;
}

i.fa.fa-refresh.reload-listing:hover,
i.fa.fa-refresh.reload-listing:focus {
  background-color: transparent;
  color: $secondary;
  outline: none;
}

#header-bulk-actions-dropdown-button:hover {
  border-bottom: 1px solid $secondary_lighter;
}

.quick-add-module {
  .modal-dialog {
    width: 83vw;
    min-width: 876px;

    .new-advance-search-modal-body>div {
      height: 100%;
      overflow-y: auto;
    }
  }

  .modal-content {
    position: relative;
  }

  .modal-header {
    text-align: left;
  }

  .modal-body {
    position: unset;
    padding: 15px 0;

    #product-variant-tab-panels {
      position: unset;
    }

    #product-variants-and-images-form-tabs-pane-1 .form-footer-btn {
      padding-right: 25px;
    }

    #product-variants-and-images-form-tabs-pane-2 .form-footer-btn {
      padding-right: 40px;
    }
  }

  .navbar-fixed-top {
    display: none;
  }

  .form-container-fluid {
    padding-top: 0;
    // padding-left: 5px;
    // padding-right: 5px;

    &>.row>.col-md-12 {
      position: unset;
    }
  }

  .fixed-action-footer {
    position: absolute;
    bottom: 0;
    left: 0 !important;
    width: 100% !important;
    z-index: 8;
  }
}

// custom loader styles
.lds-roller.custom {
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller-custom-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.login-custom-loading {
  background: $login_page_left_bg_color;

  .lds-roller>div::after {
    background: $white;
  }
}

$login-logo-dimension: 100px;

.login-client-logo,
.landing-client-logo {
  line-height: $login-logo-dimension;
  height: $login-logo-dimension;
  width: $login-logo-dimension;
  border-radius: 4px;
  font-size: 27px !important;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  background: $white;
  color: $primary !important;

  &.with-img {
    height: auto;
    width: auto;
    background: transparent;

    img {
      width: 120px;
      max-height: 120px;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.product-details {
  position: absolute;
  left: 40px;
  top: 40px;

  // .product-logo {
  //   height: auto;
  //   width: auto;
  // }

  .product-title {
    color: $white;
    font-weight: 600;
    display: inline-block;
    margin: auto 8px;
    font-size: 14px;
  }
}

#show-old-design-product-logo,
#product-logo {
  width: 116px;
}

.table.offers tbody>tr>td {
  border: none;
  padding-top: 10;
  padding-bottom: 10;
}

.form-container-fluid.full-height {
  min-height: 100%;
}

.scrollable-list-header {

  .action-btn-round:hover,
  .action-btn-round.active {
    .fa.fa-filter {
      color: $white;
    }
  }
}

.text-weight-500 {
  font-weight: 500 !important;
}

td.orders-status-col,
.form-group-output-item-status {
  .orders-status {
    display: inline-block;

    span {
      // color: $gray_darker;
      font-weight: 500;
    }
  }

  .issued {
    color: darken($gray_light, 5%);
  }

  .cancelled {
    color: darken($gray_light, 5%);
  }
}

td.credit-notes-status-col,
.form-group-output-item-status {
  .credit-notes-status {
    display: inline-block;

    span {
      // color: $gray_darker;
      font-weight: 500;
    }
  }

  .issued {
    color: darken($success_light, 5%);
  }

  .cancelled {
    color: darken($gray_light, 5%);
  }
}

td.debit-notes-status-col,
.form-group-output-item-status {
  .credit-notes-status {
    display: inline-block;

    span {
      // color: $gray_darker;
      font-weight: 500;
    }
  }

  .issued {
    color: darken($success_light, 5%);
  }

  .cancelled {
    color: darken($gray_light, 5%);
  }
}

.status-progress-bar {
  height: 7px;
  width: 72px;
  margin-bottom: 0px;

  .progress-bar-default {
    background: none repeat scroll 0 0 darken($gray_light, 5%);
  }

  .progress-bar-success {
    background: none repeat scroll 0 0 darken($success_light, 5%);
  }

  .progress-bar-info {
    background: none repeat scroll 0 0 darken($info_light, 5%);
  }

  .progress-bar-danger {
    background: none repeat scroll 0 0 darken($danger_light, 5%);
  }

  .progress-bar-warning {
    background: none repeat scroll 0 0 darken($warning_light, 5%);
  }
}

.quick-listing-modal-body .lds-roller {
  padding: 0;
}

#tally-integration-tabs {
  .nav-tabs {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tab-content {
    height: 100%;
  }
}

.nested-dropdown .Select-menu-outer {
  .Select-option {
    padding-left: 2em;
  }

  .Select-option.is-disabled {
    cursor: default;
    padding-left: 1em;
    color: $secondary_darkest;
  }
}

.form-group-output-item .label-default {
  background: none repeat scroll 0 0 $secondary_darkest;
  color: $white;
  border-radius: 50px;
  font-size: 12px;
  padding: 4px 12px;
}

.make-cross-icon-thicker {
  -webkit-text-stroke: $white !important;
  color: $primary !important;
}

.text-content-so-po-show-modal {
  font-size: 13.8px;
  font-weight: 400;
}

.identifier-list .tracking-identifier:hover,
.identifier-list .tracking-identifier:active,
.identifier-list .tracking-identifier:focus {
  background-color: $secondary_lightest;
  outline: none;
}

th.action-co {
  width: 0px;
}

th.action-co .table-head {
  font-size: 0px;
}

td.action-co {
  width: auto;
  position: relative;
  right: 0;
  opacity: 0;

  .listing-item-actions {
    position: absolute;
    display: flex;
    right: 0;
    top: 0px;
    bottom: 0px;
    margin: auto;
    align-items: center;

    .action-btn {
      opacity: 0.8 !important;
    }
  }
}

tr:hover td.action-co {
  opacity: 1 !important;
  z-index: 2 !important;

  .listing-item-actions {
    backdrop-filter: blur(100px);
  }
}

tr.row-danger:hover td.action-co .listing-item-actions {
  background: $danger_row_bg_color;
}

tr.row-success:hover td.action-co .listing-item-actions {
  background: $success_row_bg_color;
}

tr.row-cancelled:hover td.action-co .listing-item-actions {
  background: $cancelled_row_bg_color;
}

tr.row-warning:hover td.action-co .listing-item-actions {
  background: $warning_row_bg_color;
}

tr.row-info:hover td.action-co .listing-item-actions {
  background: $info_row_bg_color;
}

tr.row-white:hover td.action-co .listing-item-actions {
  background: $white_row_bg_color;
}

tr:hover td.action-co .listing-item-actions {
  background: $white;
}

#listing-item-action-dropdown.action-btn {
  padding-top: 1px;
}

.listing-item-actions .btn-toolbar .dropdown .dropdown-menu {
  .listing-item {
    padding: 5px 7px;
  }

  .listing-item:hover {
    cursor: pointer;
    background: $gray_lightest;
  }

  .listing-item:active,
  .listing-item:focus {
    outline: none;
  }

  .listing-item.delete-item {
    color: $delete_button_color;
  }

  .listing-item.delete-item.disabled,
  .listing-item.delete-item.disabled:hover {
    color: $disabled_delete_button_color;
    cursor: not-allowed;
  }
}

.contact-info-details #tooltip {
  z-index: 1050;
}

// SECTION   these classes are for showing fixed height tables
.fixed-height-table tr {
  table-layout: fixed !important;
  width: 100% !important;
  display: table;
}

.fixed-height-table tbody.table-header-group {
  overflow-y: scroll;
  height: 51vh;
  display: block;
  table-layout: fixed;
}

// !SECTION   these classes are for showing fixed height tables

.input-group .form-control {
  z-index: 1;
}

.status-role-col .td-star .btn-toolbar .btn-group .dropdown-menu,
.basic-role .btn-toolbar .btn-group .dropdown-menu {
  border: 1px solid $border-color;
}

.listing-table-card {
  .fix-table .table-fix-body {
    height: calc(100vh - 112px) !important;
  }

  // .table-with-fix-cols {
  //   width: 100%;
  // }
  #generic-table {
    border-collapse: initial;
    // table-layout: auto;
    margin-bottom: 40px;

    thead tr th {
      z-index: 3;
    }
  }
}

.listing-table-card.table-card {
  padding-left: 15px;
  padding-right: 15px;
}

.fix-table-header {
  h5 {
    position: sticky !important;
    background: $white;
    top: -15px;
    padding: 10px 0;
    border-bottom: 1px solid $gray_lighter;
  }

  #generic-table {
    margin-bottom: 0px;

    th {
      position: sticky !important;
      background: $white;
      top: 23px;
      box-shadow: 0 1px 0px 0px $box-shadow !important;
    }

    .table-header-group tr td {
      vertical-align: top;
    }
  }
}

.stock-entry-listing-header-section {
  #generic-table {
    .table-header-group tr td {
      vertical-align: top;
    }
  }
}

.credit-note-show-page-credit-note-items,
.debit-note-show-page-debit-note-items {
  #generic-table {
    .table-header-group tr td {
      vertical-align: top !important;
    }
  }
}

.table-fix-body {
  #generic-table {
    tbody tr:nth-child(1) td {
      border-top: none !important;
    }
  }
}

.fix-table-header-sibling-hr {
  margin-top: 0px !important;
}

.input-group-addon.flex-auto.primary-unit {
  text-transform: capitalize;
  line-height: 28px;
  padding-left: 0px;
  padding-right: 0px;
  width: 40%;
}

.input-group-addon.flex-auto.primary-unit.error {
  border-color: $danger_input_field_border_color !important;
}

.debit-note-items.input-group-addon.flex-auto.primary-unit small {
  color: $gray_light;
}

.select-tag-with-small-field {
  .Select-placeholder {
    line-height: 28px;
  }

  .Select-input {
    height: 28px;
  }

  .Select--single>.Select-control .Select-value {
    line-height: 28px !important;
  }
}

.additional-taxes-total {
  line-height: 37px;
  text-align: right;
  padding-right: 16px !important;
}

.capitalize {
  text-transform: capitalize;
}

/*
smaller - 12px
small   - 14px
medium  - 16px
large   - 18px
larger  - 20px
*/

$small-font-size: 14px;

.Select-menu-outer {
  font-size: $small-font-size !important;
}

.purchase-order-item-unit,
.purchase-order-item-input-price-per-unit,
.purchase-return-item-input-price-per-unit,
.purchase-order-item-price-package-unit,
.order-item-input-price-per-unit {
  width: 40%;
}

.invoice-item-input-price-per-unit,
.return-item-input-price-per-unit {
  width: 50%;
}

.order-item-input-quantity-unit,
.purchase-order-item-quantity-package-unit {
  width: 60%;
}

.return-item-input-quantity-unit,
.purchase-order-item-input-quantity-unit,
.purchase-return-item-input-quantity-unit {
  width: 70%;
}

.invoice-item-input-quantity-unit {
  width: 100%;
}

.purchase-order-supplier {
  z-index: 2;
}

.purchase-order-item-quantity:active,
.purchase-order-item-quantity:focus,
.input-field-right-border:focus {
  border-right: 1px solid $primary_input_field_border_color !important;
}

.form-control.purchase-order-item-quantity {
  z-index: 0 !important;
}

tr.product-purchase-prices {
  td {
    border-bottom: none !important;
  }
}

.product-current-prices-index-errors {
  background-color: $white !important;

  td {
    border-bottom: none !important;
  }
}

.card-header-title .information-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.purchase-orders-listing-content {
  height: 100%;
}

.listing-card-2-container .index-listing-container-fluid .card .table-full-width {
  height: calc(100vh - 139px) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.display-inline-block {
  display: inline-block;
}

.helper-text-info-icon {
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
}

.invoice-payments.nested-form-on-show-page {
  h4.text-muted.mx-6px {
    margin: 0px !important;
  }

  .invoice-payment-header {
    padding-left: 6px;
    padding-right: 6px;

    .title {
      padding-left: 6px;
      padding-right: 6px;
    }

    .actions {
      padding-right: 0px;
    }
  }

  .invoice-payment-header-tr {
    padding-left: 6px;
  }
}

.card-header-hr {
  padding: 0px !important;
}

.sequence-field-checkbox {
  margin: 2px 0 0 !important;
}

.sequence-field-info {
  color: #CCCCCC;
}

.has-attached-document {
  color: $secondary_darkest
}

.has-not-been-attached-document {
  color: $gray_light;
}

.sidenav-new-badge {
  background-color: lighten($danger, 15%);
  margin-left: 5;
  margin-top: -10;
}

.purchase-invoice-taxes-flash-message {
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 1px solid lighten($gray, 45%);
}

#product-variants-tabs {
  .product-variant {
    border-right: 1px solid $secondary_lighter;
    padding-top: 15px;
    padding-right: 0px;
    overflow-y: auto;
    height: calc(100vh - 149px);
    position: relative;
  }

  ul.nav {
    overflow-y: auto;
    height: calc(100vh - 176px);
  }

  .product-variant-detail-section {
    border-left: 1px solid $secondary_lighter;

    form.form-horizontal .product-variant-form {
      overflow-y: auto;
      max-height: 100%;
      flex: 1 1 auto;
      position: relative;
      height: calc(100vh - 176px);
    }
  }
}

.dashboard-chart-header-legend {
  float: left;
  width: 14;
  height: 12;
}

.h-100 {
  height: 100%;
}

// ul.dropdown-menu li.print-format-menu-item:hover {
//   background-color: $gray_lighter;
// }

.accounting-accounts-main.tabs-w-header .tab-content {
  height: calc(100vh - 120px);
}

.supplier-recents-tab-detail {
  border-left: 1px solid rgb(221, 221, 221);
  padding-top: 10px;
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.customer-recents-tab-details {
  border-left: 1px solid #ddd;
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.fa.fa-cog {
  font-size: large;
}

.form-group label.control-label sup.text-danger {
  top: 5px;
}

.form-group label.control-label span sup.text-danger {
  top: -0.5em;
}

#format-options-sortable-list .draggable-item.form-group .sort-handle {
  width: 24px;
  padding: 4px;
}

.action-buttons {

  .customer-address-listing-item-actions,
  .customer-contact-listing-item-actions,
  .address-listing-item-actions {
    position: absolute;
    display: flex;
    right: 0;
    top: 20px;
    bottom: 0px;
    margin: auto;
    align-items: center;
  }
}

th.product-current-price-nested-form-sr-no span {
  width: 48px !important;
}

.invoice-show-page-create-payment-btn {
  margin-right: 2px;
}

.allocate-to-purchase-invoice-form-section-title {
  .mx-6px {
    margin-bottom: 0px !important;
  }

  .remaining-amount {
    line-height: 34px !important;
  }
}

.allocate-to-purchase-invoice-header,
.purchase-invoice-payment-out-header {
  h4.text-muted {
    line-height: 34px !important;
    margin: 0px !important;
  }
}

.nested-form-header-label {
  padding-left: 5px;
}

.eligible-offer-table {
  td.eligible-offer-checkbox-td input {
    margin: 0
  }

  td.eligible-offer-label-td {
    padding-left: 20px;

    .discription {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.refetch-taxes-checkbox-with-label {
  .check-hover {
    cursor: pointer;

    input.checkbox {
      margin: 0;
    }
  }

  .discription {
    cursor: pointer;
    line-height: 36px;
    height: 36px;
    padding-left: 15px !important;
  }
}

.listing-card-2-container .navbar-fixed-top .listing-page-info {
  border-bottom: 1px solid $secondary_lighter;
}

.input-field-with-checkbox-sup {
  padding-left: 5px;
}

#input-checkbox-sequence {
  width: 35px;
}

.sup-top-zero-em {
  top: 0em !important;
}

.custom-tooltip.action-btn-round #tooltip .btn-link {
  cursor: pointer;
}

.product-packages-package-unit {
  width: 20%;
  line-height: 40px;
  border: 1px solid #e3e5e7;
  border-right: none;
  text-align: center;
  height: 42px;
  color: #b3b3b3;
}

.report-flash-message {
  z-index: 2;

  .alert {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


.pdf-viewer-parent-div .pdf-preview-error-text {
  font-weight: 400;
  color: $danger;
  line-height: 40px;
}

.quick-listing-modal-body.modal-body {
  overflow-y: unset;

  .container-fluid {
    padding-right: 6px;
  }
}

.control-label.product-packages-package-unit-label,
.control-label.product-packages-smallest-unit-label,
.control-label.product-packages-product-unit-multiplier-label {
  display: flex;
  text-align: center !important;
  flex-direction: column;
}

.gst-error-reports {
  margin-bottom: 40px;

  #generic-table {
    border-top: 1px solid $border-color;
  }
}

.price-per-input-price-per-unit {
  font-size: 14px;
  height: 42px;
  background-color: lighten($gray_lighter, 10%);
  color: $gray_light;
  border: 1px solid $border_color;
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 4px;
  // margin-left: 1px;
  // margin-right: 1px;
  border-right: 0;
}

.price-per-input-price-per-unit.credit-note-item {
  border-right: 1px solid $border-color;
}

// .order-item-input-price-per-unit .Select
.order-items-to-purchase-order-items-links {
  .row {
    margin: 0px -13px;
  }

  .order-item-purchase-order-item-label {
    cursor: pointer;
  }
}

tr.order-item-purchase-order-items-error-tr {
  td {
    border-top: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

// .order-order-item-input-price {
//   z-index: 0;
// }
.tally-books-beginning-from-label {
  margin: 10px 0px 0px 15px;

  .form-group-output {
    margin-bottom: 0px;
  }
}


.order-show-page-notes,
.payment-show-page-notes {
  height: auto;
  max-height: 60px;
  overflow-y: auto;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

// Padding classes
.p0 {
  padding: 0px;
}

.pl0 {
  padding-left: 0px;
}

.pr0 {
  padding-right: 0px;
}

// Margin classes
.mb-5 {
  margin-bottom: -5px;
}

.mb5percent {
  margin-bottom: 5%;
}

.item-serial-number label.control-label {
  display: flex;
  flex-direction: column;
}

.item-serial-number.invoice-sr-th-cell label.control-label {
  padding-top: 7px !important;
}

th.item-serial-number {
  padding: 0px;
  text-align: right;
}

// wrappable select
.wrappable-select {

  .Select-multi-value-wrapper,
  .Select-value,
  .Select-value-label {
    display: inline-block;
  }

  .Select-input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .Select-value {
    vertical-align: top;
    padding: 0 !important;
    position: initial !important;
    right: initial !important;
    top: initial !important;
    max-width: initial !important;
    overflow: initial !important;
    text-overflow: initial !important;
    white-space: initial !important;
    overflow-wrap: break-word;
  }

  .Select-value-label {
    padding: 10px 0 10px 12px;
    overflow-wrap: anywhere;
    line-height: 20px
  }
}

// Price per unit with tax field and their labels styles in nested forms
.nested-form-price-per-unit-labels,
.nested-form-price-per-unit-fields {
  display: flex;

  label {
    flex-shrink: 0;
  }

  .price-label,
  .price-field {
    width: calc(100% - 40% - 46px)
  }

  .tax-label {
    width: 46px;
  }

  .per-unit-label,
  .per-unit-field {
    width: 40%
  }

  .tax-field {
    width: 31px
  }
}

.linked-po-tracking-modal-btn {
  cursor: pointer;
  margin-top: 6px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.linked-po-tracking-modal-btn:focus,
.linked-po-tracking-modal-btn:hover,
.linked-po-tracking-modal-btn:active {
  outline: none;
  color: $gray;
}

.order-show-order-items-product-label:focus,
.order-show-order-items-product-label:hover {
  text-decoration: underline;
  outline: none;
}

.billing-address,
.shipping-address {
  .form-group .select-box-container .Select.wrappable-select .Select-control .Select-value-label {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.tax-id-type-unit .Select .Select-control {
  border-right: none;
}

.tax-id-type-unit .Select.is-focused .Select-control {
  border-right: 1px solid #272bda;
}

.tax-id-tax-types-tax-id-field {
  background-color: $white !important;
  z-index: auto !important;
}

.tax-id-tax-types-tax-id-field:focus {
  border-left: 1px solid #272bda !important;
}

.tax-id-tax-type-label {
  display: flex;
}

.net-quantity-net-quantity-unit-label {
  display: flex;
}

.net-quantity-unit .Select .Select-control {
  border-right: none;
}

.net-quantity-unit .Select.is-focused .Select-control {
  border-right: 1px solid #272bda;
}

.net-quantity-field {
  background-color: $white !important;
  z-index: auto !important;
  width: 50% !important;
}

.net-quantity-field:focus {
  border-left: 1px solid #272bda !important;
}

.net-quantity-net-quantity-unit {
  width: 50%;
}

.so-po-modal-dimensions {
  max-width: none !important;
  width: 65vw;
  margin: auto;
}

.supply_purchase_modal.modal-dialog .full_description .alert.alert-danger {
  margin-bottom: 10px;
  margin-top: 10px;
}

.advance-search-custom-field-label {
  font-size: 18px;
}

.title-sopo-pop-up-modal {
  font-size: 13px !important;
}

.order-item-purchase-order-item-quantity-orders-modal {
  height: 30px;
  text-align: right;
  width: 40%;
  margin: 0px 0px 0px 0px !important;
}

#total-quantity-heading-purchase-order-modal {
  padding-right: 15px;
}

#quantity-purhcase-order-modal {
  padding-right: 15px;
}

#container-box-purchase-order-modal-input-quantity {
  padding-left: 20px;
}

.order-item-purchase-order-item-unit-orders-modal {
  text-align: left;
  height: 30px;
  width: 50%;
}

.order-item-purchase-order-item-quantity-purchase-orders-modal {
  height: 30px;
  text-align: right;
  width: 40%;
  margin-right: 0px !important;
}

.order-item-purchase-order-item-unit-purchase-orders-modal {
  height: 30px;
  width: 50%;
}

.parent-container-box-not-found-component {
  color: #363636;
  margin-top: 50px;
}

.big-404-text-heading {
  font-size: x-large;
}

.page-not-found-heading-text {
  margin: 0px;
}

.page404-text-content-instructions {
  font-size: medium;
}

.pdf-preview-modal-body-embed {
  width: 100%;
  height: 80vh;
}

.pdf-preview-modal-parent-tag {
  width: 55vw;
}

.position-styling-download-file-circular-button {
  margin-right: 7px;
}

.position-styling-print-file-circular-button {
  margin-right: 7px;
}

.position-styling-preview-file-circular-button {
  margin-right: 7px;
}

.extra-centering-class-for-preview-modal-print-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-item-purchase-order-item-listing-parent-box {
  display: flex;
  color: $gray_darker;
}

.order-item-purchase-order-item-listing-modal-header-parent-row .title-and-item-name {
  padding-bottom: 5px;
}

.order-item-purchase-order-item-listing-modal-header-edit-pencil-icon {
  color: $primary;
}

.order-item-purchase-order-item-listing-modal-header-total-quantity-value-and-unit-div {
  font-size: 15px;
  padding-right: 17px;
}

.order-item-purchase-order-item-listing-modal-body-content-height {
  height: 60vh;
}

.order-item-purchase-order-item-listing-modal-body-content-inner-height {
  height: 57vh;
}

.orders-modal-customer-name-span-element {
  font-size: 12px;
}

.anchor-tag-parent-wrapper {
  overflow-wrap: anywhere;
}

.orders-modal-modal-header-parent-element {
  padding-left: 15px;
  padding-right: 17px;
}

.orders-modal-total-quantity-value-and-unit {
  font-size: 15px;
  color: $black;
}

.orders-modal-order-item-list-parent-div {
  margin-left: 6px;

  .order-item-purchase-order-item-row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.stock-items-compact-list {
  margin-bottom: 15%;
}

.stock-transfer-item-add-item-button {
  padding-top: 12px;
  padding-right: 12px;
}

.stock-transfer-items-compact-list .stock-transfer-items-compact-list-body {
  padding-left: 15px;
  padding-right: 15px;
}

.stock-transfer-items-display-nested-form-errors-tr {
  background-color: $white;

  .stock-transfer-items-display-nested-form-errors-td {
    border-bottom: 0px;
  }
}

.price-per-input-price-per-unit-stock-transfer {
  font-size: 14px;
  width: 5%;
  height: 42px;
  background-color: $th-default-bg-color;
  color: $gray_light;
  border: 1px solid $border-color;
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.stock-transfer-item-input-price-unit {
  width: 30%;
}

.input-fields-container-stock-transfer-compact-list-div {
  display: flex;
}

.stock-transfer-item-input-field {
  width: 60%;
}

.close-preview-modal-circular-button {
  margin-right: 23px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-action-buttons-inside-preview-modal {
  display: flex;
  justify-content: right;
}

.center-per-unit-icon-purchase-return-and-purchase-order {
  display: flex;
  align-items: center;
  justify-content: center;
}

.allocate-to-purchase-invoices-title {
  border-bottom: 1px solid $border_color;
  padding: 0px !important;
}

.e-invoice-qr-code {
  height: 150px;
  width: auto;
  padding-top: 12px;
}

.qr-code-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-per-unit-icon-purchase-return-and-purchase-order {
  display: flex;
  align-items: center;
}

.debit-note-item-nested-form-parent-ele {
  margin-bottom: 25%;
}

.cancelled-badge-sopo-show-modal {
  margin-right: 35px;
}

.credit-note-items-header {
  z-index: 1;
}

.credit-note-items-root {
  margin-bottom: 24%;
}

span.errors-message a {
  color: $primary_light;
  font-weight: 600;
  text-decoration: underline;
}

.form-group.supplier-address-nested-form {
  padding-left: 6px;
  padding-right: 6px;
}

.show-page-header-action-nav {

  .invoice-show-page-create-payment-btn,
  .e-invoice-button {
    height: 33px;
  }
}

.e-way-bill-part-a,
.e-way-bill-part-b {
  color: #4d4d4d;
}

.e-way-bill .control-label .fa.fa-info-circle {
  font-size: medium;
  color: $gray !important;
}

.listing-action-menu-item.e-way-bill.disabled,
.listing-action-menu-item.cancel-e-way-bill.disabled {
  a .text-primary {
    color: $primary_lighter !important;
  }
}

.vehicle-number-label .fa.fa-info-circle {
  font-size: medium;
}

.parent-row-payment-out-details {
  margin-left: -5px;
}

.parent-row-purchase-return-details {
  margin-left: -5px;
}

.parent-row-purchase-return-form {
  margin-left: 3px;
}

.parent-row-payment-outs-form {
  margin-left: 3px !important;
}

.payment-outs-form-page-right-coloumn {
  padding-right: 15px !important;
}

.branch-form .branch-form-main-content {
  margin-bottom: 20% !important;
}

.supplier-form.form-horizontal {
  .main-body {
    margin-bottom: 20% !important;
  }
}

.customer-form-contact-details .card-header-title h5 {
  margin-bottom: 10px;
}

.customer-form-contact-nested-form,
.supplier-form-contact-nested-form {
  .card-header-title h5 {
    margin-bottom: 10px;
  }
}

.customer-form.form-horizontal .customer-form-main-content {
  margin-bottom: 20% !important;
}

.gstin-label-parent {
  display: flex;
  align-items: center;

  a.search-and-fill-gstin-number {
    color: $primary;
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    text-align: end;
  }

  a.search-and-fill-gstin-number:hover {
    text-decoration: underline;
  }

  a.search-and-fill-gstin-number.disabled {
    color: $primary_lighter;
    cursor: not-allowed;
  }
}

.gstin-label-parent.is-loading {
  a {
    cursor: not-allowed;
  }
}

.successfully-validate-gstin.fa-check-square-o {
  color: $success;
}

.address-excel-label {
  padding-left: 0px;

  .top-section {
    display: flex;

    .customer-default-address.label-default {
      background-color: $gray;
    }

    .actions {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
    }
  }
}

.returns-form-left-parent-coloumn {
  padding-left: 30px !important;
}

.return-show-page-left-coloumn {
  padding-left: 15px !important;
}

.warehouses-parent-form-component {
  margin-bottom: 20% !important;
}

.warehouses-left-house-form-component {
  padding-left: 15px !important;
  padding-right: 20px !important;
}

.warehouses-right-house-form-component {
  padding-left: 20px !important;
  padding-right: 16px !important;
}

.master-branch-parent-row-div {
  margin-right: 1px;
  margin-left: 1px;
}

.master-branch-name-field {
  padding-left: 5px !important;
}

.master-mobile-name-field {
  padding-left: 5px !important;
}

.branch-zone-taxes-parent-col {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.branch-zone-taxes-add-button {
  margin-right: -15px !important;
}

.branch-warhouse-nested-form-container {
  padding-right: 2px !important;
}

.branch-warehouse-nested-form-add-button {
  margin-right: -30px;
}

.branch-warehouse-nested-form-label {
  padding-left: 3px !important;
}

.branch-warehouse-nested-form-select-field {
  padding-left: 3px !important;
}

.supplier-feed-section {
  display: grid !important;
  justify-content: center;
}

.last-processed-e-invoice-details,
.last-processed-e-way-bill-details,
.e-invoice-info,
.cancelled-e-invoice-details,
.cancelled-e-way-bill-details {
  .form-group-output-border {
    padding-left: 6px;
    padding-right: 6px;

    .form-group-output {
      margin-bottom: 10px;

      .error-status {
        color: $danger_light;
      }

      .e-invoice-label {
        margin-bottom: 5px !important;
        font-weight: 400 !important;
        color: #2d363f !important;
        font-size: 12px !important;
      }
    }
  }
}

.e-invoice-cancellation-errors,
.e-way-bill-cancellation-errors {
  .form-group-output {
    margin-bottom: 0px;

    .form-group-output-item {
      padding-bottom: 0px;
    }
  }

  .errors .form-group-output-border {
    label.control-label {
      font-weight: 500 !important;
      font-size: 14px !important;
      color: $alert_danger_text_color !important;
    }

    .form-group-output-item {
      color: $alert_danger_text_color !important;
    }
  }
}

.e-invoice-info {
  display: flex;
}

.sidebar-logo-main .mainly .full-sidenav {
  display: flex;

  .sidebar-logo-wrapper {
    padding: 0px;
  }

  .sidenav-brand-title-wrapper {
    padding: 0 10px 0 0;
    display: flex;
    align-items: center;

    .sidenav-brand-title {
      word-break: break-word;

      a {
        color: $white;
      }
    }
  }
}

.cancel-e-invoice-modal,
.cancel-e-way-bill-modal {
  .modal-dialog {
    width: 500px;
  }

  .modal-body {
    height: 35vh;
  }
}

.cancel-e-invoice-modal.errors {
  .modal-body {
    height: 50vh;
  }
}

.navbar-fixed-top.show-page-header.statistics-header {
  .top-report-options-dropdown {
    display: flex;
    align-items: end;
    margin-left: auto;
    margin-bottom: 10px;
  }
}

.product-type,
.product-variantable {
  .form-group {
    margin-bottom: 0px;

    .input-wrapper {
      padding: 0px;
    }

    label {
      font-size: 14px !important;
    }
  }
}

.account-show-details.form-group-output-item {
  height: 120px;
  max-height: 120px;
  min-height: 120px;
  overflow: auto;
}

.visit-header {
  .visit-party-name {
    font-weight: bold;
  }

  .visit-party-name,
  .visit-timestamp {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center
  }

  .badge {
    background-color: $gray_light;
    padding: 2px 3px;
    border-radius: 2px;
  }
}

.user-visits.index-listing-container-fluid {
  .content.table-responsive {
    padding-left: 0px !important
  }

  .view-more-visits {
    cursor: pointer;
  }

  .date-filter-wrapper {
    padding: 0px;
  }
}

.user-visits.tab-pane {
  height: calc(100vh - 230px);
  overflow-y: scroll;
}

.feed-upload-section-file-attachment-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-section-action-button-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.configuration-feed-input-fields,
.configuration-feed-settings {
  padding-top: 15px;
}

.configuration-feed-fields-label,
.configuration-feed-settings-label {
  border-bottom: 1px solid $border_color;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.feed.settings-form-elements.settings-scroll-container {
  height: auto !important;
}

.invoice.feed-upload-section-configurations-wrapper {
  padding-left: 0px;
  padding-right: 0px
}

.country-code-number-field {
  .input-group {
    .country-code {
      width: 60%;

      .Select-control {
        border-radius: 0px !important;

        .Select-multi-value-wrapper {
          .Select-value {
            padding-left: 5px;
            padding-right: 20px;
          }
        }

        // .Select-arrow-zone {
        //   display: none !important;
        // }
      }
    }

    input.form-control {
      background-color: $white !important;
      border-left: none;
    }
  }
}

.stock-show-page-product-inventory {
  tfoot {
    tr>td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.uninvoiced-orders-modal {
  .modal-body {
    height: 50vh;
  }
}

.footer {
  background-color: $white;
  width: 100%;
  left: 0;
  padding-bottom: 5px !important;
  position: fixed;
  bottom: 0;

  .product-owner-details {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    color: $label_color;
    width: 100%;
    text-align: center;

    a {
      color: $primary;
    }
  }

  .policies {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    color: $label_color;
    width: 100%;
    text-align: center;

    a {
      color: $gray !important;
    }
  }
}

.offline-component-help {
  padding-top: 10px;

  .main-order-list {
    .reload-page-text {
      b {
        font-size: 16px;
      }

      ol.secondary-order-list {
        padding-left: 20px;

        li>p {
          font-size: 16px;
        }
      }
    }

    .check-internet-text {
      b {
        font-size: 16px;
      }

      p {
        padding-left: 10px;
        font-size: 16px;
      }
    }

    .contact-support-text {
      b {
        font-size: 16px;
      }

      p {
        padding-left: 10px;
        font-size: 16px;
      }
    }
  }

  .offline-component-refresh {
    cursor: pointer;
  }
}

.error-dialog-modal-header {
  font-size: 18px;
  font-weight: 600;
  color: $black;
}

.action-btn-round.refresh.disabled,
.action-btn-round.refresh.disabled:hover {
  cursor: not-allowed !important;
  background: none repeat scroll 0 0 lighten($gray_lighter, 10%);

  .fa.fa-refresh {
    color: $gray_lighter !important;
    cursor: not-allowed !important;
  }
}

.pd-0 {
  padding: 0px !important;
}

.bank-account-opening-balance-type-credit {
  width: 50%;
}

.bulk-download-print-format-listing-action-menu-item-header {
  cursor: not-allowed !important;

  a[role=menuitem] {
    padding: 5px;
    font-weight: 600;
    cursor: not-allowed !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.bulk-download-print-format-listing-action-menu-item-divider {
  margin: 0px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.dms-select-no-result-text {
  opacity: 0.5;
}

.dms-select-upgrade-to-new-plan-text {
  color: $black;
}

@import "./custom_fields";
@import "./atomic";
@import "./reconciliations";
@import "./accounting";
@import "./quick_listing_modal";
@import "./accounts";
@import "./reports";
@import "./customers";
@import "./safari_only";
@import "./settings";
@import "./switch";
@import "./media_query";
@import "./customer_specific_taxes";
@import "./supplier_specific_taxes";
@import "./print_formats";
@import "./fonts";
@import "./box_model";
@import "./form_section_title";
@import './voucher_books';
@import './users';
@import './suppliers';
@import './nested_draggable_item';
@import './order_item_purchase_order_item_listing_modal';
@import './branches';
@import './inventory';
@import './stock';
@import './inventory_batch_tracking';
@import './inventory_piece_tracking';
@import './credential';
@import './products';
@import './location_tracking';
@import './offers';
@import './product_historical_pricing';
@import './tally_integration';
@import './bulk_update';
@import './landing_page';
@import './reset_account_password';
@import './update_account_profile';
@import './dashboard_home';
@import './sign_up_account';
@import './account_confirmation';
@import './new_organisation';
@import './getting_started/explore';
@import './getting_started/onboard';
@import './getting_started/help';
@import './content_help_video';
@import './config';
@import './module_level_reports';
@import './subscription';