@import "./table2";

.coa-content-container {
  padding-top: 0 !important;
  max-width: 100%;
}

.coa-table-tbody {
  tr:not(.accounting-children) td {
    padding: 9px 5px;
    position: sticky;
    top: 38px;
    z-index: 3;
    background: $white;
    box-shadow: $box-shadow 0px 2px 1px -1px;
  }

  tr.accounting-children td {
    padding: 0 !important;
  }
}

.btn-link {
  color: $primary;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: darken($color: $primary, $amount: 10);
  }
}

#side-info-panel {
  // flex: 1 1 auto;
  @include flex($column: true, $a: null, $j: null);
  width: calc(100% - 33.33333333%);
  border-left: 1px solid $border_color;
}

.side-info-panel-header {
  @include flex($j: null);
  height: 40px;
  width: 100%;
  border-bottom: 1px solid $border_color;
  padding: 0 0 0 15px;

  i {
    color: $gray_light;
    cursor: pointer;
  }
}

.side-info-panel-content {
  flex: 1 1 auto;
  display: flex;
  max-height: 100%;
  overflow-y: auto;
}

.side-info-panel-breadcrumbs {
  @include flex($j: flex-start);
  color: $secondary;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;

  i.fa-chevron-left {
    margin-right: 10px;
  }

  .spacer-span {
    margin: 0 5px;
  }
}

.sub-acc-parent {
  position: relative;
  transition: .3s;

  &>div {
    padding: 5px 0px 5px 20px;
  }

  .active-chev {
    transition: opacity .3s;
    color: $secondary;
  }

  &:not(.is-inactive).is-active // &:not(.is-inactive):hover 

    {
    background: lighten($gray_lighter, 10%);
    cursor: pointer;
  }

}

.side-info-panel-table {
  padding: 0 0 0 15px;
}

.pulsing-placeholder {
  animation: pulsing-bkg 1s ease-in-out 0s infinite;
  border-radius: 2px;
  width: 100%;
  height: calc(100% - 16px);
}

@keyframes pulsing-bkg {

  0%,
  100% {
    background-color: $gray_lightest;
  }

  50% {
    background-color: lighten($gray_lighter, 8%);
  }
}

.acc-details {
  @include flex($j: null);

  .acc-name {
    @include flex($j: space-between);
    flex: 1 1 auto;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .acc-more-details {
    margin: 0 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
    padding: 3px;
    line-height: 14px;
    background: transparent;

    &:hover {
      background: lighten($color: $primary, $amount: 35);
    }

    .fa {
      font-size: 14px;
    }
  }

  &:hover {
    .acc-more-details {
      visibility: visible;
      opacity: 1;
    }
  }
}

.recon-status {
  width: 120px
}

$recon-icon-size: 14px;
$recon-icon-offset: 3px;

.recon-icon {
  position: relative;
  display: inline-block;
  width: $recon-icon-size + $recon-icon-offset;
  height: $recon-icon-size + $recon-icon-offset;

  .fa {
    position: absolute;
    left: 0;
    top: 0;
    background: $white;
    font-size: $recon-icon-size !important;
    width: $recon-icon-size + 2px !important;
    line-height: $recon-icon-size !important;
  }

  &>.fa:last-child {
    left: $recon-icon-offset;
    top: $recon-icon-offset;
  }
}

.recon-date {
  width: 100px;
}

$chev-btn-dim: 25px;

.chev-btn {
  width: 60px;
  height: $chev-btn-dim;
  text-align: center;
  border-radius: $chev-btn-dim;
  margin: 0 12px;
  flex-shrink: 0;

  i {
    line-height: $chev-btn-dim;
  }

  &:hover {
    background: rgba($color: $gray_lighter, $alpha: .5);
  }
}

.account-group {
  height: 89px;
}

.sub-account-form-modal-body {
  .account-group .input-wrapper {
    padding: 0px;
  }
}

.account-opening-balance-type-credit {
  width: 50%;
}