.pagination a {
  background: $white none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 1px !important;
  margin: 0 0 0 -1px !important;
  border: 1px solid $gray_lighter !important;
}

.pagination > li > a:hover {
  background-color: lighten($gray, 45%) !important;
}

.pagination a {
  color: $primary !important;
}

.pagination .disabled a {
  color: $gray_lighter !important;
}

.pagination .active a, .pagination .active a:hover {
  background: $primary none repeat scroll 0 0 !important;
  color: $white !important;
}

.pagination .disabled a {
  opacity: 1;
}

.fixed-pagination {
  position: fixed;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  margin: 20px 0 10px 0px;
}

// .pagination {
//   .active>a,
//   .active>a:hover,
//   .active>a:focus {
//     background-color: $gray_light;
//     border-color: $gray_light;
//   }
//   li {
//     &:first-child a,
//     &:last-child a {
//       border-radius: 50px;
//     }
//     a {
//       border-radius: 25px;
//       margin: 2px;
//       outline: none;
//       color: $black;
//     }
//   }
// }