// Style of statuses.
// white
tr.row-white,
tr.row-white:hover {
  background-color: $white_row_bg_color !important;
}

// info_lightest
tr.row-info,
tr.row-info:hover {
  background-color: $info_row_bg_color !important;
}

// gray_lightest
tr.row-cancelled,
tr.row-cancelled:hover {
  background-color: $cancelled_row_bg_color !important;
}

// warning_lightest
tr.row-warning,
tr.row-warning:hover {
  background-color: $warning_row_bg_color !important;
}

// success_lightest
tr.row-success,
tr.row-success:hover,
tr.row-issued,
tr.row-issued:hover,
#generic-table tr.row-issued>td.action-co .listing-item-actions {
  background-color: $success_row_bg_color !important;
}

// danger_lightest
tr.row-danger,
tr.row-danger:hover {
  background-color: $danger_row_bg_color !important;
}