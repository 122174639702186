// Quick Listing modal style
.quick-listing-module {
  .modal-dialog {
    width: 80vw;
    min-width: 876px;

    .new-advance-search-modal-body > div {
      height: 100%;
      overflow-y: auto;
    }
  }

  .modal-content {
    position: relative;
    height: 100%;
  }

  .modal-header {
    text-align: left;
  }

  .modal-body {
    position: unset;
    padding: 15px 0;

    #product-variant-tab-panels {
      position: unset;
    }

    #product-variants-and-images-form-tabs-pane-1 .form-footer-btn {
      padding-right: 25px;
    }

    #product-variants-and-images-form-tabs-pane-2 .form-footer-btn {
      padding-right: 40px;
    }
  }

  .navbar-fixed-top {
    display: none;
  }

  .form-container-fluid {
    padding-top: 0;
    // padding-left: 5px;
    // padding-right: 5px;

    & > .row > .col-md-12 {
      position: unset;
    }
  }

  .fixed-action-footer {
    position: absolute;
    bottom: 0;
    left: 0 !important;
    width: 100% !important;
    z-index: 15;
  }
}

.quick-listing-modal-body {
  height: 95vh;
  display: flex;
  flex-direction: column;
  max-height: initial;
  .open_listing_in_modal {
    padding-top: 0;
  }
  .container-fluid  { margin: 0; }

  .index-listing-container-fluid {
    padding-top: 0px;
    flex: 1 1 auto;
    max-height: 100%;
    overflow-y: auto;
  }
  .navbar.navbar-default {
    margin-bottom: 0px;
  }
  .index-listing-container-fluid {
    border-top: 1px solid gainsboro;
  }
  .navbar-collapse {
    padding-right: 0px;
  }
  .close-listing-modal button {
    font-size: 28px;
  }
}