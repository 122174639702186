.show-page-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .show-page-main-container {
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .navbar-fixed-top.show-page-header {
      .container-fluid {
        display: flex;

        .navbar-collapse.collapse {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .navbar-fixed-top {
    position: unset;
  }

  .show-page-container-fluid {
    padding-top: 30px;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}