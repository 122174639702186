.footer {
  background-color: $white;
  line-height: 20px;
}

.footer nav>ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.footer nav>ul a:not(.btn) {
  color: $gray_light;
  display: block;
  margin-bottom: 3px;
}

.footer nav>ul a:not(.btn):hover,
.footer nav>ul a:not(.btn):focus {
  color: $gray_darkest !important;
  text-decoration: none;
}

.footer .social-area {
  padding: 15px 0;
}

.footer .social-area h5 {
  padding-bottom: 15px;
}

.footer .social-area>a:not(.btn) {
  color: $gray_light;
  display: inline-block;
  vertical-align: top;
  padding: 10px 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}

.footer .social-area>a:not(.btn):hover,
.footer .social-area>a:not(.btn):focus {
  color: $gray;
}

.footer .copyright {
  color: $gray;
  padding: 10px 15px;
  margin: 10px 3px;
  line-height: 20px;
  font-size: 14px;
}

.footer hr {
  border-color: $gray_lightest;
}

.footer .title {
  color: $gray;
}

.footer-default {
  background-color: $gray_lightest;
}

.footer:not(.footer-big) nav>ul {
  font-size: 16px;
}

.footer:not(.footer-big) nav>ul li {
  margin-left: 20px;
  float: left;
}

.footer:not(.footer-big) nav>ul a {
  padding: 10px 0px;
  margin: 10px 10px 10px 0px;
}