// Global variables
// Ref: https://getbootstrap.com/docs/4.1/utilities/colors/
// gray color code variabels.

$gray: #808080;
$gray_lightest: lighten($gray, 52%);
$gray_lighter: lighten($gray, 35%);
$gray_light: lighten($gray, 20%);
$gray_dark: darken($gray, 10%);
$gray_darker: darken($gray, 20%);
$gray_darkest: darken($gray, 30%);

// secondary color code variabels.
$secondary: #6c757d;
$secondary_lightest: lighten($secondary, 50%);
$secondary_lighter: lighten($secondary, 40%);
$secondary_light: lighten($secondary, 25%);
$secondary_dark: darken($secondary, 5%);
$secondary_darker: darken($secondary, 10%);
$secondary_darkest: darken($secondary, 15%);

// Primary color code variabels.
$primary: #1A73E8;
$primary_lightest: lighten($primary, 45%);
$primary_lighter: lighten($primary, 30%);
$primary_light: lighten($primary, 15%);
$primary_dark: darken($primary, 5%);
$primary_darker: darken($primary, 10%);
$primary_darkest: darken($primary, 15%);

// Danger color code variabels.
$danger: #f44336;
$danger_lightest: lighten($danger, 39%);
$danger_lighter: lighten($danger, 30%);
$danger_light: lighten($danger, 10%);
$danger_dark: darken($danger, 5%);
$danger_darker: darken($danger, 10%);
$danger_darkest: darken($danger, 15%);

// Warning color code variabels.
$warning: #f5b800;
$warning_lightest: lighten($warning, 45%);
$warning_lighter: lighten($warning, 30%);
$warning_light: lighten($warning, 20%);
$warning_dark: darken($warning, 5%);
$warning_darker: darken($warning, 10%);
$warning_darkest: darken($warning, 15%);

// Info color code variabels.
$info: #17a2b8;
$info_lightest: lighten($info, 57%);
$info_lighter: lighten($info, 30%);
$info_light: lighten($info, 15%);
$info_dark: darken($info, 5%);
$info_darker: darken($info, 10%);
$info_darkest: darken($info, 15%);

// Success color code variabels.
$success: #28a745;
$success_lightest: lighten($success, 57%);
$success_lighter: lighten($success, 45%);
$success_light: lighten($success, 20%);
$success_dark: darken($success, 5%);
$success_darker: darken($success, 10%);
$success_darkest: darken($success, 15%);

$black: #27221B;
$white: #FFFFFF;
$brand-blue: #00d8c8;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

// default 'tr' background color.
$th-default-bg-color: #F3F4F5;

// General variabels.
$font_family: "Roboto", sans-serif;
$sidebar_width: 230px;
$mini_sidebar_width: 84px;
$max_header_height: 123px;
$sidebar_nav_background: #29334D; //#1A1E33; //#0a074a
$sidebar_nav_highlight: lighten($sidebar_nav_background, 10%);
$border_color: lighten($secondary, 44%); //#e3e5e7
$box_shadow: $gray_lighter; //#d8dbdd
$dashboard_background_color: lighten($gray_lighter, 10%);

// Borders
$primary_input_field_border_color: $primary; //#5c5de8
$gray_input_field_border_color: #D0D1D8; //#e3e5e7
$danger_input_field_border_color: #DD6860;
$warning_input_field_border_color: #FFBF00;
$hr_border_color: $secondary_lighter; //#d8dbdd
$link_color: $primary;

$delete_button_color: $danger;
$disabled_delete_button_color: lighten($danger, 20%);

// Error Messages
$errors_message: #DD6860;
$warnings_message: #FFBF00;
$login_field_error_message: $errors_message;
$login_page_left_bg_color: #1A1E33; //before we use => $primary
$login_page_button_bg_color: #fe7c19; // before we use => $brand-blue
$login_page_right_section_header_text_color: #07103C;
$login_page_right_section_paragraph_text_color: #51525A;

$primary_button_color: $primary;
$primary_button_color_on_hover: darken($primary_button_color, 6%);
$primary_light_button_color: lighten($primary_button_color, 15%);


// Link colors
$primary_link_color: darken($primary, 6%);

// Alert
$alert_info_bg_color: #DDECF6;
$alert_info_text_color: darken(#00B4E6, 20%);

$alert_success_bg_color: #E2EFDA;
$alert_success_text_color: darken(#00AF4C, 18%);

$alert_warning_bg_color: #FBF8E5;
$alert_warning_text_color: darken(#FFBF00, 20%);

$alert_danger_bg_color: #FBEFEE;
$alert_danger_text_color: darken(#DD6860, 16%);

// Label color
$label_color: #2d363f;

// Dashboard home page
$increment_percentage_color: #00AF4C;
$decrement_percentage_color: #DD6860;

// Notification colors
$success_notification_bg_color: $alert_success_bg_color;
$success_notification_text_color: $alert_success_text_color;
$error_notification_bg_color: $alert_danger_bg_color;
$error_notification_text_color: $alert_danger_text_color;

$breadcrumb_link_color: #1A73E8;
$breadcrumb_text_color: #393A3D;
$dashboard_header_text_color: #27221B;
$dashboard_control_label: #51525A;
$dashboard_caret_increment-percentage: #E01607;
$dashboard_caret_decrement-percentage: #48A163;

// Status Pill colors
$default_label_text_color: #51525A;
$default_label_bg_color: #B3B3B3;
$success_label_bg_color: #00AF4C;
$info_label_bg_color: #00B4E6;
$danger_label_bg_color: #DD6860;
$warning_label_bg_color: #FFBF00;

// Row BG colors
$white_row_bg_color: $white;
$info_row_bg_color: #DDECF6;
$cancelled_row_bg_color: $gray_lightest;
$warning_row_bg_color: #FBF8E5;
$success_row_bg_color: #E2EFDA;
$danger_row_bg_color: #FBEFEE;

$table_data_color: #27221B;


:export {
  gray_lightest: $gray_lightest;
  gray_lighter: $gray_lighter;
  gray_light: $gray_light;
  gray: $gray;
  gray_dark: $gray_dark;
  gray_darker: $gray_darker;
  gray_darkest: $gray_darkest;

  secondary_lightest: $secondary_lightest;
  secondary_lighter: $secondary_lighter;
  secondary_light: $secondary_light;
  secondary: $secondary;
  secondary_dark: $secondary_dark;
  secondary_darker: $secondary_darker;
  secondary_darkest: $secondary_darkest;

  primary_lightest: $primary_lightest;
  primary_lighter: $primary_lighter;
  primary_light: $primary_light;
  primary: $primary;
  primary_dark: $primary_dark;
  primary_darker: $primary_darker;
  primary_darkest: $primary_darkest;

  danger_lightest: $danger_lightest;
  danger_lighter: $danger_lighter;
  danger_light: $danger_light;
  danger: $danger;
  danger_dark: $danger_dark;
  danger_darker: $danger_darker;
  danger_darkest: $danger_darkest;

  warning_lightest: $warning_lightest;
  warning_lighter: $warning_lighter;
  warning_light: $warning_light;
  warning: $warning;
  warning_dark: $warning_dark;
  warning_darker: $warning_darker;
  warning_darkest: $warning_darkest;

  info_lightest: $info_lightest;
  info_lighter: $info_lighter;
  info_light: $info_light;
  info_dark: $info_dark;
  info_darker: $info_darker;
  info_darkest: $info_darkest;

  success_lightest: $success_lightest;
  success_lighter: $success_lighter;
  success_light: $success_light;
  success: $success;
  success_dark: $success_dark;
  success_darker: $success_darker;
  success_darkest: $success_darkest;

  white: $white;
  notification_info: $alert_info_bg_color;
  notification_info_text_color: $alert_info_text_color;
  notification_success: $alert_success_bg_color;
  notification_success_text_color: $alert_success_text_color;
  notification_warning: $alert_warning_bg_color;
  notification_warning_text_color: $alert_warning_text_color;
  notification_danger: $alert_danger_bg_color;
  notification_danger_text_color: $alert_danger_text_color;
  border_color: $border_color;

  error_notification_bg_color: $error_notification_bg_color;
  error_notification_text_color: $error_notification_text_color;
  success_notification_bg_color: $success_notification_bg_color;
  success_notification_text_color: $success_notification_text_color;

  breadcrumb_link_color: $breadcrumb_link_color;

  // Status Pill colors
  default_label_text_color: $default_label_text_color;
  success_label_bg_color: $success_label_bg_color;
  info_label_bg_color: $info_label_bg_color;
  danger_label_bg_color: $danger_label_bg_color;
  warning_label_bg_color: $warning_label_bg_color;
}