@import "./variables.module";
@import "./login";
@import "./loader";
@import "./card";
@import "./btn";
@import "./alert";
@import "./checkbox_and_radio";
@import "./react_datepicker";
@import "./status_styles";
@import "./pagination";
@import "./footer";
@import "./side_modal";
@import "./comments_and_documents";
@import "./audit_history";
@import "./users_group_form";
@import "./price_screen";
@import "./credit_control";
@import "./markdown";

/* Start Old Css */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

/* Font Smoothing */

body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font_family;
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 300;
}

h1,
.h1 {
  font-size: 52px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 28px;
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: 22px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 14px;
  font-weight: 600;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.active-top-report-options-dropdown.reports-container-fluid.container-fluid .text-center.row {
  margin: 0px;
}

.active-top-report-options-dropdown.reports-container-fluid.container-fluid tfoot td {
  border-bottom: 1px solid $border_color;
}

.fix-col {
  z-index: 9;
  background: $white;
}

.fix-table .table-responsive {
  // height: calc(100vh - 250px);
  position: relative;
  width: 100%;
  overflow-y: hidden;
}

.fix-table .table-responsive table {
  margin: 0;
}

.fix-table .table-fix-top {
  position: absolute;
  top: 0;
  z-index: 99;
  background: $white;
}

.fix-table .table-fix-body {
  overflow-y: auto;
  height: calc(100vh - 250px);
  overflow-x: hidden;
  width: fit-content;
}

.fix-table .table-fix-bottom {
  position: absolute;
  bottom: 0;
  z-index: 99;
  background: $white;
}

.fix-table tbody tr td:nth-child(1),
.fix-table thead tr:nth-child(1) th:nth-child(1),
.fix-table tfoot td:nth-child(1) {
  position: relative;
  background-color: $white;
}

.table-bottom-hidden {
  display: none;
}

.table-mid-auto-height,
.auto-height-table {
  height: auto !important;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: $gray;
  font-weight: 300;
  line-height: 1.5;
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

h1 .subtitle {
  display: block;
  margin: 0 0 30px;
}

.text-muted {
  color: $gray;
}

.text-primary,
.text-primary:hover {
  color: $primary !important;
}

.text-info,
.text-info:hover {
  color: $info !important;
}

.text-success,
.text-success:hover {
  color: $success !important;
}

.text-warning,
.text-warning:hover {
  color: $warning !important;
}

.text-danger,
.text-danger:hover {
  color: $danger !important;
}

.text-black,
.text-black:hover {
  color: $black !important;
}

/* General overwrite */

body,
.wrapper {
  height: 100vh;
  position: relative;
  background-color: $white;
}

a {
  color: $primary_link_color;
}

a:hover,
a:focus {
  color: $primary_link_color;
  text-decoration: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0;
}

.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/* Animations */

.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.sidebar .nav a,
.table>tbody>tr .td-actions .btn {
  -webkit-transition: all 150ms ease-in;
  -moz-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.btn {
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.fa {
  width: 18px;
  text-align: center;
}

.margin-top {
  margin-top: 50px;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar_width;
  display: block;
  z-index: 1;
  color: $white;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
  background-color: $sidebar_nav_background;
}

.sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  /*overflow: auto;*/
  width: $sidebar_width;
  z-index: 4;
  padding-bottom: 30px;
}

.sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.sidebar .logo {
  padding: 10px 30px;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 4;
}

.sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: $white;
  line-height: 20px;
  font-family: $font_family;
}

.sidebar .logo a.logo-mini {
  float: left;
  text-align: center;
  width: 30px;
  margin-right: 15px;
}

.sidebar .logo a.logo-mini img {
  width: 40px;
  margin-left: -3px;
  display: block;
  margin-top: 2px;
}

.sidebar .logo a.logo-normal {
  display: block;
}

.sidebar .logo .logo-img {
  width: 34px;
  display: inline-block;
  height: 34px;
  margin-left: -2px;
  margin-top: -2px;
  margin-right: 10px;
  border-radius: 30px;
  text-align: center;
}

.sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid $gray_darker;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

.sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar .nav {
  margin-top: 20px;
}

.sidebar .nav li>a {
  color: $white;
  margin: 5px 15px;
  border-radius: 4px;
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar .nav .caret {
  top: 24px;
  position: absolute;
  right: 15px;
}

.sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.sidebar .logo,
body>.navbar-collapse .logo {
  padding: 10px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar .logo p,
body>.navbar-collapse .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: $white;
  line-height: 20px;
  font-family: $font_family;
}

.sidebar .logo .simple-text,
body>.navbar-collapse .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: $white;
  font-weight: 400;
  line-height: 30px;
}

.sidebar .logo-tim,
body>.navbar-collapse .logo-tim {
  border-radius: 50%;
  border: 1px solid $gray_darker;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

.sidebar .logo-tim img,
body>.navbar-collapse .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar:after,
.sidebar:before,
body>.navbar-collapse:after,
body>.navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar:before,
body>.navbar-collapse:before {
  opacity: 0.33;
  background: $black;
}

.sidebar:after,
body>.navbar-collapse:after {
  background: $gray_darker;
  background: -moz-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, $gray_darker),
      color-stop(100%, $gray_darkest));
  background: -webkit-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: -o-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: -ms-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: linear-gradient(to bottom, $gray_darker 0%, $gray_darkest 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

.sidebar[data-color="custom"]:after,
body>.navbar-collapse[data-color="custom"]:after {
  background: $sidebar_nav_background;
  background-size: 150% 150%;
}

.sidebar[data-color="blue"]:after,
body>.navbar-collapse[data-color="blue"]:after {
  background: $primary;
  background: -moz-linear-gradient(top, $primary 0%, $primary_darkest 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, $primary),
      color-stop(100%, $primary_darkest));
  background: -webkit-linear-gradient(top, $primary 0%, $primary_darkest 100%);
  background: -o-linear-gradient(top, $primary 0%, $primary_darkest 100%);
  background: -ms-linear-gradient(top, $primary 0%, $primary_darkest 100%);
  background: linear-gradient(to bottom, $primary 0%, $primary_darkest 100%);
  background-size: 150% 150%;
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - #{$sidebar_width});
}

.main-panel>.content {
  padding: 30px 15px;
  min-height: calc(100% - #{$max_header_height});
}

.main-panel>.footer {
  border-top: 1px solid $gray_lightest;
}

.main-panel .navbar {
  margin-bottom: 0;
}

.sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.form-control::-moz-placeholder {
  color: $gray_light;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-moz-placeholder {
  color: $gray_light;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
  color: $gray_light;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
  color: $gray_light;
  opacity: 1;
  filter: alpha(opacity=100);
}

.Select-placeholder,
.Select--single>.Select-control .Select-value {
  color: $gray_light;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 40px;
}

.Select.is-focused:not(.is-open)>.Select-control {
  border-color: $primary_input_field_border_color;
  -webkit-box-shadow: none;
}

.Select.is-open>.Select-control {
  border-color: $primary_input_field_border_color;
}

.Select-menu-outer {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid $primary_input_field_border_color;
  border-top-color: $white;
}

.form-control,
.Select-control {
  background-color: $white;
  border: 1px solid $gray_input_field_border_color;
  border-radius: 2px;
  color: $gray_darker;
  height: 42px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400 !important;
}

.form-control,
.Select-control:hover,
.form-control,
.Select-control:focus,
.form-control,
.Select-control:active {
  box-shadow: none;
}

.form-control:focus {
  background-color: $white;
  border: 1px solid $primary_input_field_border_color;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: $gray_darker;
}

::placeholder {
  color: $gray_light;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $gray_light;
}

::-ms-input-placeholder {
  color: $gray_light;
}

.form-control::-webkit-input-placeholder {
  color: $gray_light;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: $gray_light;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: $gray_light;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: $gray_light;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: $gray_light;
}

/* Microsoft Edge */

.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
  border-color: $gray_lightest;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .form-control {
  color: $success;
}

.has-success .form-control:focus {
  border-color: $success;
}

.has-error .form-control {
  color: $danger;
}

.has-error .form-control:focus {
  border-color: $danger;
}

.form-control+.form-control-feedback {
  border-radius: 6px;
  font-size: 14px;
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
  vertical-align: middle;
}

.open .form-control {
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent;
}

.input-lg {
  height: 55px;
  padding: 14px 30px;
}

.has-error .form-control-feedback {
  color: $danger;
}

.has-success .form-control-feedback {
  color: $success;
}

.input-group-addon {
  background-color: $white;
  border: 1px solid $border_color;
  border-radius: 4px;
}

.has-success .input-group-addon,
.has-error .input-group-addon {
  background-color: $white;
  border: 1px solid $gray_light;
}

.has-error .form-control:focus+.input-group-addon {
  border-color: $danger;
  color: $danger;
}

.has-success .form-control:focus+.input-group-addon {
  border-color: $success;
  color: $success;
}

.form-control:focus+.input-group-addon,
.form-control:focus~.input-group-addon {
  border-color: $gray_light;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child) {
  border-left: 1px solid $border_color;
  background-color: lighten($gray_lighter, 10%);
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.is-disabled .Select-control {
  background-color: lighten($gray_lighter, 13%);
  color: $gray_light;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 16px;
}

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: $gray_lightest;
}

.input-group-btn:last-child>.btn {
  margin-left: 0;
}

.input-group-focus .input-group-addon {
  border-color: $gray_light;
}

.nav>li>a:hover,
.nav>li>a:focus {
  background-color: transparent;
}

.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
}

.navbar .navbar-minimize {
  float: left;
  margin: 3px 0px;
}

.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  padding: 15px 15px;
}

.navbar .navbar-nav>li>a {
  padding: 10px 15px;
  /*margin: 10px 3px;*/
  position: relative;
}

.navbar .navbar-nav>li>a.btn {
  margin: 15px 3px;
  padding: 8px 16px;
}

.navbar .navbar-nav>li>a.btn-sm {
  margin: 0px;
  padding: 5px 10px;
}

.navbar .navbar-nav>li>a.btn-primary {
  color: $white;
}

.navbar .navbar-nav>li>a.btn-primary:hover {
  background-color: $primary_lighter;
  border-color: $primary_lighter;
}

.navbar .navbar-nav>li>a.btn-round {
  margin: 16px 3px;
}

.navbar .navbar-nav .notification {
  position: absolute;
  background-color: $danger_light;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: $white;
  font-weight: bold;
  line-height: 18px;
  top: 0px;
  left: 7px;
}

.navbar .btn {
  font-size: 14px;
  line-height: 1.5;
  // min-height: 34px;
  // padding: 2px 11px;
}

.navbar .btn-simple {
  font-size: 16px;
}

.navbar.fixed {
  right: 0;
  left: auto;
  border-radius: 0;
}

.navbar-nav>li>.dropdown-menu {
  border-radius: 10px;
  margin-top: -5px;
}

.navbar-transparent .navbar-brand,
[class*="navbar-ct"] .navbar-brand {
  color: $white;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-transparent .navbar-brand:focus,
.navbar-transparent .navbar-brand:hover,
[class*="navbar-ct"] .navbar-brand:focus,
[class*="navbar-ct"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .navbar-nav>li>a:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:not(.btn) {
  color: $white;
  border-color: $white;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.navbar-transparent .navbar-nav>.active>a:not(.btn),
.navbar-transparent .navbar-nav>.active>a:hover:not(.btn),
.navbar-transparent .navbar-nav>.active>a:focus:not(.btn),
.navbar-transparent .navbar-nav>li>a:hover:not(.btn),
.navbar-transparent .navbar-nav>li>a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav>.active>a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav>li>a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: $white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .navbar-nav .nav>li>a.btn:hover,
[class*="navbar-ct"] .navbar-nav .nav>li>a.btn:hover {
  background-color: transparent;
}

.navbar-transparent .navbar-nav>.dropdown>a .caret,
.navbar-transparent .navbar-nav>.dropdown>a:hover .caret,
.navbar-transparent .navbar-nav>.dropdown>a:focus .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a:hover .caret,
[class*="navbar-ct"] .navbar-nav>.dropdown>a:focus .caret {
  border-bottom-color: $white;
  border-top-color: $white;
}

.navbar-transparent .navbar-nav>.open>a,
.navbar-transparent .navbar-nav>.open>a:hover,
.navbar-transparent .navbar-nav>.open>a:focus,
[class*="navbar-ct"] .navbar-nav>.open>a,
[class*="navbar-ct"] .navbar-nav>.open>a:hover,
[class*="navbar-ct"] .navbar-nav>.open>a:focus {
  background-color: transparent;
  color: $white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .btn-default,
[class*="navbar-ct"] .btn-default {
  color: $white;
  border-color: $white;
}

.navbar-transparent .btn-default.btn-fill,
[class*="navbar-ct"] .btn-default.btn-fill {
  color: $gray_light;
  background-color: $white;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default,
[class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: $white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-open .nav .caret {
  border-bottom-color: $white;
  border-top-color: $white;
}

.navbar-default {
  background-color: $white;
}

.navbar-transparent .navbar-form .form-control,
[class*="navbar-ct"] .navbar-form .form-control {
  color: $white;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0;
}

.navbar-toggle .icon-bar {
  background-color: $white;
}

.navbar-toggle .navbar-collapse,
.navbar-toggle .navbar-form {
  border-color: transparent;
}

.dropdown-menu {
  visibility: hidden;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  display: block;
  z-index: 9000;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
}

.open .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.select .dropdown-menu {
  border-radius: 0 0 10px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform-origin: 50% -40px;
  -moz-transform-origin: 50% -40px;
  -o-transform-origin: 50% -40px;
  -ms-transform-origin: 50% -40px;
  transform-origin: 50% -40px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
}

.select.open .dropdown-menu {
  margin-top: -1px;
}

.dropdown-menu>li>a {
  padding: 8px 16px;
  color: $gray_darker;
}

.dropdown-menu>li>a img {
  margin-top: -3px;
}

.dropdown-menu>li>a:focus {
  outline: 0 !important;
}

.btn-group.select .dropdown-menu {
  min-width: 100%;
}

.dropdown-menu>li:first-child>a {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-menu>li:last-child>a {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select .dropdown-menu>li:first-child>a {
  border-radius: 0;
  border-bottom: 0 none;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-color: $gray_lightest;
  color: $gray_darker;
  opacity: 1;
  text-decoration: none;
}

.dropdown-menu.dropdown-blue>li>a:hover,
.dropdown-menu.dropdown-blue>li>a:focus {
  background-color: rgba(52, 114, 247, 0.2);
}

.dropdown-menu.dropdown-azure>li>a:hover,
.dropdown-menu.dropdown-azure>li>a:focus {
  background-color: rgba(29, 199, 234, 0.2);
}

.dropdown-menu.ct-green>li>a:hover,
.dropdown-menu.ct-green>li>a:focus {
  background-color: rgba(135, 203, 22, 0.2);
}

.dropdown-menu.dropdown-orange>li>a:hover,
.dropdown-menu.dropdown-orange>li>a:focus {
  background-color: rgba(255, 149, 0, 0.2);
}

.dropdown-menu.dropdown-red>li>a:hover,
.dropdown-menu.dropdown-red>li>a:focus {
  background-color: rgba(255, 74, 85, 0.2);
}

.dropdown-with-icons>li>a {
  padding-left: 0px;
  line-height: 28px;
}

.dropdown-with-icons i {
  text-align: center;
  line-height: 28px;
  float: left;
}

.dropdown-with-icons i[class^="pe-"] {
  font-size: 24px;
  width: 46px;
}

.dropdown-with-icons i[class^="fa"] {
  font-size: 14px;
  width: 38px;
}

.btn-group.select {
  overflow: hidden;
}

.btn-group.select.open {
  overflow: visible;
}

.navbar-form {
  margin-top: 21px;
  margin-bottom: 21px;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-nav>li>.dropdown-menu:before {
  border-bottom: 11px solid rgba(192, 186, 186, 0.2);
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -11px;
}

.navbar-nav>li>.dropdown-menu:after {
  border-bottom: 11px solid $white;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -10px;
}

.navbar-nav.navbar-right>li>.dropdown-menu:before {
  left: auto;
  right: 12px;
}

.navbar-nav.navbar-right>li>.dropdown-menu:after {
  left: auto;
  right: 12px;
}

.footer:not(.footer-big) nav>ul li:first-child {
  margin-left: 0;
}

body>.navbar-collapse.collapse {
  display: none !important;
}

.card form [class*="col-"] {
  padding: 0 6px;
}

.card form [class*="col-"]:first-child {
  padding-left: 15px;
}

.card form [class*="col-"]:last-child {
  padding-right: 15px;
}

@media (min-width: 992px) {}

/* Changes for small display */
@media (max-width: 991px) {}

@media (min-width: 992px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px;
  }

  .table-responsive {
    // overflow: visible;
  }
}

@media (max-width: 991px) {}

/* new add css */
.sidebar .logo-img {
  width: 30px;
  display: inline-block;
  max-height: 30px;
}

.sidebar .logo-img img {
  width: 40px;
  top: 6px;
  position: absolute;
  left: 0px;
}

.sidebar .sidebar-wrapper {
  overflow: auto;
  position: relative;
  overflow-y: scroll;
  z-index: 4;
  width: $sidebar_width;
  height: calc(100% - 64px);
  padding-bottom: 30px;
}

.sidebar .sidebar-wrapper>.nav {
  margin-top: 0px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.sidebar .sidebar-wrapper>.user~.nav {
  margin-top: 0;
}

.sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
  display: none !important;
}

.sidebar .sidebar-wrapper .nav li a,
.sidebar .sidebar-wrapper .user .info li a {
  outline: none;
  cursor: pointer;
}

.sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal,
.sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal {
  margin: 0;
  position: relative;
  -webkit-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  opacity: 1;
  white-space: nowrap;
  display: block;
}

.sidebar .sidebar-wrapper .nav ul>li>a .sidebar-mini,
.sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-mini {
  text-transform: uppercase;
  float: left;
  width: 30px;
  text-align: center;
  margin-right: 15px;
  letter-spacing: 1px;
}

.sidebar .sidebar-wrapper .nav ul>li>a i,
.sidebar .sidebar-wrapper .user .info ul>li>a i {
  font-size: 17px;
  line-height: 30px;
  width: 26px;
}

.sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.sidebar .logo {
  /*padding: 12px 0px;*/
  /* padding: 5px 18px; */
  margin: 0;
  padding: 0;
  -webkit-box-shadow: inset -1px 0px 0px 0px $box_shadow;
  box-shadow: inset -1px 0px 0px 0px $box_shadow;
  position: relative;
  z-index: 4;
  display: block;
}

.sidebar .logo a.logo-mini {
  float: left;
  width: 30px;
  text-align: center;
  margin-left: 23px;
  margin-right: 15px;
  position: relative;
}

.sidebar .logo a.logo-normal {
  display: block;
  opacity: 1;
  -webkit-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.sidebar .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  color: $white;
}

.sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  line-height: 20px;
}

.sidebar .logo:before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 15px;
  width: calc(100% - 30px);
  height: 1px;
}

.sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.sidebar .nav li>a {
  border-radius: 0 2px 2px 0px;
  color: $white;
  margin: 0 0 2px;
  padding-left: 18px;
  padding-right: 15px;
}

.sidebar .nav li:hover>a {
  color: $white;
  background: $sidebar_nav_highlight;
}

.sidebar .nav li.active>a {
  color: $white;
  background: $sidebar_nav_highlight;
}

.sidebar .nav li>a p {
  font-weight: 600;
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  white-space: nowrap;
  position: relative;
  color: $white;
  -webkit-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  display: block;
  height: auto;
  opacity: 1;
}

.sidebar .nav p .caret {
  top: 12px;
  position: absolute;
  right: 0px;
}

.sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.sidebar .logo-mini {
  display: none;
}

.sidebar .logo p,
.bootstrap-navbar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: $white;
  line-height: 20px;
  font-family: $font_family;
}

.sidebar .logo .logo-text,
.bootstrap-navbar .logo .logo-text {
  text-transform: uppercase;
  padding: 5px 0px 5px 15px;
  display: block;
  font-size: 18px;
  color: $white;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar .logo .logo-image,
.bootstrap-navbar .logo .logo-image {
  float: left;
  height: 40px;
  width: 40px;
  margin: 0 10px;
}

.sidebar .logo .logo-round,
.bootstrap-navbar .logo .logo-round {
  border-radius: 50%;
  display: block;
  overflow: hidden;
}

.sidebar .logo .logo-round img,
.bootstrap-navbar .logo .logo-round img {
  width: 100%;
}

.sidebar .logo:before,
.bootstrap-navbar .logo:before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.sidebar .logo-mini .logo-text,
.bootstrap-navbar .logo-mini .logo-text {
  text-transform: uppercase;
  padding: 7px 0px;
  display: block;
  font-size: 18px;
  color: $white;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar .user,
.bootstrap-navbar .user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
  margin-top: 20px;
}

.sidebar .user .photo,
.bootstrap-navbar .user .photo {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin-right: 11px;
  margin-left: 23px;
  z-index: 5;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.sidebar .user .photo img,
.bootstrap-navbar .user .photo img {
  width: 100%;
}

.sidebar .user a,
.bootstrap-navbar .user a {
  color: $white;
  padding: 8px 14px;
  display: block;
  white-space: nowrap;
}

.sidebar .user .info>a>span,
.bootstrap-navbar .user .info>a>span {
  opacity: 1;
  line-height: 20px;
  display: block;
  position: relative;
}

.sidebar .user .info .caret,
.bootstrap-navbar .user .info .caret {
  position: absolute;
  top: 11px;
  right: 15px;
}

.sidebar:after,
.sidebar:before,
.bootstrap-navbar:after,
.bootstrap-navbar:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar:before,
.bootstrap-navbar:before {
  opacity: 0.33;
  background: $black;
}

.sidebar:after,
.bootstrap-navbar:after {
  background: $gray_darker;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, $gray_darker),
      color-stop(100%, $gray_darkest));
  background: -webkit-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: -o-linear-gradient(top, $gray_darker 0%, $gray_darkest 100%);
  background: -webkit-gradient(linear, left top, left bottom, from($gray_darker), to($gray_darkest));
  background: linear-gradient(to bottom, $gray_darker 0%, $gray_darkest 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

/*.sidebar[data-image]:after,
  .sidebar.has-image:after,
  .bootstrap-navbar[data-image]:after,
  .bootstrap-navbar.has-image:after {
    opacity: .77;
  }*/

.sidebar[data-color="custom"]:after,
.bootstrap-navbar[data-color="custom"]:after {
  background: $sidebar_nav_background;
  background-size: 150% 150%;
}

.sidebar[data-color="blue"]:after,
.bootstrap-navbar[data-color="blue"]:after {
  background: $primary;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, $primary),
      color-stop(100%, rgba(0, 111, 230, 0.7)));
  background: -webkit-linear-gradient(top, $primary 0%, rgba(0, 111, 230, 0.7) 100%);
  background: -o-linear-gradient(top, $primary 0%, rgba(0, 111, 230, 0.7) 100%);
  background: -webkit-gradient(linear, left top, left bottom, from($primary), to(rgba(0, 111, 230, 0.7)));
  background: linear-gradient(to bottom, $primary 0%, rgba(0, 111, 230, 0.7) 100%);
  background-size: 150% 150%;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - #{$sidebar_width});
  min-height: 100%;
  background-color: $white;
}

.main-panel .main-content {
  /*padding: 30px 15px;*/
  background-color: $white;
  min-height: calc(100vh - 50px);

  .navbar-fixed-top .navbar.navbar-default .container-fluid {
    display: flex;
  }
}

.main-panel>.content-no-padding {
  padding: 0;
}

.main-panel>.footer {
  border-top: 1px solid $gray_lightest;
}

.main-panel .navbar {
  margin-bottom: 0;
}

.main-panel>.content-no-padding {
  padding: 0;
}

.main-panel>.footer {
  border-top: 1px solid $gray_lightest;
}

.main-panel .navbar {
  margin-bottom: 0;
}

.sidebar,
.main-panel,
.sidebar-wrapper,
.navbar-fixed {
  -webkit-transition-property: top, bottom, width;
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  -o-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

/*.sidebar,
  .main-panel {
    max-height: 100%;
    height: 100%;
  }*/

.visible-on-sidebar-regular {
  display: inline-block !important;
  width: 12px;
}

.visible-on-sidebar-mini {
  display: none !important;
  width: 12px;
}

.sidebar-mini .visible-on-sidebar-regular {
  display: none !important;
}

.sidebar-mini .visible-on-sidebar-mini {
  display: inline-block !important;
}

@media (min-width: 576px) {

  .sidebar-mini .sidebar,
  .sidebar-mini .sidebar .sidebar-wrapper {
    width: $mini_sidebar_width;
  }

  .sidebar-mini .main-panel {
    width: calc(100% - #{$mini_sidebar_width});
  }

  .sidebar-mini .sidebar {
    display: block;
    font-weight: 200;
    z-index: 3;
  }

  .sidebar-mini .sidebar .logo a.logo-mini {
    opacity: 1;
  }

  .sidebar-mini .sidebar .logo a.logo-normal {
    opacity: 0;
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  .sidebar-mini .sidebar .sidebar-wrapper>.nav ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal,
  .sidebar-mini .sidebar .sidebar-wrapper .user .info>a>span,
  .sidebar-mini .sidebar .sidebar-wrapper>.nav li>a p {
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }

  /*.sidebar-mini .sidebar:hover {
        width: 260px;
    }
    .sidebar-mini .sidebar:hover .logo a.logo-normal {
        opacity: 1;
        -webkit-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .sidebar-mini .sidebar:hover .sidebar-wrapper {
        width: 260px;
    }
    .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li > a p,
    .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav ul > li > a .sidebar-normal,
    .sidebar-mini .sidebar:hover .sidebar-wrapper .user .info ul > li > a .sidebar-normal,
    .sidebar-mini .sidebar:hover .sidebar-wrapper .user .info > a > span {
        -webkit-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
        opacity: 1;
    }
    .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li.active > a:not([data-toggle="collapse"]):before,
    .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li.active > a:not([data-toggle="collapse"]):after {
        opacity: 0;
    }*/
}

.animation-transition-fast,
.sidebar .nav .collapse a>span,
.sidebar .sidebar-wrapper>.nav p,
.sidebar .user .info>a>span,
.sidebar .logo a.logo-normal,
.sidebar .user .info .collapse .nav a>span {
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
}

.sidebar .nav a,
.table>tbody>tr .td-actions .btn,
.caret {
  -webkit-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.sidebar .dropdown .dropdown-menu {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  display: none;
}

.sidebar .dropdown.open .dropdown-menu {
  display: block;
}

/*.sidebar .nav i,
  .bootstrap-navbar .nav i,
  .off-canvas-sidebar .navbar-collapse .nav i {
    font-size: 22px;
  }*/

/*.sidebar .nav > li > a,
  .bootstrap-navbar .nav > li > a,
  .off-canvas-sidebar .navbar-collapse .nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
    color: $white !important;
  }*/

/*.sidebar .nav > li > a.dropdown-toggle,
  .bootstrap-navbar .nav > li > a.dropdown-toggle,
  .off-canvas-sidebar .navbar-collapse .nav > li > a.dropdown-toggle {
    background: transparent;
  }*/

.sidebar .navbar-form,
.bootstrap-navbar .navbar-form,
.off-canvas-sidebar .navbar-collapse .navbar-form {
  float: none !important;
  margin: 15px 0 0 0;
}

.sidebar .navbar-form .input-group,
.bootstrap-navbar .navbar-form .input-group,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group {
  width: 100%;
}

.sidebar .navbar-form .input-group .form-control,
.bootstrap-navbar .navbar-form .input-group .form-control,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group .form-control {
  background: rgba(255, 255, 255, 0.13);
  /* border-radius: 0 4px 4px 0; */
  /* padding-left: 0; */
  /* padding-right: 0; */
  /* color: $white; */
}

.sidebar .navbar-form .input-group .form-control:focus,
.bootstrap-navbar .navbar-form .input-group .form-control:focus,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group .form-control:focus {
  background: rgba(255, 255, 255, 0.23);
}

.sidebar .navbar-form .input-group-addon,
.sidebar .navbar-form .input-group-focus .input-group-addon,
.bootstrap-navbar .navbar-form .input-group-addon,
.bootstrap-navbar .navbar-form .input-group-focus .input-group-addon,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group-addon,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group-focus .input-group-addon {
  color: $white;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 4px 0 0 4px;
}

.sidebar .navbar-form .input-group-focus .input-group-addon,
.bootstrap-navbar .navbar-form .input-group-focus .input-group-addon,
.off-canvas-sidebar .navbar-collapse .navbar-form .input-group-focus .input-group-addon {
  background: rgba(255, 255, 255, 0.23);
}

.sidebar .nav-mobile-menu,
.bootstrap-navbar .nav-mobile-menu,
.off-canvas-sidebar .navbar-collapse .nav-mobile-menu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 15px;
  padding-bottom: 8px;
  padding-top: 5px;
}

.sidebar .nav-mobile-menu i,
.bootstrap-navbar .nav-mobile-menu i,
.off-canvas-sidebar .navbar-collapse .nav-mobile-menu i {
  font-size: 18px;
}

.sidebar .nav-mobile-menu .notification,
.bootstrap-navbar .nav-mobile-menu .notification,
.off-canvas-sidebar .navbar-collapse .nav-mobile-menu .notification {
  float: left;
  line-height: 30px;
  margin-right: 8px;
  font-weight: 600;
}

.sidebar .nav-mobile-menu .open .dropdown-menu,
.bootstrap-navbar .nav-mobile-menu .open .dropdown-menu,
.off-canvas-sidebar .navbar-collapse .nav-mobile-menu .open .dropdown-menu {
  position: static;
  float: none;
  width: auto;
  margin-top: 0;
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sidebar .nav-mobile-menu .dropdown-menu li a,
.bootstrap-navbar .nav-mobile-menu .dropdown-menu li a,
.off-canvas-sidebar .navbar-collapse .nav-mobile-menu .dropdown-menu li a {
  padding-left: 55px;
}

/*.sidebar .nav-mobile-menu .dropdown-with-icons li a,
  .bootstrap-navbar .nav-mobile-menu .dropdown-with-icons li a,
  .off-canvas-sidebar .navbar-collapse .nav-mobile-menu .dropdown-with-icons li a {
    padding-left: 15px;
  }
  .sidebar .dropdown-with-icons > li > a,
  .bootstrap-navbar .dropdown-with-icons > li > a,
  .off-canvas-sidebar .navbar-collapse .dropdown-with-icons > li > a {
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .sidebar .dropdown-with-icons .dropdown-menu i[class^="pe-"],
  .bootstrap-navbar .dropdown-with-icons .dropdown-menu i[class^="pe-"],
  .off-canvas-sidebar .navbar-collapse .dropdown-with-icons .dropdown-menu i[class^="pe-"] {
    width: 30px;
  }*/

.sidebar .dropdown-menu:after,
.sidebar .dropdown-menu:before,
.bootstrap-navbar .dropdown-menu:after,
.bootstrap-navbar .dropdown-menu:before,
.off-canvas-sidebar .navbar-collapse .dropdown-menu:after,
.off-canvas-sidebar .navbar-collapse .dropdown-menu:before {
  display: none;
}

.sidebar .sidebar-wrapper {
  padding-bottom: 14px;
  padding-top: 14px;
}

.sidebar .sidebar-wrapper .dropdown-menu>li:first-child>a,
.sidebar .sidebar-wrapper .dropdown-menu>li:last-child>a {
  border-radius: 4px;
}

.sidebar .sidebar-wrapper .dropdown-menu>li>a:hover,
.sidebar .sidebar-wrapper .dropdown-menu>li>a:focus {
  color: $white;
}

.sidebar .sidebar-wrapper>.nav>div .nav {
  margin-top: 10px;
  float: none !important;
}

.sidebar .sidebar-wrapper>.nav>div .nav>li>a {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 5px 15px;
}

.sidebar .sidebar-wrapper>.nav>div .notification {
  float: left;
  line-height: 30px;
  margin-right: 8px;
  font-weight: 600;
}

.card .content {
  padding: 15px;
}

.card .content .table-responsive {
  padding: 15px;
}

.card .content-no-padding {
  padding: 0;
}

.card .content-no-padding .map {
  margin-top: 15px;
  border-radius: 0;
}

.card .content-full-width {
  padding: 15px 0;
}

.card-user .content {
  min-height: 240px;
}

.card-calendar .content {
  padding: 0px;
  height: 633px;
}

.content-full-width .panel-heading a {
  padding: 10px 15px;
}

.wrapper.wrapper-full-page {
  height: auto;
}

.nav-open .wrapper-full-page {
  left: 0;
  -webkit-transform: translate3d(-260px, 0, 0);
  -ms-transform: translate3d(-260px, 0, 0);
  transform: translate3d(-260px, 0, 0);
}

.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  z-index: 1030;
}

/* End Old Css */

/* Start breadcrumb Style */
.breadcrumb {
  padding: 0px;
  margin-bottom: 0px;
  list-style: none;
  border-radius: none;
  background: transparent;
}

.header-breadcrumb {
  margin: 0;
  font-weight: 400;
  line-height: 23px;
  color: $gray_darker;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: $gray_lighter;
  content: "/\A0";
}

.breadcrumb>.active {
  color: $gray;
}

.breadcrumb li,
.breadcrumb a {
  font-size: 12px;
}

.custom-breadcrumb li {
  list-style: outside none none;
}

/* End breadcrumb Style */

/* Start Updated Css */

/* Start Sidebar */
.sidebar-logo p {
  color: $white !important;
  font-family: Roboto !important;
  font-size: 15px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  line-height: normal !important;
  margin: 0 !important;
  float: none !important;
}

/* .sidebar-mini .sidebar-logo img {
    margin-bottom: 15px;
  } */

.sidebar-mini .logo {
  padding: 0px;
}

.sidebar-mini .sidebar-logo {
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
}

.sidebar .nav i img {
  width: 14px;
  margin: -2px 0 0 0;
}

.sidebar .nav i {
  margin: 0 6px 0 0;
}

/* .sidebar-mini .navbar-minimize .btn {
    padding: 0px;
  } */

#main-panel nav.navbar-default {
  padding: 11px 0px 0px 15px;
  min-height: 66px;
}

li.new-btn .btn,
li.new-btn .btn:hover {
  font-weight: 400;
  height: 34px;
  background: $white !important;
  color: $primary !important;
  border: 1px solid $primary !important;
  line-height: 23px;
  min-width: 76px;
  min-height: auto;
  text-transform: uppercase;
  // border-radius: 50px;
  font-weight: 500;
}

li.new-btn .btn .fa {
  // background: $primary;
  color: $primary;
  font-size: 15px;
  width: 18px;
  height: 18px;
  line-height: 19px;
  // border-radius: 50px;
  margin: 0px 2px 0px 0px;
  vertical-align: text-bottom;
}

/* .navbar-minimize .btn { */
/* border: 1px solid $gray_lighter !important; */
/* border-radius: 3px !important; */
/* padding: 0 9px 1px 4px; */
/* } */

/* End Sidebar Css */

/* Start Label Css */
td .label {
  border-radius: 50px;
  font-size: 12px;
  /* padding: 6px 17px; */
  padding: 4px 12px;
}

td .label-default {
  background: none repeat scroll 0 0 $default_label_bg_color;
  color: $white;
}

td .label-success {
  background: none repeat scroll 0 0 $success_label_bg_color;
  color: $white;
}

td .label-info {
  background: none repeat scroll 0 0 $info_label_bg_color;
  color: $white;
}

td .label-danger {
  background: none repeat scroll 0 0 $danger_label_bg_color;
  color: $white;
}

td .label-warning {
  background: none repeat scroll 0 0 $warning_label_bg_color;
  color: $white;
}

/* End Label Css */

/* Start Tabel Css */

.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  font-size: 14px;
  font-weight: normal;
  padding: 19px 5px;
  color: $table_data_color;
  vertical-align: middle;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th {
  border-bottom: 1px solid $secondary_lighter;
  padding: 9px 5px;
  vertical-align: middle;
  font-weight: 500;
  border-top: medium none;
  color: $gray_dark;
  font-size: 14px;
  text-transform: uppercase;
}

.table>thead>tr>th>a,
.table>tbody>tr>th>a,
.table>tfoot>tr>th>a {
  color: $gray;
  font-size: 14px;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th {
  border-top: 1px solid $secondary_lighter;
}

/* .table td a {
    color: $gray_darkest;
  } */

/* End Tabel Css */

.displaying-total-and-page-info-count .form-control {
  border: none;
  display: inline-block;
  height: auto;
  margin: 0 5px;
  padding: 0px;
  width: 45px !important;
  border-bottom: 1px solid $gray_lighter;
  border-radius: 0px;
}

.displaying-total-and-page-info-count .form-control:focus,
.displaying-total-and-page-info-count .form-control:active {
  background-color: transparent;
  // color: $secondary_darker;
  border-bottom: 1px solid $gray;
  outline: none;
}

.displaying-total-and-page-info-count {
  color: $gray_darker;
  margin: 0px;
}

.navbar-fixed-top .listing-page-info .text-right {
  margin-top: -3px;
}

/* .custom-card-header-title {
    position: absolute;
    z-index: 9;
  }
  
  .sidebar-mini .custom-card-header-title {
    left: 70px;
  }
  
  .custom-card-header-title h4 .breadcrumb {
    font-size: 14px;
    vertical-align: text-top;
  }
  
  .custom-card-header-title h4 {
    color: #6e7379;
    font-weight: 500;
    margin: 0;
  } */

tr .please-wait-table-spinner {
  font-size: 14px !important;
}

.sidebar .sidebar-logo-main .mainly {
  padding: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  display: flex;
}

.sidebar .sidebar-logo-main .mainly .logo-parent {
  padding: 0 0 0 0;
}

.sidebar-mini .sidebar-logo-main .sidebar-logo {
  text-align: center;
  margin-left: 0;
}

a.sidebar-logo {
  display: flex;
  margin: 20px 0 5px 18px;

  img {
    height: 33px;
  }

  .sidenav-brand-title {
    display: flex;
    align-items: center;
  }
}

a.sidebar-logo img {
  width: 33px;
  margin: 0 3px 3px 0;
}

.sidebar-logo-main .sidenav-toggle-sidebar-button-parent-div {
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-logo-main .sidenav-toggle-sidebar-button-parent-div button {
  padding-left: 0px;
  margin-left: -15px;
}

.sidebar-mini .sidebar-logo-main .sidenav-toggle-sidebar-button-parent-div {
  display: none;
  padding: 0px;
}

#sidebar,
.sidebar-wrapper {
  transition: auto;
}

.sidenav-brand-title {
  padding-left: 5px;
  font-size: 15px;
  color: $white;
}

.sidebar-mini .sidenav-brand-title {
  display: none !important;
  padding: 0;
}

.master-checkbox {
  font-size: 14px !important;
}

th.master-checkbox-th {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/* select::-ms-expand {
    display: none;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */

.navbar-header {
  padding: 0 15px;
}

.stock-inventory-header-section .control-label {
  text-transform: uppercase;
  color: $secondary_darker !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.navbar-collapse .navbar-nav {
  margin: 0px !important;
  padding-top: 5px;
}

.navbar-collapse .navbar-nav .navbar-form {
  padding: 0px !important;
  margin: 0px !important;
}

.advance-search-nav {
  padding-top: 0 !important;
}

.navbar-default .navbar-nav>li>a {
  color: $primary;
}

.navbar-default .navbar-nav>li>a:hover {
  color: $white;
}

.navbar-header {
  padding-left: 0px;
}

.sidebar-mini .navbar-header {
  padding: 0 15px;
}

.sidebar-mini .navbar-minimize #minimizeSidebar {
  padding-left: 0px;
}

.navbar-fixed-top {
  left: $sidebar_width !important;
  z-index: 9;

  // .logout-nav {
  // img {
  //   width: 34px;
  //   height: 34px;
  // }
  // }
}

.sidebar-mini .navbar-fixed-top {
  left: 86px !important;
}

.ReactModal__Body--open .navbar-fixed-top {
  z-index: 0 !important;
}

.login-main-container.main-content {
  .navbar-fixed-top {
    left: 0 !important;
    z-index: 9;

    .logout-nav {
      img {
        width: 34px;
        height: 34px;
      }
    }
  }

  .sidebar-mini .navbar-fixed-top {
    left: 86px !important;
  }

  .ReactModal__Body--open .navbar-fixed-top {
    z-index: 0 !important;
  }
}

.index-listing-container-fluid {
  padding-top: 115px;
}

.show-page-container-fluid,
.form-container-fluid {
  padding-top: 99px;
  padding-right: 30px;
  padding-left: 30px;
}

.reports-container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 109px;
}

.reports-container-fluid .table-card {
  padding-bottom: 44px;
}

.navbar-fixed-top .listing-page-info {
  padding: 10px 15px;
  background-color: $white;
}

.navbar-fixed-top {
  border-bottom: 1px solid $secondary_lighter;
}

.card .header div {
  padding: 0px;
}

.header-search-buttons button {
  font-size: 14px !important;
  // padding: 2px 10px 5px 10px !important;
}

.header-search-buttons .btn-primary {
  border-radius: 50px !important;
  padding: 0 19px !important;
  border: none;
  margin-left: -16px !important;
  height: 34px !important;
  position: relative;
  z-index: 9 !important;
}

.normal-search-form .form-control {
  border-radius: 50px 0 0 50px;
  height: 34px !important;
}

.header-search-buttons #advance-serach-button {
  margin-left: 4px;
  border: none;
  color: $primary;
  padding: 5px;
}

.header-search-buttons #clear-searched-filter-btn {
  border: none;
  color: $primary;
  padding: 5px;
}

.header-search-buttons .number-of-advance-search-applied-count {
  margin-left: 2px;
  background-color: $primary;
}

.header-search-buttons .btn {
  min-height: auto;
}

.navbar .navbar-nav {
  padding: 5px;
}

.listing-page-header-action-nav {
  display: flex;
  justify-content: left;
}

.listing-page-header-action-nav ul.dropdown-menu {
  right: 0;
  left: auto;
}

.listing-page-header-search-form-nav .navbar-form .input-group-btn {
  width: 1% !important;
}

.listing-page-header-action-btns {
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  justify-content: flex-end;
}

.show-page-header-action-nav,
.show-page-header-custome-action-nav {
  display: flex;
  justify-content: flex-end;
}

.listing-page-info .btn,
.card-header-actions .btn {
  margin-right: 2px;
  min-height: auto;
  line-height: 19px;
}

/* End Updated Css */

.table-full-width {
  position: relative;
  padding-top: 35px !important;
  table-layout: fixed;
  padding-left: 30px !important;
  padding-right: 30px !important;

  thead {
    position: fixed;
    left: 260px;
    display: table;
    table-layout: fixed;
    width: calc(100% - 290px);
    background: $white;
    top: 112px;
    z-index: 8;
    right: 0;
    border-bottom: 1px solid rgba(65, 77, 91, 0.1);
  }

  .table {
    width: 100%;
    table-layout: fixed;
    position: relative;
  }
}

.bulk-action-body.table-full-width tfoot {
  position: fixed;
  left: 260px;
  display: table;
  table-layout: fixed;
  width: calc(100% - 290px);
  background: $white;
  bottom: 66px;
  z-index: 8;
  right: 0;
  border-bottom: 1px solid rgba(65, 77, 91, 0.1);
}

.bulk-action-body.table-full-width tfoot .tfoot-first-child {
  width: 36px;
}

.cheque-realization-tfoot {
  bottom: 24px !important;
}

.bulk-action-body.table-full-width tfoot td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-full-width thead tr,
.table-full-width thead th {
  border: none !important;
  box-shadow: none;
}

body.off-canvas-sidebar.sidebar-mini .table-full-width thead {
  left: 113px;
  width: 89.1%;
}

body.off-canvas-sidebar.sidebar-mini .bulk-action-body.table-full-width tfoot {
  left: 113px;
  width: 89.1%;
}

.panel-default>.panel-heading {
  background-color: $white;
}

.pop-main ::-webkit-scrollbar {
  width: 4px;
}

.pop-right-body {
  height: 74vh;
  overflow-y: scroll;

  padding-top: 10px;
}

.pop-left th {
  padding-top: 0px !important;
}

.pop-left tbody.table-header-group {
  height: 74vh;
  overflow-y: scroll;
  display: block;
  table-layout: fixed;
}

.pop-left table.table {
  margin: 0px;
}

.pop-left tr {
  width: 100% !important;
  table-layout: fixed !important;
  display: table;
}

.pop-left tbody.table-header-group td {
  padding: 11px 5px;
}

.pop-right hr {
  border-top: 1px solid $border_color;
  margin-bottom: 0px !important;
}

.pop-left tbody.table-header-group tr:first-child td {
  border-top: 0px !important;
}

.close-pop {
  font-size: 23px;
  width: 20px;
  height: 25px;
  color: $gray_light;
  padding: 0px 0 0 0;
  margin: 0 0 0 4px;
  line-height: 25px;
  cursor: pointer;
}

.arrow-btn {
  font-size: 23px;
  padding: 0;
  float: right;
}

.pop-main {
  margin: -11px 0 -49px 0;
}

.pop-right-header h5 {
  color: $gray_lighter;
  font-size: 18px;
  font-weight: 500;
  margin: 3px 0 5px 0;
}

.pop-right-body .form-group-output-main {
  padding: 0 15px;
}

.pop-left .pagination {
  margin-bottom: 0px;
  margin-top: -33px;
}

.pop-left tbody.table-header-group tr:last-child {
  margin-bottom: 30px;
}

.pop-left tbody td:last-child:after {
  content: "\f105";
  position: absolute;
  right: 10px;
  font-family: fontawesome;
  font-size: 18px;
  top: 30%;
  color: $gray_lighter;
}

.pop-left tbody td:last-child {
  position: relative;
}

i.fa.fa-times {
  -webkit-text-stroke: 3px $white;
}

.fixed-action-footer {
  position: fixed;
  bottom: 0;
  left: calc((100% - (100% - #{$sidebar_width})) + 15px) !important;
  width: calc((100% - #{$sidebar_width}) - 30px) !important;
  background: $white;
  margin-bottom: 0 !important;
  padding-bottom: 5px;
  z-index: 8;
}

.fixed-action-footer-for-min-sidebar {
  position: fixed;
  bottom: 0;
  left: calc((100% - (100% - #{$mini_sidebar_width})) + 15px) !important;
  width: calc((100% - #{$mini_sidebar_width}) - 30px) !important;
  background: $white;
  margin-bottom: 0 !important;
  padding-bottom: 5px;
}

.fixed-action-footer-for-min-sidebar .form-footer-btn,
.fixed-action-footer .form-footer-btn {
  padding: 10px 0px 0px 0px;
}

.feed-container-fluid {
  padding-top: 99px;
  padding-right: 30px;
  padding-left: 30px;
}

.content-wrapper {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bulk-action-body {
  height: calc(100vh - 190px);
  padding: 15px 30px;

  #generic-table {
    margin-bottom: 40px !important;
  }
}

.bulk-action-body.table-full-width thead {
  top: 130px !important;
}

.bulk-action-left {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 232px);
}

.bulk-action-right {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
}

.report-page-info {
  float: left;
}

.report-page-info p {
  font-size: 14px;
  color: $secondary;
  margin: 0 0 2px 0;
}

.report-page-info h6 {
  font-size: 18px;
  color: $secondary_darkest;
  margin: 0px;
  padding: 0px;
  min-height: 30px;
  font-weight: 400;
}

.report-page-info .Select-control,
.report-page-info .Select-input {
  height: 30px;
  cursor: pointer;
}

.report-page-info .Select {
  width: 204px;
  margin: 0px 0 0 0;
  z-index: 9;
}

.report-page-info .dropdown {
  list-style: none;
  line-height: 28px;
}

.report-page-info .dropdown-menu {
  width: auto;
  min-width: 250px !important;
}

.report-page-info .dropdown .dropdown-toggle {
  font-size: 23px;
  font-weight: 400;
  border-bottom: 1px solid $white;
  color: $secondary_darkest;
  position: relative;
  padding-right: 15px;
  overflow: hidden;
  display: table-cell;
}

.report-page-info .dropdown .dropdown-toggle:hover,
.report-page-info .dropdown.open .dropdown-toggle {
  border-bottom: 1px solid $gray_lighter;
}

.report-page-info .dropdown .dropdown-toggle i {
  font-size: 10px;
  position: absolute;
  right: 0;
  top: 9px;
  width: auto;
}

.report-page-info .dropdown-menu li:last-child {
  border: none !important;
  margin: 0px !important;
}

#report-variant-dropdown,
#render-dropdown-selection {
  width: max-content;
}

.report-page-info .Select-value {
  line-height: 31px !important;
}

#bar-chart .Select {
  width: 300px;
  float: right;
  margin: 10px 30px 20px 0px;
}

.bar-chart-block {
  border-bottom: 1px solid $gray_lighter;
  padding: 20px;
}

.bar-chart-block.text-center .recharts-responsive-container {
  margin: auto;
}

.bar-chart-block .recharts-wrapper {
  margin: auto;
}

.reports-container-fluid .fixed-pagination {
  margin-bottom: 4px;
}

.top-bar {
  background: none repeat scroll 0 0 $white;
  left: 230px;
  padding: 0px 30px 8px 30px;
  right: 0;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid $gray_lighter;
}

.sidebar-mini .top-bar {
  left: 84px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.top-bar .navbar-fixed-top {
  position: unset;
  border: none;
}

.top-bar .navbar-fixed-top .navbar {
  padding: 0px !important;
  min-height: unset !important;
}

.top-bar .navbar-fixed-top .navbar .container-fluid {
  padding: 0px;
}

.top-bar .logout-nav {
  padding-right: 0px !important;
}

.reports-advance-filters-header h3 .pull-right {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.mb-0 {
  margin-bottom: 0px;
}

.top-report-options-dropdown .Select-control,
.top-report-options-dropdown .Select-input {
  height: 30px;
}

.top-report-options-dropdown .Select-placeholder {
  line-height: 30px;
}

.top-report-options-dropdown [class*="col-"] {
  padding: 0 4px;
}

.without-label {
  padding-top: 22px !important;
}

.date-picker-to {
  width: 27px !important;
  line-height: 27px;
}

.top-report-options-dropdown .btn {
  height: 32px;
  line-height: 12px;
}

.top-report-options-dropdown {
  padding: 8px 26px 0 26px;
  margin: 5px -15px 0px -15px;
  z-index: 99999;
  position: relative;
}

.top-report-options-dropdown .Select--single .Select-value-label {
  line-height: 28px;
}

.top-report-options-dropdown [class*="col-"]:first-child {
  padding-left: 2px;
}

.top-report-options-dropdown .Select--multi .Select-value-label {
  line-height: 15px;
  white-space: nowrap;
}

.top-report-options-dropdown .Select--multi .Select-value {
  margin: 0 0 0 5px;
  height: 23px;
}

.top-report-options-dropdown .has-value.Select--multi .Select-multi-value-wrapper {
  display: flex;
  align-items: center;
  max-width: 240px;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: overlay;
}

.d-flex {
  display: flex;
}

.col-md-custom {
  min-width: 175px;
}

.top-report-options-dropdown .form-control {
  height: 32px;
}

.top-report-options-dropdown .form-group {
  margin: 0px;
}

.top-report-options-dropdown .report-table-action-main p {
  padding: 6px 0 0 0;
}

.top-bar .navbar-fixed-top {
  position: fixed !important;
  padding: 11px 30px 0 30px;
  background: $white;
  height: 62px;
  z-index: 9999999;
  transition-delay: 1s;
  /* delays for 1 second */
  -webkit-transition-delay: 1s;
  /* for Safari & Chrome */
}

.notifications-wrapper,
.notifications-tr {
  z-index: 999999999999999999 !important;
}

.active-top-report-options-dropdown {
  padding-top: 62px;
  transition-delay: 1s;
  /* delays for 1 second */
  -webkit-transition-delay: 1s;
  /* for Safari & Chrome */
}

.active-top-report-options-dropdown .table-header-group {
  //height: calc(100vh - 300px) !important;
}

.active-top-report-options-dropdown .reports-advance-filters-body {
  height: calc(100vh - 293px) !important;
}

.active-top-report-options-dropdown #reports-tabs {
  top: 176px;
}

.table-bottom thead {
  visibility: hidden;
}

.text-column-50 {
  width: 50px;
}

.text-column-100 {
  width: 100px;
}

.text-column-150 {
  width: 150px;
}

.text-column-200 {
  width: 200px;
}

.number-column-50 {
  width: 50px;
}

.number-column-100 {
  width: 100px;
}

.number-column-200 {
  width: 200px;
}

.top-report-options-dropdown .react-bootstrap-daterangepicker-container {
  border: 1px solid $border_color;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top-report-options-dropdown .react-bootstrap-daterangepicker-container:after {
  border-color: $border-color transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  right: 12px;
  top: 11px;
  width: 0;
  content: "";
  position: absolute;
}

.top-report-options-dropdown .react-bootstrap-daterangepicker-container:focus,
.top-report-options-dropdown .react-bootstrap-daterangepicker-container:hover {
  border: 1px solid #7700ef;
}

.ranges {
  height: 291px;
  overflow: scroll;
}

.report-table-action-main p {
  padding: 4px 0 0 0;
  margin: 0px;
  color: $secondary_darkest;
  font-size: 14px;
}

.action-btn-round {
  background: none repeat scroll 0 0 lighten($gray_lighter, 10%);
  border-radius: 50px;
  border: 0;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  color: $primary_light;
  cursor: pointer;
  padding: 8px 0;
  margin: 0 0 0 8px;
  position: relative;

  line-height: 10px;

  sup {
    position: initial;
  }
}

.action-btn-round:hover,
.action-btn-round:focus,
.action-btn-round.active {
  background: none repeat scroll 0 0 $primary_light;
  color: $white;
}

.action-btn-round .badge {
  position: absolute;
  top: -11px;
  right: -11px;
  background: $primary_light;
  color: $white;
  padding: 0;
  min-width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
}

.action-btn-round:hover .badge,
.action-btn-round:focus .badge,
.action-btn-round.active .badge {
  background: $gray_lightest;
  color: $primary_light;
}

.reports-container-fluid th,
.reports-container-fluid td {
  border-left: 1px solid $gray_lighter;
  border-right: 1px solid $gray_lighter;
}

.reports-container-fluid .table-card-body {
  overflow: scroll;
  padding: 0px !important;
}

.reports-container-fluid .fixed-pagination.pagination {
  z-index: 3;
  left: calc(50% + 115px);
}

.reports-container-fluid.toggled .table-card-body {
  margin-right: 268px !important;
}

.reports-container-fluid .table-responsive {
  position: relative;
  margin: auto;
  width: 100%;
}

.table-mid {
  height: calc(100vh - 120px) !important;
  display: block;
  overflow: auto;
  position: relative;
  z-index: 1;
  width: 100%;
  background: $white;
}

.table-mid-auto-height table {
  border-bottom: 1px solid $gray_lighter;
}

.table-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: scroll;
  bottom: auto;
}

.table-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  top: auto;
  border-bottom: 1px solid $gray_lighter;
}

.table-top th,
.table-top thead,
.table-bottom tfoot td,
.table-bottom tfoot {
  position: relative;
  background: $white;
  z-index: 2;
}

.table-top tbday,
.table-top tfoot,
.table-mid thead,
.table-top tbody,
.table-bottom thead,
.table-bottom tbody {
  visibility: hidden;
}

.reports-container-fluid th,
.reports-container-fluid td {
  padding: 10px 5px !important;
}

.reports-container-fluid tbody.table-header-group {
  //height: calc(100vh - 240px);
  //overflow-y: overlay;
  //display: block;
  //table-layout: fixed;
}

.reports-container-fluid table.table {
  margin: 0px;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.reports-container-fluid table,
.reports-container-fluid table tr {
  width: min-content;
}

.reports-container-fluid tr td {
  //padding: 9px 5px !important;
}

.reports-container-fluid .table-card {
  padding-left: 30px !important;
}

.reports-container-fluid tr td:last-child {
  padding-right: 19px !important;
}

.reports-container-fluid th:last-child {
  padding-right: 19px !important;
}

.slow-fixed-top #reports-tabs {
  position: fixed !important;
  top: 62px !important;
}

.slow-fixed-top .top-bar .navbar-fixed-top {
  border-bottom: 1px solid $gray_lighter;
}

.report-table-action-main {
  margin: 8px 0 0 0;
}

.reports-container-fluid th div {
  color: $secondary_darkest;
}

#reports-tabs {
  background: none repeat scroll 0 0 $white;
  border-left: 1px solid $border-color;
  position: absolute !important;
  right: -268px;
  top: 110px;
  height: 100vh;
  width: 268px;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.reports-container-fluid.toggled #reports-tabs {
  width: 268px;
  right: 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.reports-container-fluid.toggled .content-wrapper {
  padding-right: 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#reports-tabs .fade {
  opacity: unset;
}

#reports-tabs .nav-tabs {
  margin: 0px;
  padding: 0 19px;
}

.Select--multi .Select-value {
  position: relative;
  padding-left: 18px;
}

.Select--multi .Select-value-icon {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
}

.Select--multi .Select-value-label {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.top-report-options-dropdown .Select--single>.Select-control .Select-value {
  top: -5px !important;
}

.reports-advance-filters-body select:focus {
  border-color: $primary;
  box-shadow: none;
}

.reports-advance-filters-body .form-group {
  margin-bottom: 23px;
}

.reports-advance-filters-body {
  height: calc(100vh - 233px);
  overflow: auto;
  padding: 19px;
}

#reports-tabs-pane-settings .reports-advance-filters-body {
  height: calc(100vh - 168px);
  padding-bottom: 0px;
}

.reports-advance-filters-body .col-md-12:last-child .form-group {
  margin-bottom: 0px !important;
}

.reports-advance-filters-body .col-md-12:first-child .control-label {
  padding-top: 0px;
}

.reports-advance-filters-price label {
  color: $gray_darkest;
}

.reports-advance-filters-header {
  background: $gray_lightest !important;
  border-bottom: 1px solid $border-color;
}

.reports-advance-filters-header .nav-tabs .nav-item.show .nav-link,
.reports-advance-filters-header .nav-tabs .nav-link.active {
  color: $secondary_darkest;
  background-color: #f9f9fc !important;
  border-color: #dee2e6 #dee2e6 $white;
}

.reports-advance-filters-header .nav-link {
  padding: 15px 19px;
  border: none !important;
  font-weight: 500;
}

.reports-advance-filters-header ul.nav.nav-tabs {
  border: none;
}

.reports-advance-filters-header li.nav-item {
  border-right: 1px solid $border-color;
  width: 50%;
  text-align: center;
}

.reports-advance-filters-header li.nav-item:last-child {
  border: none;
}

.reports-advance-filters-footer {
  border-top: 1px solid $border-color;
  padding: 10px 5px;
  overflow: hidden;
}

.reports-advance-filters-footer .col-md-6 {
  padding: 0 5px;
}

.reports-advance-filters-footer .btn {
  padding: 8px 2px;
}

.reports-invoice-date-form {
  position: relative;
}

.reports-invoice-date-form .fa {
  color: $primary;
  margin: 10px 0 0 13px;
  position: absolute;
}

.reports-invoice-date-form input {
  padding-left: 33px;
}

.text-link {
  cursor: pointer;
  color: $primary;
}

@mixin col-half($number) {
  width: (8.33333333% * $number) + 4.16666665% !important;
  float: left;
  position: relative;
  min-height: 1px;
}

.col-md-half {
  width: 4.16666665%;
  float: left;
  position: relative;
  min-height: 1px;
}

.col-md-1-half {
  @include col-half(1);
}

.col-md-2-half {
  @include col-half(2);
}

.col-md-3-half {
  @include col-half(3);
}

.col-md-4-half {
  @include col-half(4);
}

.col-md-5-half {
  @include col-half(5);
}

.col-md-6-half {
  @include col-half(6);
}

.col-md-7-half {
  @include col-half(7);
}

.col-md-8-half {
  @include col-half(8);
}

.col-md-9-half {
  @include col-half(9);
}

.col-md-10-half {
  @include col-half(10);
}

.col-md-11-half {
  @include col-half(11);
}

.inner-td {
  position: relative;
  padding: 0 0 0 20px !important;
}

.inner-td:before {
  content: "";
  height: 2px;
  width: 20px;
  background: $white;
  position: absolute;
  top: -1px;
  left: 0;
}

/* for desktop view */

@media screen and (max-width: 1200px) {

  /* 
  body{
   width: 1200px;
   overflow-x: scroll;
  }
  .container-fluid, #main-wrapper, .main-content, .navbar-fixed-top, .table-full-width thead, .table-card, .table-header-group{
    width: 1200px;
  }
  */
  .reports-container-fluid .fixed-pagination {
    width: fit-content;
  }

  .active-top-report-options-dropdown.reports-container-fluid.toggled.container-fluid {
    padding-right: 268px;
  }

  .table-responsive {
    overflow-x: scroll;
  }

  .top-bar .container-fluid,
  .top-bar .navbar-fixed-top {
    width: 100% !important;
  }

  .navbar-toggle {
    display: none;
  }

  .container>.navbar-header,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container-fluid>.navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .table-responsive {
    margin-bottom: 0px;
    border: none;
  }
}

.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
  float: left;
}

.col-md-12 {
  width: 100%;
}

.col-md-11 {
  width: 91.66666667%;
}

.col-md-10 {
  width: 83.33333333%;
}

.col-md-9 {
  width: 75%;
}

.col-md-8 {
  width: 66.66666667%;
}

.col-md-7 {
  width: 58.33333333%;
}

.col-md-6 {
  width: 50%;
}

.col-md-5 {
  width: 41.66666667%;
}

.col-md-4 {
  width: 33.33333333%;
}

.col-md-3 {
  width: 25%;
}

.col-md-2 {
  width: 16.66666667%;
}

.col-md-1 {
  width: 8.33333333%;
}

.col-md-pull-12 {
  right: 100%;
}

.col-md-pull-11 {
  right: 91.66666667%;
}

.col-md-pull-10 {
  right: 83.33333333%;
}

.col-md-pull-9 {
  right: 75%;
}

.col-md-pull-8 {
  right: 66.66666667%;
}

.col-md-pull-7 {
  right: 58.33333333%;
}

.col-md-pull-6 {
  right: 50%;
}

.col-md-pull-5 {
  right: 41.66666667%;
}

.col-md-pull-4 {
  right: 33.33333333%;
}

.col-md-pull-3 {
  right: 25%;
}

.col-md-pull-2 {
  right: 16.66666667%;
}

.col-md-pull-1 {
  right: 8.33333333%;
}

.col-md-pull-0 {
  right: auto;
}

.col-md-push-12 {
  left: 100%;
}

.col-md-push-11 {
  left: 91.66666667%;
}

.col-md-push-10 {
  left: 83.33333333%;
}

.col-md-push-9 {
  left: 75%;
}

.col-md-push-8 {
  left: 66.66666667%;
}

.col-md-push-7 {
  left: 58.33333333%;
}

.col-md-push-6 {
  left: 50%;
}

.col-md-push-5 {
  left: 41.66666667%;
}

.col-md-push-4 {
  left: 33.33333333%;
}

.col-md-push-3 {
  left: 25%;
}

.col-md-push-2 {
  left: 16.66666667%;
}

.col-md-push-1 {
  left: 8.33333333%;
}

.col-md-push-0 {
  left: auto;
}

.col-md-offset-12 {
  margin-left: 100%;
}

.col-md-offset-11 {
  margin-left: 91.66666667%;
}

.col-md-offset-10 {
  margin-left: 83.33333333%;
}

.col-md-offset-9 {
  margin-left: 75%;
}

.col-md-offset-8 {
  margin-left: 66.66666667%;
}

.col-md-offset-7 {
  margin-left: 58.33333333%;
}

.col-md-offset-6 {
  margin-left: 50%;
}

.col-md-offset-5 {
  margin-left: 41.66666667%;
}

.col-md-offset-4 {
  margin-left: 33.33333333%;
}

.col-md-offset-3 {
  margin-left: 25%;
}

.col-md-offset-2 {
  margin-left: 16.66666667%;
}

.col-md-offset-1 {
  margin-left: 8.33333333%;
}

.col-md-offset-0 {
  margin-left: 0%;
}

#header-title {
  font-size: 22px;
}

%wrap-words {
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  /* css-3 */
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap !important;
  /* Opera 4-6 */
  white-space: -o-pre-wrap !important;
  /* Opera 7 */
  word-wrap: break-word !important;
  /* Internet Explorer 5.5+ */
}

table {
  table-layout: fixed !important;
  @extend %wrap-words;

  td {
    @extend %wrap-words;
  }
}

// CSS changes required for decoupled app
%full-height-of-parent {
  height: 100% !important;
}

%full-view-height {
  height: 100vh !important;
}

#root {
  @extend %full-view-height;
}

.login-main,
.landing-page-main {
  @extend %full-height-of-parent;

  .login-main-container {
    @extend %full-height-of-parent;

    .login-main-row {
      @extend %full-height-of-parent;

      .login-main-left {
        @extend %full-height-of-parent;
      }

      .login-main-right {
        @extend %full-height-of-parent;
      }
    }
  }
}

// Hide brand name that is to be shown only during printing of the page
.brand-name {
  display: none;
}

.print-separator {
  display: none;
}

// Hide bulk payment options screen details that are to be shown only during printing
// Screen URL - /payment_bulk_options

.print-payment-bulk-action-filters {
  display: none;
}

.print-salesman-filter,
.print-date-filter {
  display: none;
}

.control-label {
  width: 100%;
  display: flex;
}

.draggable-helper-modal .modal-dialog {
  top: 50% !important;
  height: 100px !important;
  min-height: 100px !important;
}

.draggable-helper-modal .modal-backdrop {
  opacity: 0 !important;
}

.draggable-helper-modal .modal {
  width: auto;
  height: auto;
}

.help-container {
  position: fixed;
  top: 15%;
  right: 5%;
  min-height: 200px;
  width: calc((100vw - 300px) / 4);
  max-height: calc(100vh - 30%);
  background: $white;
  border-radius: 8px;
  -moz-box-shadow: 1px 1px 2px 2px $box_shadow;
  -webkit-box-shadow: 1px 1px 2px 2px $box_shadow;
  box-shadow: 1px 1px 2px 2px $box_shadow;
  z-index: 1051;
  overflow: auto;
}

.help-section-header {
  min-height: 62px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px;
  color: $white;
  cursor: move;
  border-bottom: 1px solid $gray_lighter;
  background-color: $white;
  position: sticky;
  top: 0px;
}

.help-container-title {
  font-size: 22px;
  position: relative;
  top: 7px;
  left: 6px;
  font-weight: 400;
  line-height: 1.42857143;
  color: rgb(108, 117, 125);
  line-height: 1.42857143;
  font-size: 22px;
  display: inline-block;
  width: calc(100% - 40px);
  text-align: center;
}

.help-container-close-button {
  // color: $white;
  position: absolute;
  right: 5%;
  top: 18px;
  // font-size: 20px;
  // font-weight: bold;
  cursor: pointer;

  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 $white;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

.help-section-body {
  background-color: $white;
  height: calc(100% - 104px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px;
  overflow: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

.dropdown-toggle span {
  display: initial !important;
}

.dropdown-toggle .caret {
  position: relative;
  top: 8px;
  left: 4px;
}

#show-page-header-action-dropdown .caret {
  top: 10px;
  left: 0px;
}

.help-container table {}

.help-container tr {}

.help-container th {
  border: 1px solid $black;
  padding: 8px;
  background-color: lightgray;
}

.help-container td {
  border: 1px solid $black;
  padding: 8px;
}

.help-container img {
  max-width: 100%;
  height: auto;
}

.Select-menu-outer {
  z-index: 4 !important;
}