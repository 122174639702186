.module-report-link-nav {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  color: $primary;
}

.report-link-wrapper {
  font-size: 14px;
  color: $primary;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.module-report-link-nav.show {
  .report-help-circle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.module-report-link-nav.show-page {
  .report-link-wrapper {
    button.report-help-circle-btn {
      display: flex;
      text-align: center;
      align-items: center;

      img {
        height: 28px;
        padding-left: 1px;
      }
    }
  }
}

// .video-help-circle-btn {
//   margin-right: 7px;

//   .fa.fa-question {
//     font: normal normal normal 14px/1 FontAwesome;
//     padding-top: 2px;
//   }
// }

// .listing-page-help-video-link {
//   height: 44px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: end;
// }

.module-report-modal {
  .modal-dialog {
    width: 80vw;

    .modal-header {
      display: flex;
      // border-bottom: none !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      .close-modal-btn-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0px;
      }

      .header-breadcrumb {
        text-transform: capitalize;
      }

      .common-modal-title-content {
        text-transform: capitalize;
      }

      .common-modal-title-action {
        display: flex;
        justify-content: right;
        padding: 0px;
      }
    }

    .modal-body {
      margin-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      height: calc(100vh - 100px);
      max-height: calc(100vh - 100px);
      min-height: calc(100vh - 100px);

      .module-report-tabs {
        .nav-tabs.navbar-nav {
          // padding-right: 0px;
          position: fixed;
          width: 98%;
          z-index: 8;

          li {
            margin-left: 0px;
            margin-right: 16px;

            a {
              padding-top: 10px !important;
            }
          }
        }
      }

      .tab-content {
        padding-top: 47px;

        .settings-form-elements.settings-scroll-container {
          margin-bottom: 75px;
          height: auto !important;
        }

        #fixed-action-footer {
          padding-bottom: 0px;
          width: 100% !important;
          left: 0px !important;
          margin-bottom: -7px !important;

          .form-footer-btn {
            padding: 5px 0px 5px 0px !important;

            .btn {
              min-width: auto !important;
            }
          }
        }

        .module-report-user-group-tab {
          padding-bottom: 50px;
          padding-top: 10px;

          .module-report-user-groups-submit-btn-wrapper {
            padding: 0px;
            text-align: end;
          }
        }
      }

      table.table-responsive {
        margin-left: 0px !important;
        margin-right: 0px !important;

        th.action-co {
          width: 50px;
        }
      }

      .fixed-pagination {
        margin-bottom: 0px !important;
      }
    }
  }
}