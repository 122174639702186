// .top-alert {
//   margin: 0px;
//   top: 0;
//   height: 30px;
//   text-align: center;
//   position: fixed;
//   right: 0;
//   border-radius: 0px;
//   padding: 4px 0 !important;
//   left: 0;
//   z-index: 999999999999;
// }
// .top-alert .close {
//   font-size: 16px !important;
//   color: $black;
//   width: 20px !important;
//   height: 20px !important;
//   margin: -11px 0 0 0 !important;
// }
// .top-alert .close span {
//   line-height: 16px;
//   font-weight: 400;
// }
// .on-top-alert {
//   padding-top: 30px;
// }

// .on-top-alert .table-full-width thead {
//   top: 141px;
// }
// .on-top-alert .sidebar {
//   top: 30px;
// }
// .on-top-alert .navbar-fixed-top {
//   top: 30px;
// }

.top-alert {
  margin: 0px;
  text-align: center;
  border-radius: 0px;
  padding: 5px 50px !important;
  position: relative;
  transform-origin: center top 0
}

.top-alert .close {
  font-size: 12px !important;
  display: flex !important;
  align-items: center;
  color: #000000;
  width: 20px !important;
  height: 20px !important;
  margin: -11px 0 0 0 !important
}

.top-alert .close span {
  line-height: 16px;
  font-weight: 400
}

.alert.top-alert {
  a {
    color: #1A73E8;
  }
}