.order-item-purchase-order-item-listing-modal-header-parent-row {
  @include flex($column: false, $a: flex-end, $j: null);
  cursor: default;
}

.order-item-purchase-order-item-listing-modal-header-edit-button-col {
  @include flex($column: false, $a: null, $j: flex-end);
}

.order-item-purchase-order-item-listing-modal-header-total-quantity-div {
  padding-top: 15px;
  padding-right: 17px;
  @include flex($column: false, $a: null, $j: flex-end);
}

.orders-modal-modal-header-parent-row {
  @include flex($column: false, $a: flex-end, $j: null);
  padding-bottom: 5px;
}

.orders-modal-close-icon-row-element {
  @include flex($column: false, $a: null, $j: flex-end);
}

.orders-modal-total-quantity-heading-div {
  @include flex($column: false, $a: null, $j: flex-end);
}

.inactive-so-po-modal-show-modal-styling {
  background-color: $gray_lighter;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.info-icon-unsourced-quantity,
.info-icon-allocated-quantity {
  color: $gray_light;
}

.order-item-purchase-order-item-listing-modal-body-height,
.order-item-purchase-order-item-edit-modal-body,
.order-item-purchase-order-item-listing-modal-body {
  .headers {
    padding-bottom: 5px !important;
    border-bottom: 1px solid $border_color;
  }

  .alert.alert-danger {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}