%full-height-of-parent {
  height: 100% !important;
}

%full-view-height {
  height: 100vh !important;
}

.landing-page-wrapper {
  @extend %full-height-of-parent;

  .main-container {
    @extend %full-height-of-parent;
    padding-left: 0px;
    padding-right: 0px;

    .main-row {
      @extend %full-height-of-parent;

      .main-left {
        @extend %full-height-of-parent;

        .landing-page-signout-wrapper {
          text-align: right;
          font-weight: 500;
          cursor: pointer;

          div.disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }

        .active-account-tenants {
          padding-top: 0px;
          // height: 100%;
          overflow: auto;

          .account-tenant-wrapper {
            padding-left: 0px;
            padding-right: 0px;
            margin-bottom: 5px;

            .account-tenant-details {
              padding: 5px;
              border: 1px solid $border_color;
              display: flex;
              align-items: center;

              .account-tenant-logo {
                height: 60px;
                width: 60px;
                margin-right: 10px;
              }

              .account-tenant-initials {
                height: 60px;
                width: 60px;
                line-height: 60px;
                text-align: center;
                border: 1px solid $border_color;
                color: $white;
                margin-right: 10px;
              }

              .account-tenant-title {
                color: $white;
              }
            }
          }

          .account-tenant-wrapper.can-access-dashboard:hover {
            cursor: pointer;
          }

          .account-tenant-wrapper.disabled,
          .account-tenant-wrapper.disabled:hover {
            opacity: 0.5;
            cursor: not-allowed;
          }

        }

        .pending-account-tenants-title hr {
          margin-top: 10px !important;
          margin-bottom: 15px !important;
        }

        .pending-account-tenants {
          padding-top: 0px;
          // height: 100%;
          overflow: auto;

          .pending-account-tenant-wrapper {
            margin-bottom: 5px;
            padding: 5px;
            border: 1px solid $border_color;
            display: flex;
            align-items: center;

            .account-tenant-details {
              padding: 0px;
              display: flex;
              align-items: center;

              .account-tenant-initials {
                height: 60px;
                width: 60px;
                min-width: 60px;
                max-width: 60px;
                line-height: 60px;
                text-align: center;
                border: 1px solid $border_color;
                color: $label_color;
                margin-right: 10px;
              }

              .account-tenant-title {
                color: $label_color;
              }
            }

            .pending-account-tenant-warning {
              text-align: right;
              padding: 0px;

              span.text-warning {
                color: #997300 !important;
              }
            }
          }

          .pending-account-tenant-wrapper.disabled,
          .pending-account-tenant-wrapper.disabled:hover {
            opacity: 0.8;
            cursor: not-allowed;
          }

        }

        .btn-block.add-organisation {
          border-color: $login_page_button_bg_color;
          color: $login_page_button_bg_color;
          font-weight: 600;
          margin-top: 20px;
          text-transform: uppercase;
        }
      }

      .main-right {
        @extend %full-height-of-parent;
      }
    }
  }
}

.login-main-container.main-content {
  .navbar-fixed-top {
    .navbar.navbar-default {
      margin-bottom: 0px;

      .distributo-product-image-nav {
        padding-top: 10px;
        padding-bottom: 10px;

        .distributo-product-image-wrapper {
          position: inherit;
          cursor: pointer;
        }
      }
    }
  }

  .form-container-fluid.organisations-screen.container-fluid {
    height: calc(100% - 120px);
  }

  .form-container-fluid.organisations-screen {
    .organisations-welcome-header {
      font-weight: 500;
      color: $label_color;
    }

    .landing-left {
      margin-top: 0% !important;
    }

    .list-of-account-tenants-title,
    .pending-account-tenants-title {
      text-align: center;

      h6 {
        color: $label_color;
      }
    }

    .active-account-tenants {
      .account-tenant-wrapper {
        .account-tenant-details {
          .account-tenant-title {
            color: $label_color;
          }
        }
      }
    }

    .product-owner-details {
      color: $label_color;

      a {
        color: $primary;
      }
    }

    .policies {
      a {
        color: $gray !important;
      }
    }
  }
}