.audit-show-updates-btn {
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.audit-history-summary {
  padding-top: 10px;

  .btn-link {
    color: $primary;
    cursor: pointer
  }
}

.resource-action {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  .action-timestamp {
    font-size: 14px;
    color: darken($gray, 3%);
    width: 280px;
    flex-shrink: 0;
  }
  
  .action-deco {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 20px;

    $action-deco-circle-dimension: 16px;
    $deco-circle-offset: 2px;
    .action-deco-circle {
      height: $action-deco-circle-dimension;
      width: $action-deco-circle-dimension;
      border-radius: $action-deco-circle-dimension / 2;
      background: $gray_lighter;
      margin-top: $deco-circle-offset;
      position: absolute;
      z-index: 1;
    }

    .action-deco-line {
      width: 2px;
      height: 100%;
      position: absolute;
      left: 299px;
      top: $deco-circle-offset;
      background: $gray_lighter;
    }
  }

  .action-statement {
    font-size: 14px;

    p {
      font-size: 14px;
      margin-bottom: 0;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  .action-timestamp, .action-statement {
    margin-bottom: 16px;
  }
}