// Users Group role
.users-group-roles {
  .table {
    border: 1px solid $secondary_lighter;
    thead > tr > th {
      padding: 9px 5px;
      text-align: center;
      background-color: lighten($gray, 42%);
      border-color:$gray_lighter;
      border: 1px solid $gray_lighter !important;
      color: $gray_darkest;
    }
    tbody > tr > td:first-child {
      font-weight: 500;
      color: $gray_darkest;
      font-size: 14px;
      text-transform: uppercase;
    }
    tbody > tr > td {
      padding: 8px 7px;
      color: $gray_lighter;
      position: relative;
      .basic-role {
        padding: 0 6px;
        text-align: center;
      }
      .checkboxes
      {
          display: flex;
          align-items: center;
      }
      label {
        display: inline-block;
        padding-right: 13px;
      }

      /* The users-group-role-checkbox-label */
      .users-group-role-checkbox-label {
        display: block;
        position: relative;
        padding-left: 33px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        color: $gray_light !important;
        text-transform: uppercase;
        padding-top: 3px;
        margin-bottom: 0px;
      }

      /* Hide the browser's default checkbox */
      .users-group-role-checkbox-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid $gray_light;
        bottom: 0;
        margin: auto;
        border-radius: 2px;
        background-color: $white;
      }

      .checkmark.disabled {
        background-color: $gray_lighter;
      }

      /* When the checkbox is checked, add a blue background */
      .users-group-role-checkbox-label input:checked ~ .checkmark {
        background-color: $primary;
        border: 1px solid $primary;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .users-group-role-checkbox-label input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .users-group-role-checkbox-label .checkmark:after {
        left: 6px;
        top: 2px;
        width: 7px;
        height: 12px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.users-group-roles .table tbody > tr > td .basic-role {
  padding: 18px 2px;
}
.users-group-roles .table tbody > tr > td .basic-role .users-group-role-checkbox-label:last-child {
  z-index: 1;
}
td.basic-role-col {
  position: relative !important;
}

.basic-role-col:after {
  content: "";
  position: absolute;
  background: lighten($gray_lighter, 10%);
  right: 0;
  top: 0;
  bottom: 0;
  width: 25%;
}
.users-group-roles .table tbody > tr > td .basic-role.basic-role-col-master:last-child {
  min-height: 53px;
}
td.basic-role-col .checkmark {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto !important;
}

.role-star {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50px;
}

.role-star img {
  width: 16px;
  border-radius: 50px;
}

td.basic-role-col.basic-role-col-master .users-group-role-checkbox-label {
  padding: 26px 0 0 0 !important;
  height: auto;
}
td.basic-role-col .users-group-role-checkbox-label {
  height: 20px;
}
.users-group-roles .table {
  width: auto;
}
.checkboxes.checkboxes-list {
  display: initial !important;
}

.checkboxes.checkboxes-list .td-star {
  display: inline-block !important;
  width: 200px;
  margin: 5px 0 5px 0 !important;
  vertical-align: middle;
}

.users-group-roles button.btn {
  border: none;
  padding: 0px;
  width: 16px;
  z-index: 7;
  height: 16px;
  line-height: unset;
  background: none !important;
  box-shadow: none !important;
}

.users-group-roles .btn-toolbar {
  top: 1px;
  position: absolute;
  margin: 0px;
}

.users-group-roles .btn-toolbar .dropdown {
  margin: 0px;
  margin-top: -13px;
}
.users-group-roles .dropdown-menu {
  padding: 10px;
  box-shadow: none;
  border-radius: 5px;
  width: 220px;
  margin: 30px 0 0 0px !important;
  left: 0;
  z-index: 8;
}
.users-group-roles .dropdown-menu:after {
    content: "";
    position: absolute;
    top: -8px;
    width: 14px;
    height: 14px;
    border-left: 1px solid rgba(0, 0, 0, .15);
    border-radius: 5px 0px 0 0;
    transform: rotate(43deg);
    border-top: 1px solid rgba(0, 0, 0, .15);
    background: $white;
}
.td-star button.btn {
  margin: 8px 0px 0px 15px !important;
}

.td-star .dropdown-menu {
  margin-left: -8px !important;
}
.basic-role button.btn {
  margin: 1px 0 0 35px !important;
}

.basic-role .dropdown-menu {
  margin-left: 12px !important;
}

.basic-role .dropdown-menu label {
  height: auto;
}

.basic-role .dropdown-menu label .checkmark {
  left: 0 !important;
  right: auto;
  bottom: 0 !important;
}
.basic-role-col.basic-role-col-master {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
td.basic-role-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

label.users-group-role-checkbox-label {
  margin-bottom: 0px !important;
}

.special-role-col .open.btn-group ul.dropdown-menu {
  border: 1px solid $secondary_lighter;
}