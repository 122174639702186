.tabs-w-header-page {
  padding: 0 !important;
  overflow: hidden !important;

  .tab-content {
    padding: 15px 30px 15px 30px;
    flex: 1 1 auto;
    max-height: 100%;
    overflow-y: auto;
  }
}

.account-show-page-header {
  border-bottom: 0;
}

.tabs-w-header {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .nav.nav-tabs {
    flex-shrink: 0;
    background: $white;
    padding-right: 30px;
    margin-bottom: 0;

    li:first-child {
      margin-left: 30px;
    }

    li>a {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}

$add-btn-dim: 22px;
$add-btn-font-size: 13px;

.quick-add-link {
  @include flex();
  flex-shrink: 0;
  display: none;
  height: $add-btn-dim;
  width: $add-btn-dim;
  border: 2px solid $white;
  border-radius: 50%;

  i {
    color: $white;
    font-size: $add-btn-font-size  !important;
    margin-right: 0px !important;
    width: auto !important;
    line-height: $add-btn-font-size  !important;
  }

  &:hover {
    background: $primary;
  }
}

.nav-link:hover {
  .quick-add-link {
    display: flex;
  }
}

.sidebar-normal.sub-menu-item {
  flex: 1 1 auto;
  margin-right: 6px;
}

.btn-header-act-circle {
  padding: 5px 0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.dms-inf-select {
  .Select-menu {
    overflow: hidden;
  }

  .Select-menu-outer {
    .Select-menu {
      .select-option {
        box-sizing: border-box;
        background-color: $white;
        color: #666666;
        cursor: pointer;
        display: block;
        padding: 8px 10px;
      }

      .select-option.is-focused {
        background-color: #ebf5ff;
        background-color: rgba(0, 126, 255, 0.08);
        color: #333;
      }
    }
  }
}

.btn-header-act-circle.e-invoice {
  margin-right: 6px;
}