.reports-container-fluid {
  .table-card {
    overflow-x: hidden;
    width: 100%;
  }
}


.table-with-fix-cols {
  overflow-x: auto !important;
  max-width: 100% !important;

  table {
    table-layout: fixed;
  }

  thead th, tfoot td {
    position: sticky !important;
    background: $white;
  }
  
  tfoot td {
    bottom: 0 !important;
  }
  
  thead th.fix-col, tfoot tr td:first-child  {
    left: 0 !important;
    z-index: 7;
  }

  .fix-td {
    position: sticky !important;
    left: 0 !important;
  }
}

.table-with-sticky-header {
  th {
    position: sticky !important;
    top: 0;
    background: $white;
    box-shadow: 0 2px 3px -2px $box-shadow !important;
    border-bottom: 1px solid $border-color !important;
    z-index: 2;
  }
}

table.with-bottom-border {
  tr td {
    border-bottom: 1px solid $gray_lighter
  }
}

table.td-align-top {
  tr td {
    vertical-align: top;
  }
}

$report-section-header-top: 15px;
.report-section-header {
  position: sticky;
  top: 0;
  padding-top: $report-section-header-top;
  background: $white;
  z-index: 1;
}

.report-section-table {
  tr th {
    top: (33px + $report-section-header-top) !important;
  }
}

.report-group-selection-container {
  .Select-control {
    table-layout: fixed;
  }

  .Select-multi-value-wrapper {
    overflow: auto !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
}

.report-form-container {
  flex: 1 1 auto;
}