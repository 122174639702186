// WHAT   CSS fixes which are applied only in safari browser
// SOURCE   https://gist.github.com/jbutko/6718701#gistcomment-2630350

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .main-content {

      // This fix is for the main indexes
      .index-listing-container-fluid,
      .content-wrapper .index-listing-container-fluid {
        height: 100%;

        .index-listing-container-fluid .row:first-child {
          height: 100%;
        }
      }

      // This fix is for the index in user beat routes tab in user show page
      .show-page-container-fluid {
        height: 100%;

        .row.customer-tabs-and-content {
          height: 100%;
        }

        .tab-content {
          height: 100%;
        }
      }
    }

    .reports-container-fluid {

      // This fix is for the reports index page
      .table-card {
        height: 100%;
      }

      // This fix is for the reports index page footer
      #generic-table {
        tfoot {
          position: sticky;
          bottom: 0;
        }
      }
    }

    .country-code-number-field {
      .input-group {
        .country-code {
          width: 110%;
        }
      }
    }

  }
}