.table .ReactVirtualized__Table__headerColumn,
.table .ReactVirtualized__Table__rowColumn {
  margin: 0 !important;
  line-height: 1.42857143;
  // vertical-align: top;
}

.table .ReactVirtualized__Table__headerColumn {
  color: $secondary_darker;
  border-bottom: 1px solid $border_color;
  padding: 8px 16px !important;
}

.table .ReactVirtualized__Table__rowColumn.v-table-cell, .v-table-cell {
  padding: 8px 16px !important;
  white-space: normal !important;
}

.table .ReactVirtualized__Table__row, .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__footerRow {
  border-top: 1px solid $border_color;
  align-items: stretch !important;
}

.ReactVirtualized__Table__footerRow {
  display: flex;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  outline: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

.slim-table {
  tr > td {
    padding: 12px 5px !important;
  }
}

.side-info-panel-content .ReactVirtualized__Table__rowColumn {
  padding: 8px 16px
}

.table-with-side-filter {
  overflow-y: auto;

  tbody {
    display: table-row-group !important;
  }
}

.nested-form-table {
  width: 100%;
  table-layout: fixed;

  th, td {
    padding: 0 6px;
    border-bottom: 1px solid $border_color;
  }

  thead th {
    font-size: 12px;
    font-weight: normal;
    color: $secondary_darkest;
    padding-bottom: 9px !important;
    text-transform: uppercase;
  }

  td {
    padding-bottom: 6px;
    padding-top: 6px;
    vertical-align: top;

    .form-control, .Select-control {
      border-color: transparent;
      background-color: transparent;
    }

    .form-control:focus, .Select.is-open > .Select-control {
      border-color: $primary;
    }
  }

  tr:nth-child(even){ background-color: $gray_lightest; }

  tr > td:first-child {
    font-size: 14px;
  }

  tr > th:first-child {
    padding-left: 0;
  }
}