// WHAT  mixins
// WHAT  for flex generator
@mixin flex($column: false, $a: center, $j: center) {
  display: flex;
  @if $column {
    flex-direction: column;
  }
  
  @if $a != null {
    align-items: $a;
  }

  @if $j != null {
    justify-content: $j;
  }
}

// WHAT  flex
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

// WHAT overflow hidden
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-hidden-x {
  overflow-x: hidden !important;
}
.overflow-hidden-y {
  overflow-y: hidden !important;
}

// WHAT  margin and paddings 0 for different dimensions
$dimensions: (
  t: top,
  r: right,
  b: bottom,
  l: left,
);

@each $name, $value in $dimensions {
  .p#{$name}0 {
    padding-#{$value}: 0 !important;
  }

  .m#{$name}0 {
    margin-#{$value}: 0 !important;
  }
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.px-15px {
  padding: 0 15px
}

// WHAT  btns
.btn-reset {
  padding: 0 !important;
  outline: 0 !important;
  border: 0 !important;
  background: transparent !important;
}

.truncate-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.display-none { 
  display: none;
}

.display-inline-block {
  display: inline-block;
}

// WHAT  borders
.border-bottom-0 {
  border-bottom: 0;
}

// WHAT  word break
.word-break-all {
  word-break: break-all;
}

// WHAT  position
.position-sticky {
  position: sticky !important;
}
.position-relative {
  position: relative;
}

.fit-w {
  width: 100%;
}

// WHAT  table
.table-layout-fixed {
  table-layout: fixed;
}

// WHAT  line clamps
.lc2, .lc3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lc2 {
  -webkit-line-clamp: 2;
}

.lc3 {
  -webkit-line-clamp: 3;
}

// WHAT   whitespace
.ws-normal {
  white-space: normal;
}
.ws-nowrap {
  white-space: nowrap;
}

// WHAT   not permitted
.not-permitted {
  width: inherit;
  display: inline-block;
  pointer-events: none;
  opacity: .5;
}

.not-permitted-container {
  display: inline-block;
  cursor: not-allowed;
}

.el-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.dropdown-menu {
  border-radius: 0;
  box-shadow: 0px 0px 21px $box_shadow;
  border: 0;
}

// WHAT   shimmer like effect
.shimmer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    animation: pulsing-bkg 1s ease-in-out 0s infinite;
  }
}

// Text aligns
.text-align-right {
  text-align: right !important;
}

// WHAT   overflow wrap styles
.wrap-anywhere {
  overflow-wrap: anywhere;
}

// WHAT width styles
.width-half {
  width: 50%;
}

// WHAT no gutter classs which can be used with row
.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}