.bulk-update.common-modal {
  .modal-dialog {
    width: 65vw;

    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;

      .number-of-resource-selected-text {
        font-size: 14px;
        font-weight: 500;
      }

      .flash-messages {
        padding: 0px !important;

        .alert.alert-danger {
          margin-bottom: 0px;
          margin-top: 0px;
          padding: 10px !important;
        }
      }
    }

    .modal-body {
      height: 65vh;
      padding: 0px;

      table>thead>tr>th:first-child,
      table>thead>tr>th:last-child {
        padding-left: 20px;
      }

      table>tbody>tr>td:first-child,
      table>tbody>tr>td:last-child {
        padding-left: 20px;
      }
    }

    .modal-footer {
      .select-up {
        margin-bottom: 0px;
        padding-left: 6px;
        padding-right: 6px;

        .select-box-container {
          .Select-control {
            height: 30px;

            .Select-value,
            .Select-placeholder {
              text-align: left;
              line-height: 30px;
            }

            .Select-input {
              height: 30px;
            }
          }

          .Select-menu-outer {
            text-align: left;
          }
        }

        input.form-control {
          height: 30px;
        }

        .bulk-update.errors-message {
          text-align: left;
        }
      }
    }

  }
}