.supplier-specific-taxes.card-header {
  padding-bottom: 5px !important;
  border-bottom: 1px solid $border_color;
}

.supplier-specific-taxes-nested-form-table {
  thead tr th {
    padding-top: 15px;
    padding-bottom: 15px !important;
  }
}

.supplier-specific-taxes {
  .card-header-title {
    h4 {
      line-height: 42px !important;
      vertical-align: middle;
      margin-bottom: 0 !important;
    }
  }
}