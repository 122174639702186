.inventory-piece-tracking-link.show {
  cursor: pointer;
  margin-top: 6px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.inventory-piece-tracking-link.disabled {
  cursor: auto;
  color: $gray;
}

.inventory-piece-tracking-modal-dimensions {
  max-width: none !important;
  width: 65vw;
  margin: auto;

  .modal-content .modal-body {
    height: 65vh;
  }

  .modal-body {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.inventory-piece-tracking-modal-dimensions.purchase-piece-wise-inventory {
  max-width: none !important;
  width: 45vw;
  margin: auto;

  .modal-content .modal-body {
    height: 35vh;
  }

  .modal-body {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.inventory-piece-modal-header-parent-row {
  .inventory-piece-modal-header-right {
    .close-modal-button-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }

    .total-quantity {
      font-size: 14px;
      font-weight: 500;
      padding-top: 7px;
      padding-bottom: 7px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .header-breadcrumb-and-title {
    padding-bottom: 5px;
  }
}

.inventory-piece-tracking-details-wrapper {
  .control-label {
    padding-left: 0px;
  }

  .select-box-container .Select {
    border: 1px solid $border_color;

    .Select-menu-outer {
      border: 1px solid $primary_input_field_border_color;
      border-top: 1px solid $border_color;
    }
  }
}

.purchase-inventory-piece-tracking-input-pieces-wrapper {
  display: flex;
  margin-bottom: 15px;

  #inventory-piece-tracking-input {
    height: 30px;
  }

  .btn.btn-primary {
    line-height: 14px;
  }
}

.purchase-inventory-piece-tracking-modal-body-content-height {
  border: none;
  height: 50vh;
}

.inventory-piece-tracking-modal-body-content-height {
  border: none;
  height: 50vh;
}

#available-inventory-piece-tracking {
  height: 30px;
}

.inventory-piece-tracking-search-and-total-section {
  padding-top: 15px;
  padding-bottom: 15px;
}

.inventory-piece-tracking-main-section {
  display: flex;

  .available-numbers-label,
  .selected-numbers-label {
    color: $label_color;
    font-size: 14px;
  }

  .available-identifier-list-wrapper .available-identifier-list,
  .selected-identifier-list-wrapper .available-identifier-list {
    height: 51vh;
    overflow: scroll;
    border: 1px solid $border_color;

    .Select-option:hover,
    .Select-option:focus,
    .Select-option:active {
      background-color: $secondary_lightest;
      cursor: pointer;
      outline: none;
    }

    .Select-option:focus-visible {
      outline: none;
    }

    .no-result-found {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;

      span {
        color: $label_color;
      }
    }
  }

  .divider {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: x-large;
    font-weight: 500;
    color: $gray;
  }
}

.required-and-allocated-section {
  .required-qty {
    text-align: right;
    font-size: 16px;
    color: $gray;
  }

  .allocated-qty {
    text-align: right;
    font-size: 16px;
    color: $gray;
  }

  .required-qty-value,
  .allocated-qty-value {
    text-align: left;
  }
}

.add-new-piece-wise-inventory-tracking-link,
.add-new-piece-wise-inventory-tracking-link:hover {
  color: $primary;
  cursor: pointer;
  // text-decoration: underline;
  font-size: 14px;
}

.inventory-piece-tracking-errors-wrapper,
.purchase-inventory-piece-tracking-errors-wrapper {
  .alert.alert-danger {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.voucher-item-inventory-pieces-list {
  display: flex;
  flex-wrap: wrap;

  .voucher-item-inventory-piece-wrapper {
    background-color: $white;
    padding: 2px 5px;
    margin: 2px;
    color: lighten($primary, 10%);
    border: 1px solid $primary_lighter !important;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .fa.fa-times {
    -webkit-text-stroke: 1px $white;
    color: lighten($primary, 10%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $primary_lighter;
    padding-right: 2px;
    margin-right: 4px;
  }

  .no-items-found {
    width: 100%;
  }
}