$customer-img-dim: 60px;

.customer-upload-btn {
  height: $customer-img-dim !important;
  width: $customer-img-dim !important;
  border-radius: 100% !important;
  text-align: center;
  border: 2px solid $secondary_lighter;
  box-shadow: none;

  .mdi {
    line-height: $customer-img-dim;
    height: $customer-img-dim;
    font-size: 20px;
  }
}

.customer-img-div {
  height: $customer-img-dim !important;
  width: $customer-img-dim !important;
  border-radius: 100% !important;
}

.customer-img-container {
  position: relative;

  .customer-img-div {
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .customer-img-delete {
    position: absolute;
    left: 60px;
    top: 0px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    border-radius: 100%;
    text-align: center;
    padding: 0;
  }
}

.balance-detail {
  margin-left: 16px;
  text-align: right;

  .balance-number {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: $gray_dark;
  }
}

.customer-recents {
  margin: -15px -30px;
}

#customer-recents-tabs,
.side-tabs-nav {
  overflow: hidden;
  height: 100%;
}

.customer-show-page {
  .customer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .tab-content {
    overflow-x: hidden;
  }
}

.customer-quick-info {
  padding: 15px;
  flex-shrink: 0;
}

.customer-tabs-and-content {
  flex: 1 1 auto;
  overflow: hidden;
}

.statement-details {
  padding: 15px;
  display: flex;
  // justify-content: space-between;

  .statement-detail:last-child {
    margin-right: 0;
  }
}

.statement-detail {
  margin-right: 60px;
  text-align: right;
}

.truncate-to-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.custom-tooltip {
  position: relative;
  display: inline-block;

  & .tooltip.bottom {
    top: 100%;
    left: 50% !important;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .3s;

    & .btn-link {
      color: $white !important;
    }
  }

  &:hover .tooltip,
  .tooltip:hover {
    opacity: 1;
  }
}

.tax-id-type-unit {
  width: 40%;
}

.tax-id {
  width: 60%;
}

.contact-info-details {
  font-size: 14px;

  .action-btn-round {
    margin-left: 0;
    margin-right: 12px;
  }
}

.location-logs-container {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15;
}

.user-location-tab-content {
  padding-bottom: 51px;
  height: 100%;

  #fixed-action-footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.user-location-logs-container {
  padding-top: 0;
  flex: 1 1 auto;
  max-height: 100%;
  overflow: hidden;
}

.location-logs-list {
  display: flex;
  justify-content: center;
  overflow-y: scroll
}

.user-location-logs-container .location-logs-list,
.location-logs-container .location-logs-list {
  display: block !important;

  .bulk-action-left {
    display: block !important;
  }
}

.tabs-right-content {
  position: absolute !important;
  right: 15px;
  top: 0;

  a {
    pointer-events: auto !important;
    outline: 0;
  }

  .btn-sm {
    padding: 2px 4px;
    font-weight: 500;
  }
}

.recents-table-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .recents-simple-table-container {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
  }

  .tabel-body-wrapper {
    height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
    overflow: auto;
  }

  .pagination {
    margin: 0;
  }
}

.common-dropdown {
  [role="menuitem"] {
    padding: 3px 16px;
    line-height: 28px;
  }
}

.breakdown-dropdown {
  .dropdown-menu {
    border-radius: 0;
    box-shadow: 0px 0px 21px $box_shadow;
    border: 0;
  }

  [role="menuitem"]>.row {
    display: flex;
  }

  .breakdown-amount {
    display: inline-block;
    text-align: right;
    min-width: 100px;
    margin-left: 12px;
  }
}

.disabled-menu-item,
.disabled-menu-item a {
  cursor: default;
}

.disabled-menu-item a:hover {
  background: transparent !important;
}

$tab-left-padding: 30px;

.side-tabs-nav {
  li a {
    border-radius: 0;
    color: $secondary_darkest;
    padding: 6px 15px !important;
    padding-left: $tab-left-padding !important;
    outline: 0 !important;
  }

  li.active a,
  li.active a:focus,
  li.active a:hover {
    color: $secondary_darkest;
    font-weight: 700;
    background-color: lighten($color: $primary, $amount: 30);
  }

  li a:focus,
  li a:hover {
    background-color: lighten($color: $primary, $amount: 30);
  }
}

.customer-recents-left-sidenav {
  overflow-y: auto;
  padding-top: 15;
}

.customer-recents-right-section {
  border-left: 1px solid $secondary_lighter;
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.visit-link a {
  cursor: pointer;
}

.visit-link a:hover {
  text-decoration: underline;
}

.customer-form-main-content .country-code-number-field .form-control.phone-field {
  z-index: 0;
}

.customer-opening-balance-type-credit {
  width: 50%;
}