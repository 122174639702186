.stock-entry-form-modal-body.modal-body {
  h5.text-muted.modal-title {
    font-size: 18px;
  }

  .add-nested-item-btn {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px;

    #add-new-item {
      line-height: 18px;
      width: 65px;
      padding: 5px;
    }
  }

  .inventory-stock-entry-items {
    .header {

      div.inventory-piece,
      div.inventory-batch,
      div.quantity,
      div.action {
        padding-left: 6px;
        padding-right: 6px;
      }
    }

    .body {
      .inventory-stock-entry-item {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 0px;
        padding-right: 0px;
      }

      div.inventory-piece,
      div.inventory-batch,
      div.quantity,
      div.nested-form-item-destroy-btn {
        padding-left: 6px;
        padding-right: 6px;
      }

      div.inventory-piece,
      div.inventory-batch {
        padding-left: 0px;
      }

      div.nested-form-item-destroy-btn {
        padding-right: 0px;

        .btn.btn-danger-outline.btn-block {
          padding: 0px 10px;
        }
      }
    }
  }
}

.stock-entry-form-modal {
  .modal-dialog {
    max-width: none !important;
    width: 60vw;
    margin: auto;
  }
}

.inventory-stock-entry-item-mfd-date,
.inventory-stock-entry-item-expiry-date {
  line-height: 45px;
  color: #4d4d4d;
}

form.stock-form {
  .inventory-stock-entry-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .inventory-stock-entry-items {
    .add-nested-item-btn {
      .form-group {
        margin-top: 15px !important;
        margin-bottom: 0px !important;

        #add-new-item {
          margin-right: 0px !important;
        }
      }
    }
  }
}

.form-container-fluid.stock-form {
  .card {
    margin-bottom: 25% !important;
  }
}

.card-header.stock-entry-pieces {
  h4 {
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
  }
}

.stock-inventory-piece-tracking-modal-body-content-height {
  border: none;
  height: 50vh;
}

.stock-inventory-piece-tracking-input-pieces-wrapper {
  display: flex;
  margin-bottom: 15px;


  #inventory-piece-tracking-input {
    height: 30px;
  }

  .btn.btn-primary {
    line-height: 14px;
  }
}

.inventory-stock-entry-piece-modal {
  .stock-inventory-piece-tracking-input-pieces-wrapper {
    padding: 0px;
  }

  .inventory-piece-tracking-details-wrapper {
    padding: 0px;
  }
}