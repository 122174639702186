.comment-text-box {
  resize: none;
  height: 37px !important;
  transition: .3s;

  &:focus {
    height: 100px !important;
  }
}

$circle-diameter: 30px;

.comment-user-bubble,
.visit-user-bubble {
  height: $circle-diameter;
  width: $circle-diameter;
  line-height: $circle-diameter;
  color: $white;
  background: $primary;
  text-align: center;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  flex-shrink: 0;
}

.delete-document,
.download-document {
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  padding: 3px 1px;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: none;
}

.delete-document {
  right: 0;
  background: $danger_light;
}

.download-document {
  right: 25px;
  font-size: 10;
  background: $primary;
}

.document-preview-card {
  padding: 12px;
  border: 1px solid $border_color;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  position: relative;

  .dropdown {
    align-self: flex-start;

    button {
      padding: 4px 0;
      margin-top: -4px;
      border: 0;
    }
  }

  .document-category {
    color: darken($gray, 3%);
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .document-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .comments-other-actions {
    opacity: 0;
    transition: .3s;
    position: absolute;
    right: 12px;
  }

  .document-other-actions {
    position: absolute;
    top: 12px;
    right: 12px;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }

  .document-name-container {
    margin-left: 8px;
  }

  &:hover {

    .comments-other-actions,
    .document-other-actions {
      visibility: visible;
      opacity: 1;
    }

    .delete-document,
    .download-document {
      display: block;
    }
  }

  &.for-comment {
    max-width: 350px;

    .document-name-container {
      max-width: 236px;
    }
  }
}

.document-delete-btn {
  background-color: rgba(250, 100, 35, .75);
  color: $white;
  opacity: 1;

  &:hover {
    color: $white;
    background-color: rgba(250, 100, 35, 1) !important;
  }
}

.document-download-btn {
  background-color: rgba(92, 93, 232, .75);
  color: $white;
  opacity: 1;

  &:hover {
    color: $white;
    background-color: rgba(92, 93, 232, 1) !important;
  }
}

.document-img-preview {
  position: relative;
  border-radius: 2px;
  border: 1px solid $border_color;
  margin: 0 6px 6px 6px;

  a {
    img {
      height: 40px;
    }

    display: flex;
    flex-wrap: nowrap;
  }

  &:hover {

    .delete-document,
    .download-document {
      display: inline-block;
    }

    .dropdown-container {
      visibility: visible;
      opacity: 1;
    }
  }

  .dropdown-container {
    position: absolute;
    right: 10px;
    top: 10px;
    visibility: hidden;
    opacity: 0;

    button {
      padding: 4px 0;
      background: $primary;
    }
  }

  .document-type {
    color: darken($gray, 3%);
    margin-bottom: 4px;
  }

  img {
    max-height: 150px;
    border-radius: 2px;
    width: auto;
    max-width: 300px;
  }
}

.comment-header {
  .dropdown>button {
    padding: 0;
    border: 0
  }
}

.view-more-comments {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.show-add-document {
  background: lighten($gray_lighter, 12%);
  padding: 20px 30px;
  border-top: 1px solid $gray_lighter;
}

.adBanner {
  height: 0
}

;

.delete-comment-btn {
  cursor: pointer;
  font-size: 14px;
}

.browse-btn {
  height: 100%;
  padding: 6px 12px;
  line-height: 28px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: lighten($gray_lighter, 10%);

  &:hover {
    background: $gray_lighter;
  }
}