.variant-actions {
  width: 14px;
  padding-left: 0;
  padding-right: 0;
  border: 0px;
  background-color: transparent;
}

.variant-actions:hover,
.variant-actions:focus {
  background-color: transparent !important;
}

.print-format-menu-items {
  padding: 6px 15px;

  label {
    width: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
    margin: 0px !important;
    font-weight: 400 !important;
  }
}

.variant-menu-items.duplicate.disabled label {
  cursor: not-allowed;
  color: $secondary_light !important;
}

.variant-menu-items:hover,
.variant-menu-items label:hover {
  cursor: pointer;
  background-color: $th-default-bg-color;
}

.variant-menu-items.duplicate label {
  color: $secondary_darkest !important;
}

#product-variants-tabs .product-variant ul.nav.nav-pills {
  li>a {
    display: flex;

    span.variant {
      width: 93%;
    }

    .variant-btn-toolbar.btn-toolbar .dropdown.btn-group {
      button.variant-actions {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .dropdown-menu {
        margin-top: 0px;

        .variant-menu-items.duplicate {
          padding: 5px;
        }
      }
    }
  }
}

#product-variants-tabs .product-variant ul.nav.nav-pills li.active {
  .fa.fa-ellipsis-v {
    color: $white;
  }
}