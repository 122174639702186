$tab_bkg: transparent !important;
$tab-left-padding: 30px;
$t-border-width: 3px;

.index-listing-container-fluid.help.container-fluid {
  .content.table-full-width.table-responsive {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 100% !important;

    .content-help-page {
      .form-container-fluid.container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 0px !important;

        .tab-container {
          #content-help-left-side {
            padding-left: 0px;
            padding-right: 5px;
            height: calc(100vh - 105px) !important;
            overflow-y: auto;

            li.menu-item-divider>hr {
              margin-bottom: 0px !important;
              margin-top: 0px !important;
              margin-left: 10px !important;
            }

            ul.nav {
              li>a {
                color: #3d4246;
                padding: 6px 15px;
                border-radius: 0 !important;
                padding-left: $tab-left-padding !important;
                outline: 0 !important;
                background-color: $white;
              }

              li>a:hover {
                background: $tab_bkg;
                border-left: $t-border-width solid $primary;
                padding-left: $tab-left-padding - $t-border-width !important;
                background-color: $white;
              }

              li>a[aria-selected="true"] {
                background: $tab_bkg;
                font-weight: 600;
                padding-left: $tab-left-padding - $t-border-width !important;
                border-left: $t-border-width solid darken($color: $primary, $amount: 10);
                color: $primary !important;
                background-color: $white;
              }
            }
          }

          #content-help-right-side {
            border-left: 1px solid $border_color;
            height: calc(100vh - 120px) !important;
            overflow-y: auto;
            padding: 6px;

            .box-wrapper {
              padding: 6px;

              .box {
                text-align: left;
                display: block;

                .content-youtube-video {
                  border: 1px solid $border_color
                }

                .content-youtube-video-title {
                  border: 1px solid $border_color;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  border-top: none;
                  min-height: 71px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}