.login-main-container.main-content {
  .form-container-fluid.update-account-profile-page.container-fluid {
    height: 100%;

    .update-account-profile-wrapper {
      .container-fluid.main-container {
        padding-left: 0px;
        padding-right: 0px;

        .update-profile-title {
          margin-bottom: 30px;
          font-weight: 500;
        }

        .landing-left {
          margin-top: 0%;
        }

        .form-group.name-wrapper {
          .control-label {
            color: $label_color !important;
          }

          input.form-control {
            border: 1px solid $border_color;
          }
        }
      }
    }

    .footer {
      background-color: $white;

      .product-owner-details {
        color: $label_color;

        a {
          color: $primary;
        }
      }

      .policies {
        a {
          color: $gray !important;
        }
      }
    }
  }
}