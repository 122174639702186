.e-invoice-credential-description {
  padding-right: 15px !important;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  .step-to-create-e-invoice-credential,
  .steps-to-create-e-invoice {
    .header {
      color: $gray_darker;
      text-transform: capitalize;
      font-weight: 400;
    }

    ol.order-list li,
    ul>li {
      font-size: 15px;
      color: $gray_darker;
      line-height: 26px;
    }
  }

  .steps-to-create-e-invoice {
    margin-bottom: 10%;
  }
}

.e-way-bill-credential-description {
  padding-right: 15px !important;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  .step-to-create-e-way-bill-credential,
  .steps-to-create-e-way-bill {
    .header {
      color: $gray_darker;
      text-transform: capitalize;
      font-weight: 400;
    }

    ol.order-list li,
    ul>li {
      font-size: 15px;
      color: $gray_darker;
      line-height: 26px;
    }
  }

  .steps-to-create-e-way-bill {
    margin-bottom: 10%;
  }
}