@import "./new_banner";

.pt0 {
  padding-top: 0 !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.container-fluid-half {
  padding: 0 7.5px;
}

.login-main-container {
  height: 100%;

  .bg-image,
  .login {
    min-height: initial;
    height: 100%;
  }
}

.main-panel {
  flex: 1 1 auto;
}

.main-panel .main-content {
  min-height: initial;
}

// .main-content > .form-container-fluid { flex: 1 1 auto }

.main-content,
.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .navbar-fixed-top {
    position: initial;
  }

  .index-listing-container-fluid {
    padding-top: 4px;
    flex: 1 1 auto;
    overflow-y: hidden;
  }

  .form-container-fluid,
  .show-page-container-fluid {
    overflow-y: auto;
    padding-top: 15px;
  }

  .form-container-fluid,
  .reports-container-fluid,
  .show-page-container-fluid {
    margin: 0;
  }

  .reports-container-fluid {
    padding-top: 0;
    height: 100%;

    .navbar-fixed-top {
      position: initial !important;
      padding: 0;
      padding-top: 8px;
    }

    .table-fix-body {
      height: 100%;
    }

    .top-bar {
      z-index: 11;
    }

    .table-fix-top {
      z-index: 10;
    }
  }

  .setting-page-tab {
    overflow: hidden;
    height: 100%;

    .form-container-fluid {
      height: 100%;
      overflow: hidden;
      padding-top: 0;
      padding-left: 0;
    }

    .form-container-fluid .card {
      margin-bottom: 0;
    }
  }
}

#accounting-account-tabs,
#settings-tabs {
  .nav.nav-tabs {
    margin-top: -18px;
  }
}

#settings-tabs {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  height: 100%;

  .nav.nav-tabs {
    position: initial;
    width: 100%;
    padding-top: 0;
    margin-bottom: 0;

    a {
      padding-top: 18px;
    }
  }

  .tab-content {
    flex: 1 1 auto;
    overflow: hidden;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      &>div {
        flex: 1 1 auto;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 20px;
      }
    }
  }
}

.content-wrapper>div {
  height: 100%;
  display: flex;
  flex-direction: column;

  .table-card {
    flex: 1 1 auto;
    max-height: 100%;
    overflow-y: auto;
  }

  .top-bar {
    flex-shrink: 0;
  }

  .table-card-body,
  .table-responsive,
  .table-mid {
    height: 100% !important;
  }
}

#main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  min-height: initial;
  max-height: 100%;
  overflow-y: auto;
}

.reports-container-fluid #wrapper {
  height: 100%;
  width: 100%;
}

.feed-container-fluid {
  padding-top: 30px;
  margin-left: 0;
  margin-right: 0;
  overflow-y: auto;
}

// .table-fix-top, .table-fix-bottom { display: none; }

// .table-fix-body {
//   thead th { position: sticky !important; top: 0; background: #fff !important; z-index: 2; }
//   tfoot td { position: sticky !important; bottom: 0; background: #fff !important; z-index: 2; }
// }

#sidebar {
  position: relative;

  // .sidebar-wrapper {
  //   height: calc(100% - 68px);
  // }
}

.top-alert {
  margin: 0;
}

.table-full-width {
  thead {
    display: table-header-group !important;
    position: initial !important;
    width: 100%;

    th {
      background: white;
      position: sticky;
      top: 0;
      z-index: 5;
      box-shadow: 0 2px 1px -1px #dddddd !important;
    }
  }
}

.index-listing-container-fluid {
  .card {
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-full-width {
      max-height: 100%;
      overflow-y: auto;
      padding-top: 0 !important;
    }
  }

  .row:first-child {
    // height: 100%;
    overflow-y: auto;

    .col-md-12 .card {
      margin-bottom: 0;
    }

    .col-md-12 .card .table {
      margin-bottom: 35px;
    }
  }
}

#reports-tabs {
  height: calc(100% - 176px) !important;
  display: flex;
  flex-direction: column;

  // .tab-content,
  // .tab-pane {
  //   height: 100% !important;
  // }

  .reports-advance-filters-body {
    max-height: 100% !important;
  }
}

.form-horizontal fieldset:nth-last-child(2) {
  margin-bottom: 200px !important;
}

.top-report-options-dropdown {
  z-index: 1;
}

.common-actions-base-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .navbar-fixed-top {
    position: initial !important;
    padding: 11px 0 0 0;
  }

  .common-actions-base-screen-body {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;

    .card {
      margin-bottom: 0;
    }

    .bulk-action-body {
      padding-top: 0 !important;
      height: 100% !important;
    }
  }

  .common-actions-base-screen-empty-message {
    margin-top: 15px;
  }

  .common-actions-base-screen-footer {
    width: 100%;
    padding: 0 30px;
  }

  #fixed-action-footer {
    position: initial;
    padding-left: 15px;
    width: 100% !important;
  }
}

.action-btn-round .badge {
  top: -6px;
  right: -6px;
}

#fixed-action-footer .text-right.col-md-12 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.payment-listing-main>.row {
  height: 100%;
}

.action-co {

  a,
  span {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
}

.p0 {
  padding: 0 !important;
}

$primary: #5c5de8;
$tab_bkg: transparent !important;
$tab-left-padding: 30px;
$t-border-width: 3px;

// NOTE make this part generic or remove it
[id^="settings-left-tabs-tab"],
[id^="customer-recents-tabs-tab"] {
  color: #3d4246;
  padding: 6px 15px !important;
  border-radius: 0 !important;
  padding-left: $tab-left-padding !important;
  outline: 0 !important;

  &:hover {
    background: $tab_bkg;
    border-left: $t-border-width solid $primary;
    padding-left: $tab-left-padding - $t-border-width !important;
  }

  &[aria-selected="true"] {
    background: $tab_bkg;
    font-weight: 600;
    padding-left: $tab-left-padding - $t-border-width !important;
    border-left: $t-border-width solid darken($color: $primary, $amount: 10);
    color: $primary !important;
  }
}

#settings-left-tabs {
  height: 100%;

  .new.form-horizontal {
    height: 100%;
    display: flex;
    flex-direction: column;

    &>div:first-child {
      position: relative;
      flex: 1 1 auto;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &>.form-group:first-child {
        margin-top: 15px + 6px;
      }
    }
  }

  #fixed-action-footer {
    position: static;
    width: 100% !important;

    &>.col-md-12 {
      padding: 0 !important;
    }
  }
}