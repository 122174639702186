.video-help-link-nav {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  color: $primary;
}

.video-link-wrapper {
  font-size: 14px;
  color: $primary;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 30px;
  }
}

.video-link-wrapper.field-level {
  font-size: 14px;
  color: $primary;
  cursor: pointer;
  display: inline;
  text-align: center;

  img {
    width: 18px;
  }
}

.video-help-circle-btn {
  margin-right: 7px;

  .fa.fa-question {
    font: normal normal normal 14px/1 FontAwesome;
    padding-top: 2px;
  }
}

.listing-page-help-video-link {
  height: 44px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: end;
}

.video-modal {
  .modal-dialog {
    width: 80vw;

    .modal-header {
      display: flex;

      .close-modal-btn-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0px;
      }

      .header-breadcrumb {
        text-transform: capitalize;
      }

      .common-modal-title-content {
        text-transform: capitalize;
      }

      .common-modal-title-action {
        display: flex;
        justify-content: right;
        padding: 0px;
      }
    }

    .modal-body {
      padding-top: 0px;
      padding-bottom: 0px;
      height: calc(100vh - 100px);
      max-height: calc(100vh - 100px);
      min-height: calc(100vh - 100px);

      .box-wrapper {
        padding-top: 15px;
        padding-bottom: 15px;

        .box {
          text-align: left;
          display: block;

          .content-youtube-video {
            border: 1px solid $border_color;
            width: 100%;
            height: 194px !important;

            iframe.content-youtube-video-iframe {
              height: 192px !important;
            }
          }

          .content-youtube-video-title {
            border: 1px solid $border_color;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: none;
            min-height: 71px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.list-of-contents-wrapper {
  padding-bottom: 20px;

  .content-item-wrapper {
    display: flex;
    padding: 0px;

    .video-item {
      padding-left: 1px;
      padding-right: 1px;
      border: 1px solid $border_color;

      .box img {
        width: 100%;
      }

      .box>img.play {
        height: 35px;
        width: 35px;
        position: absolute;
        z-index: 1;
        top: 30%;
        left: 40%;
      }
    }

    .content-item {
      height: 60px;
      font-size: 16px;
      overflow: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
    }
  }

  .content-item-wrapper:hover {
    cursor: pointer;
  }
}

.faq-title-wrapper {
  .faq-title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.help-title-wrapper {
  .help-title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.help-show-video {
  .customer-content {
    height: 100%;
  }
}