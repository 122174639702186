.login-main-container.main-content {
  height: 100%;

  .form-container-fluid.sign-up-account-page.container-fluid {
    height: 100%;

    .sign-up-account-wrapper {
      max-height: calc(100% - 70px) !important;
      overflow: auto;
      padding-bottom: 50px;

      .container-fluid.main-container {
        padding-left: 0px;
        padding-right: 0px;

        .sign-up-account-title {
          margin-bottom: 30px;
          font-weight: 500;
        }

        .landing-left {
          margin-top: 0% !important;
        }

        .form-group,
        .form-group {
          .control-label {
            color: $label_color !important;
          }

          input.form-control {
            border: 1px solid $border_color;
          }
        }

        .country-code-number-field .input-group input.form-control:disabled {
          background-color: lighten($gray_lighter, 13%) !important;
        }

        .agreement-wrapper {
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 15px;

          .control-label {
            color: $label_color !important;

            input[type="checkbox"] {
              margin-top: 0px !important;
            }
          }
        }
      }

      .btn-primary:disabled:hover {
        opacity: 0.5 !important;
      }

      .toggle-password {
        z-index: 0 !important;
      }

      .country-code-number-field .input-group .country-code {
        width: 40%;
      }
    }

    .footer {
      background-color: $white;
      width: 100%;
      left: 0;
      padding-bottom: 5px !important;
      position: fixed;
      bottom: 0;

      .product-owner-details {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        bottom: auto;
        color: $label_color;
        width: 100%;
        text-align: center;

        a {
          color: $primary;
        }
      }

      .policies {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        bottom: auto;
        color: $label_color;
        width: 100%;
        text-align: center;

        a {
          color: $gray !important;
        }
      }
    }
  }
}