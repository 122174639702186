.index-listing-container-fluid.explore.container-fluid {
  .box-wrapper {
    padding: 10px;

    .box {
      text-align: left;
      display: block;

      .content-youtube-video {
        border: 1px solid $border_color
      }

      .content-youtube-video-title {
        border: 1px solid $border_color;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: none;
        min-height: 71px;
        font-size: 16px;
      }
    }
  }
}