.reset-account-password-link-wrapper {
  color: $white;

  .reset_account_password,
  .reset_account_password:hover,
  .reset_account_password:active {
    color: $white;
    font-weight: 500;
    cursor: pointer;
  }

  .account-confirmation,
  .account-confirmation:hover,
  .account-confirmation:active {
    color: $white;
    font-weight: 500;
    cursor: pointer;
  }
}

%full-height-of-parent {
  height: 100% !important;
}

%full-view-height {
  height: 100vh !important;
}

.login-main-container.main-content {
  .navbar-fixed-top {}

  .form-container-fluid.reset-password-page.container-fluid {
    height: calc(100% - 120px);
  }

  .reset-password-page-wrapper {
    @extend %full-height-of-parent;

    .main-container {
      @extend %full-height-of-parent;
      padding-left: 0px;
      padding-right: 0px;

      .main-row {
        @extend %full-height-of-parent;

        .main-left {
          @extend %full-height-of-parent;

          .landing-left {
            margin-top: 0%;
            margin-bottom: 0px;
          }

          .list-of-account-tenants-title {
            text-align: center;
          }

          .product-details:hover {
            cursor: pointer;
          }

          .reset-account-password {
            padding-top: 0px;
            height: 100%;
            overflow: auto;

            .validate-number-title {
              color: $label_color;
              font-weight: 500;
              margin-bottom: 30px !important;
            }

            .password-wrapper {
              padding-left: 6px;
            }

            .form-group {
              label.control-label {
                color: $label_color !important;
              }

              input.form-control {
                border: 1px solid $border_color;
              }
            }

            .resend-otp-wrapper {

              .resend-otp-number,
              .resend-otp-number:hover,
              .resend-otp-number:active {
                color: $label_color;
                font-weight: 500;
                cursor: pointer;
              }

              .resend-otp-number.disabled,
              .resend-otp-number.disabled:hover,
              .resend-otp-number.disabled:active {
                color: $gray_light;
                cursor: not-allowed;
              }

              .resend-otp-time-remaining {
                color: lighten($label_color, 10%);
              }
            }
          }

          .product-owner-details {
            color: $label_color;

            a {
              color: $primary;
            }
          }

          .policies {
            a {
              color: $gray !important;
            }
          }
        }

        .main-right {
          @extend %full-height-of-parent;
        }
      }
    }
  }
}