.form-container-fluid.dashboard-container-fluid.container-fluid {
  span.breadcrumb {
    li>a {
      color: $breadcrumb_link_color;
    }
  }

  .home-card.home-card-stats {
    .header h5 {
      color: $dashboard_header_text_color;
    }
  }

  .home-graph-card {
    .header h5 {
      color: $dashboard_header_text_color;

      .updated-at {
        color: $dashboard_control_label;
      }
    }

    .form-group label.control-label {
      color: $dashboard_control_label !important;
    }

    .Select.is-open>.Select-control,
    .Select.has-value.is-focused.is-open .Select-menu-outer {
      border-color: $breadcrumb_link_color !important;
    }

    .react-datepicker-wrapper .form-control:focus {
      border-color: $breadcrumb_link_color !important;
    }

    .btn-primary {
      background-color: $breadcrumb_link_color !important;
      border-color: $breadcrumb_link_color;
    }

    .report-table-action {

      .action-btn-round {
        color: $primary;
      }

      .action-btn-round:hover {
        color: $white;
        background-color: $primary;
      }

      .badge.badge-primary.number-of-advance-search-applied-count {
        background-color: $breadcrumb_link_color;
      }

      .action-btn-round:hover {
        .badge.badge-primary.number-of-advance-search-applied-count {
          background-color: $white;
          border: 1px solid $breadcrumb_link_color;
          color: $breadcrumb_link_color;
        }
      }
    }
  }
}

.fa-info-circle {
  color: lighten($dashboard_control_label, 25%) !important;
}

.sales-performance-section {
  padding-right: 7.5px;
}

.collection-performance-section {
  padding-left: 7.5px;
}