.inventory-manufacturer-nested-form {
  margin-bottom: 12%;
}

@mixin select-box-container {
  .select-box-container {
    width: 100%;
  }
}

.inventory-manufacturers-mfd-date {

  .mfd-year {
    width: 27%;
    @include select-box-container;
  }

  .mfd-month {
    width: 48%;
    @include select-box-container;
  }

  .mfd-day {
    width: 25%;
    @include select-box-container;
  }
}

.inventory-manufacturers-expiry-date {

  .expiry-year {
    width: 27%;
    @include select-box-container;
  }

  .expiry-month {
    width: 48%;
    @include select-box-container;
  }

  .expiry-day {
    width: 25%;
    @include select-box-container;
  }
}

.inventory-manufacturer-item-errors {
  padding-left: 15px !important;
  padding-right: 15px !important;
}