.login-main-container.main-content {
  height: 100%;

  .form-container-fluid.account-confirmation-page.container-fluid {
    height: calc(100% - 120px);

    .account-confirmation-wrapper {
      .container-fluid.main-container {
        padding-left: 0px;
        padding-right: 0px;

        .account-confirmation-title {
          margin-bottom: 30px;
          font-weight: 500;
        }

        .landing-left {
          margin-top: 0%;
        }

        .form-group {
          .control-label {
            color: $label_color !important;
          }

          input.form-control {
            border: 1px solid $border_color;
          }
        }

        .resend-otp-wrapper {

          .resend-otp-number,
          .resend-otp-number:hover,
          .resend-otp-number:active {
            color: $label_color;
            font-weight: 500;
            cursor: pointer;
          }

          .resend-otp-number.disabled,
          .resend-otp-number.disabled:hover,
          .resend-otp-number.disabled:active {
            color: $gray_light;
            cursor: not-allowed;
          }

          .resend-otp-time-remaining {
            color: lighten($label_color, 10%);
          }
        }
      }

      .btn-primary:disabled:hover {
        opacity: 0.5 !important;
      }
    }

    .product-owner-details {
      color: $label_color;

      a {
        color: $primary;
      }
    }

    .policies {
      a {
        color: $gray !important;
      }
    }

    .account-confirmation.alert {
      display: flex;

      img {
        padding-right: 10px;
      }
    }
  }
}