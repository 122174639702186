.normal-form-row {
  margin: 0 -6px;

  [class*="col-"] {
    padding: 0 6px !important;
  }
}

.normal-form-container { padding: 0 6px }

.quick-filter-tabs {
  margin-bottom: 0 !important;

  & > li:first-child { margin-left: 30px; }
  li > div { padding-top: 3px; padding-bottom: 3px; cursor: pointer; }
  li.no-deco::after { content: none; margin-right: 30px; }
}

.recon-navbar { border-bottom: 0; }

.recon-start-details {
  // WHY z-index: 8?  because as the `.navbar-fixed-top` has z-index: 9 and all other header elements
  //                  should be below it, so z-index: 8
  padding: 0 30px; z-index: 8;

  .form-group {
    .form-control { height: 32px; }

    .Select-control {
      height: 32px;

      .Select-placeholder {
        line-height: 28px;
      }
      .Select-value {
        line-height: 28px !important;

        .Select-value-label {
          line-height: 28px;
        }
      }
      .Select-input {
        height: 28px;
      }
    }
  }
}

.recon-end-details {
  // WHY z-index: 7?  because as the `.navbar-fixed-top` has z-index: 9 and `.recon-start-details`
  //                  has z-index: 8, and end details should be below start details, so z-index: 7
  padding: 0 30px; z-index: 7;

  .form-group {
    input { flex: 1 1 auto }

    .form-control { height: 32px; }

  }
}

.recon-sheet {
  #generic-table { 
    margin-bottom: 0;
  }

  $footer-offset: 13px;
  table {
    td, th { padding-top: 10px !important; padding-bottom:10px !important; }
    th.entry-date, th.party-type { width: 100px }
    td.master-checkbox-th, th.master-checkbox-th { padding-top: 0px !important; padding-bottom:0px !important; }
    tr, td, th { max-height: 37px; }
    
    tfoot {
      // position: sticky; bottom: -15px;
      td, tr { background: $white;  }
      td { height: 37px; position: sticky; box-shadow: 0 -4px 2px -4px $box_shadow; }
      // tr {  }

      tr:nth-child(1) > td { bottom: 35px - $footer-offset; }
      tr:nth-child(2) > td { bottom: 0 - $footer-offset; }
    }
  }
}

.new-advance-search-modal-body.for-show-page { 
  padding-bottom: 0;

  .show-page-container-fluid { padding-top: 22px }

}

.state-confirmation-modal { z-index: 1070; }
.state-confirmation-modal-backdrop { z-index: 1060; }
.z-index-1050 { z-index: 1050; }