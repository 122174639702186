.new.form-horizontal {
  &>.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    padding: 0 15px;

    [class*="col-"]:first-child {
      padding-left: 6px;
    }

    [class*="col-"]:last-child {
      padding-right: 6px;
    }

    .fixed-action-footer {
      .form-footer-btn {
        padding: 10px 0px 5px 0px;
      }
    }

    .tasks-fieldset .card-header {
      padding: 0;
    }

    #add-new-item {
      margin-right: -9px;
    }
  }

  .card-header-title .row {
    margin: 0 -6px
  }
}

.custom-index-dropdown {
  .dropdown-toggle {
    border-width: 0;
    padding: 0 6px;
  }

  .dropdown-menu {
    border-radius: 0;
    z-index: 8;

    li:hover {
      .quick-index-item-options {
        opacity: 1;
        visibility: visible;
      }

      .quick-index-item-label {
        width: calc(100% - 20px);
      }
    }
  }

  .dropdown.open .dropdown-menu {
    margin-top: 2px;
  }

  .divider {
    margin: 0
  }

  &.add-voucher-dropdown {
    .dropdown-toggle {
      border-width: 1px;
    }
  }
}

.quick-index-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .quick-index-item-label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .sort-handle {
      display: inline-block;
      margin-right: 6px;
    }

    .sort-handle .fa {
      width: 5px;
      color: $gray_light
    }
  }

  .quick-index-item-label.inactive {
    color: lighten($color: $secondary_darkest, $amount: 40)
  }

  .quick-index-item-options {
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }


  .quick-index-item-edit {
    cursor: pointer;
  }

  .quick-index-item-delete {
    margin-left: 6px;
    color: $danger_light;
    cursor: pointer;
  }

  .quick-index-item-active-ind {
    width: 18px;
    display: inline-block;
    margin-right: 4px;
    color: $primary;
  }

  .quick-index-item-active-ind.active {
    background: $primary;
  }
}

.quick-index-item-container.no-result {
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 0px;
  color: $gray;
  cursor: default;
}

.quick-index-item-container.no-result.menu-item {
  a {
    cursor: default;
  }

  a:focus-visible,
  a:active {
    outline: none;
  }
}

.quick-index-item-container.no-result:focus-visible {
  outline: none;
}

.draggable-menu-item:hover .quick-index-item-options {
  opacity: 1 !important;
  visibility: visible !important;
}

.new-form-container .control-label {
  padding-top: 7px
}

#custom-fields-form-container {
  .modal-dialog {
    width: 720px;
  }

  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}

.form-field-preview-container {
  background: $gray_lightest;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  overflow-y: auto;
}

.flex-wrap-content {
  display: flex;
  flex-wrap: wrap;
}

.fieldset-neg-margin fieldset {
  margin: 0 -6px
}

;

.custom-fields-tab-dropdown-container {
  position: absolute;
  right: 40px;
  bottom: 10px;
  outline: 0;
  float: right !important;
  margin-right: 0 !important;

  &::after {
    height: 0 !important;
  }
}

.p0 {
  padding: 0 !important
}

.form-group-mb-0 .form-group {
  margin-bottom: 0 !important;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.meun-item-ghost {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: $white;
  }
}

.tabs-with-custom-fields {
  position: relative;
  z-index: 1;
}

.number-format-drag {
  background-color: lighten($gray, 48%);
  padding: 5px;
}

.number-format-ghost {
  opacity: 20%;
  padding: 5px;
  background-color: lighten($gray, 48%);
}

.custom-fields-wrapper.custom-index-dropdown {
  padding-left: 0px;
  padding-right: 0px;

  #custom-field-sortable-list {
    padding: 0px;
    list-style-type: none;
    height: 80vh;
    max-height: 80vh;
    overflow: auto;
    padding-top: 5px;

    li.draggable-menu-item {
      padding: 5px;
      // border-bottom: 1px solid $border_color;
      margin-bottom: 5px;
      outline: none;
      cursor: pointer;

      a:focus-visible,
      a:focus {
        outline: none;
      }

      .draggable-menu-item-container:focus-visible {
        outline: none;
      }

      .quick-index-item-label:focus-visible {
        outline: none;
      }

    }

    li.draggable-menu-item.active,
    li.draggable-menu-item:hover {
      background-color: #ebf3fd !important;
    }

    li.draggable-menu-item.divider {
      padding: 0px;
      // border: 1px solid $border_color;
      border: none;
      // border-bottom: 1px solid $border_color;
    }

    li.draggable-menu-item.add-new-custom-field {
      // padding: 0px !important;
      // border: 0px !important;

      // position: fixed;
      // bottom: 0px;
      // background-color: white;
      // width: 32%;
      // border-top: 1px solid $border_color;

      .quick-index-add-item-container .add-field-wrapper {
        // border: 1px solid $primary_input_field_border_color;
        padding: 5px;
        text-align: center;
        width: 110px;
      }
    }

    li.draggable-menu-item.add-new-custom-field:hover {
      background: none !important;
      text-decoration: underline;
    }

    .draggable-menu-item.add-new-custom-field {
      list-style: none;
      list-style-type: none;
    }
  }
}

.custom-fields-form-wrapper {
  border-left: 1px solid $border_color;

  .custom-fields-form-header {
    padding-left: 15px;
    padding-right: 15px;

    .header-title {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .custom-fields-form-body {
    height: 65vh;
    display: flex;

    .custom-fields-form-body-wrapper {
      padding: 15px;
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .custom-fields-form-footer {
    text-align: end;
    padding-bottom: 5px;

    button.cancel-btn {
      margin-right: 10px;
    }
  }
}